import React from 'react';
import styled from 'styled-components';

import BorderButtonBlue from '@elements/BorderButtonBlue/BorderButton';

import {
  AmountHeading,
  CheckBoxField,
  DateField,
  FromToField,
  IconField,
  RowMobile,
  TagField,
} from './AnalyticsTransactions/AnaliticsTransaction.styles';
import { StatusField } from './Status';

const Preload = ({ isLaptop, visibleRows = 5 }: { isLaptop: boolean; visibleRows?: number }) => {
  return (
    <>
      {Array(visibleRows)
        .fill(null)
        .map((i, index) =>
          isLaptop ? (
            <Row key={index}>
              <CheckBoxField>
                <CheckboxPreload />
              </CheckBoxField>
              <AmountHeading>
                <AmountPreload />
              </AmountHeading>
              <DateField>
                <PreloadField />
              </DateField>
              <TagField>
                <PreloadField />
              </TagField>
              <IconField>
                <PreloadCircle />
              </IconField>
              <FromToField>
                <PreloadField />
              </FromToField>
              <StatusField>
                <PreloadField />
              </StatusField>
            </Row>
          ) : (
            <RowMobile key={index}>
              <RowWrap>
                <CircleMobile />
                <RowWrapMidle>
                  <FieldMobile />
                  <FieldMobile />
                </RowWrapMidle>
                <CheckboxMobile />
              </RowWrap>
              <FieldMobile />
              <FieldMobile />
              <BorderButtonBlue onClick={() => undefined}>Transaction Details</BorderButtonBlue>
            </RowMobile>
          )
        )}
    </>
  );
};

export default Preload;

const CheckboxPreload = styled.div`
  background: ${(props) => props.theme.palette.greys.darkGrey};
  width: 12px;
  height: 12px;
`;
const AmountPreload = styled.div`
  background: ${(props) => props.theme.palette.greys.darkGrey};
  width: 80%;
  height: 12px;
`;

const PreloadField = styled.div`
  background: ${(props) => props.theme.palette.greys.middleGrey};
  width: 80%;
  height: 12px;
`;
const PreloadCircle = styled.div`
  background: ${(props) => props.theme.palette.greys.middleGrey};
  width: 26px;
  height: 26px;
  border-radius: 50%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.palette.greys.lightLightGrey};
  height: 50px;
  width: 100%;
  margin-top: 7px;
  opacity: 50%;
`;
// const RowMobile = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background: ${(props) => props.theme.palette.greys.lightLightGrey};
//   height: 167px;
//   width: 100%;
//   border-radius: 8px;
//   margin-top: 7px;
// `;
const RowWrap = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  margin: 10px 0;
`;
const RowWrapMidle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3px 100px 10px 10px;
  & > :last-child {
    width: 70%;
  }
`;
const CircleMobile = styled.div`
  background: ${(props) => props.theme.palette.greys.middleGrey};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
`;
const FieldMobile = styled.div`
  background: ${(props) => props.theme.palette.greys.middleGrey};
  width: 100%;
  height: 12px;
  margin-bottom: 10px;
`;
const CheckboxMobile = styled.div`
  border: 2px solid ${(props) => props.theme.palette.greys.middleGrey};
  width: 18px;
  height: 18px;
  flex-shrink: 0;
`;
