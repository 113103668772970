import React from 'react';

import { useBoundStore } from '@stores/BoundStore';

import { DeleteItem } from '@constants/icons';

import { allTypes, statuses } from '@components/Filters/Statuses';

import { IconWrap, ScrollFilters, SelectedFilter } from './AnalyticsTransactionsWrapper.styles';

const SelectedFilterList = () => {
  const setFilterType = useBoundStore((state) => state.setFilterType);
  const filterType = useBoundStore((state) => state.filterType);
  const filterStatus = useBoundStore((state) => state.filterStatus);
  const setFilterStatus = useBoundStore((state) => state.setFilterStatus);
  const getFilterName = (value: string, filters: SelectOption[]) => {
    const filter = filters.find((elem) => elem.value === value);
    return filter?.text;
  };

  const handleResetType = () => {
    setFilterType(undefined);
  };
  const handleResetStatus = () => {
    setFilterStatus(undefined);
  };

  return (
    <ScrollFilters>
      {filterType && (
        <SelectedFilter>
          {' '}
          {getFilterName(filterType, allTypes)}{' '}
          <IconWrap
            onClick={handleResetType}
            className="closeIcon"
          >
            <DeleteItem />
          </IconWrap>{' '}
        </SelectedFilter>
      )}
      {filterStatus && (
        <SelectedFilter>
          {' '}
          {getFilterName(filterStatus, statuses)}{' '}
          <IconWrap
            onClick={handleResetStatus}
            className="closeIcon"
          >
            <DeleteItem />
          </IconWrap>
        </SelectedFilter>
      )}
    </ScrollFilters>
  );
};

export default SelectedFilterList;
