import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useGetCorporate, useGetKYB, useStartKYB } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { VerifyBGs } from '@constants/images';
import { IconLogoWhite } from '@constants/logos';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import EmailModal from '@components/EmailModal/EmailModal';
import OTPModal from '@components/OTPModal/OTPModal';

import BorderButton from '@elements/BorderButton/BorderButton';

const VerificationBox = () => {
  const navigate = useNavigate();

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const openModal = useBoundStore((state) => state.openModal);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const {
    data: corporate,
    isError: isGetCorporateError,
    error: getCorporateError,
  } = useGetCorporate();
  const { data: kybData, isError: isGetKYBError, error: getKYBError } = useGetKYB();

  const { mutate: startCorporateKybMutation } = useStartKYB();

  const emailVerified = corporate?.rootUser.emailVerified ?? false;
  const mobileNumberVerified = corporate?.rootUser.mobileNumberVerified ?? false;

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (isGetKYBError && getKYBError) {
      errorsToHandle.push(getKYBError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [getCorporateError, getKYBError, handleHTTPErrors, isGetCorporateError, isGetKYBError]);

  useEffect(() => {
    if (emailVerified && !mobileNumberVerified && location.pathname === '/') {
      openModal(<OTPModal />);
    }
  }, [emailVerified, mobileNumberVerified, openModal]);

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (!emailVerified) {
      openModal(<EmailModal />);
    } else if (!mobileNumberVerified) {
      openModal(<OTPModal />);
    } else {
      startCorporateKybMutation(undefined, {
        onSuccess: (data) => {
          if (data) {
            const { reference } = data;
            navigate('/kyb', { state: { kybRef: reference } });
          }
        },
      });
    }
  };

  return (
    <Container>
      <Rectangle />
      <Row>
        <IconLogoWhite />
        <VerifyButton
          onClick={handleButtonClick}
          text={verifiedKYC ? 'Add Money' : checkIfKybInitiated() ? 'View Status' : 'Verify'}
        />
      </Row>
      <Text>All transactions can be displayed with different sorting options</Text>
    </Container>
  );
};

export default VerificationBox;

const Container = styled.div`
  width: 220px;
  height: 58px;
  padding: 8.791px 3.46px 8.471px 12.418px;
  box-sizing: border-box;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.primary.blue};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 234px;
    height: 124px;
    padding: 15px;
    justify-content: space-between;
    align-items: unset;
    background-image: image-set(url(${VerifyBGs[0]}) 1x, url(${VerifyBGs[1]}) 2x);
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const Rectangle = styled.div`
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  left: 36px;
  background: ${({ theme }) => theme.palette.primary.blue};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    background: #0094fe;
  }
`;

const Row = styled.div`
  display: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const VerifyButton = styled(BorderButton)`
  width: 111px;
  height: 40px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gilroy Semibold', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  transition: 300ms ease-in-out;
  padding: 0;
`;

const Text = styled.div`
  min-width: 100%;
  ${FontStyles.h7};
  color: ${({ theme }) => theme.palette.primary.white};
`;
