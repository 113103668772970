import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconDelete } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import Avatar from '@components/Avatar/Avatar';

interface Props {
  contact: ContactsWithBankDetails;
  className?: string;
}

const ContactHeader = ({ contact, className }: Props) => {
  const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const { name, tag, createdAt } = contact;

  const handleClick = () => {
    setSelectedContact(contact);
    if (!isLaptop) {
      navigate('/send-money/transaction-form');
    } else {
      navigate('/send-money');
    }
  };
  console.log('contact', contact);
  return (
    <Container
      className={className}
      onClick={handleClick}
    >
      <Avatar
        src=""
        alt={`Avatar ${name}`}
        text={name ?? 'A'}
        dark={true}
        size="medium"
      />
      <List>
        <Name>{name}</Name>
        {createdAt && (
          <Since>In contact list since: {format(new Date(createdAt), 'dd/MM/yyyy')}</Since>
        )}
        {/*<Tag>{!tag || tag === '' ? 'No hashtag assigned' : tag}</Tag>*/}
      </List>
      <IconDelete />
    </Container>
  );
};

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const Name = styled.p`
  ${FontStyles.h4};
  line-height: 100%;
  transition: color 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.black};
`;

const Since = styled.p`
  ${FontStyles.bodySmallGilroy};
  transition: color 300ms ease-in-out;
  margin-top: 7px;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const List = styled.div`
  height: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  column-span: 4;

  &.dontCollapse {
    //align-items: start;
    justify-content: start;
    //flex-direction: column;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    //align-items: start;
    justify-content: start;
    //flex-direction: column;
  }
`;

export default ContactHeader;
