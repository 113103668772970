import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useGetCorporate } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { parseUserName } from '@shared/functions';

import { femaleAvatar1 } from '@constants/avatars';
import { IconArrowLeft, IconChat } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import CardSetup from '@components/CardSetup/CardSetup';
import Hamburger from '@components/Hamburger/Hamburger';
import MobileNavigation from '@components/MobileNavigation';
import NotificationButton from '@components/NotificationButton';
import PeanudsLogo from '@components/PeanudsLogo/PeanudsLogo';

import {
  AvatarHover,
  ButtonGroup,
  Container,
  Line,
  MenuGroup,
  MobileCurrentPathText,
  MobileCurrentPathTextContainer,
  NavGroup,
  NavIconBackButton,
  NavIconButton,
  NavigationButton,
  NavigationLink,
} from './Navigation.styles';

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const scrolled = useBoundStore((state) => state.notificationsOpen);
  const setSupportVisible = useBoundStore((state) => state.setSupportVisible);

  const openModal = useBoundStore((state) => state.openModal);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const { data: corporate, isError, error } = useGetCorporate();

  useEffect(() => {
    setSupportVisible(mobileNavOpen);
  }, [mobileNavOpen, setSupportVisible]);

  useEffect(() => {
    if (isError && error) {
      handleHTTPErrors([error]);
    }
  }, [error, handleHTTPErrors, isError]);

  const userName = corporate
    ? parseUserName(corporate.rootUser.name, corporate.rootUser.surname)
    : '?';

  const navLinks: NavLinkStructure[] = [
    { title: 'Overview', url: '/' },
    { title: 'Send Money', url: '/send-money' },
    { title: 'Cards', url: '/cards' },
    { title: 'Analytics', url: '/analytics' },
    { title: 'Pricing', url: '/pricing' },
  ];

  const renderSwitch = (pathname: string) => {
    //regex check if path matches settings or any of the specified sub routes
    if (/^\/settings(\/(company|access|log)?)?\/?$/.test(pathname)) {
      return 'Settings';
    } else {
      switch (pathname) {
        case '/send-money':
          return 'Send Money';
        case '/send-money/transaction-form':
          return 'Send Money';
        case '/cards':
          return 'Cards';
        case '/analytics':
          return 'Analytics';
        case '/pricing':
          return 'Pricing';
        case '/':
          return '/';
        case '/kyb':
          return 'KYB';
        case '/kyc':
          return 'KYC';
        default:
          return 'Error';
      }
    }
  };

  return (
    <>
      <MobileNavigation
        avatarSrc={femaleAvatar1}
        navLinks={navLinks}
        mobileNavOpen={mobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
      />
      <Container $isScrolled={scrolled}>
        <MobileCurrentPathTextContainer>
          {location.pathname === '/' ? (
            <PeanudsLogo gradientId="logoMobile" />
          ) : (
            <MobileCurrentPathText>{renderSwitch(location.pathname)}</MobileCurrentPathText>
          )}
        </MobileCurrentPathTextContainer>
        <NavGroup>
          <NavLink to="/">
            <PeanudsLogo gradientId="logoNav" />
          </NavLink>
          <Line />
          {navLinks.map(({ title, url }, index) => {
            if (title !== 'Cards') {
              if (title !== 'Pricing') {
                return (
                  <NavigationLink
                    key={index}
                    to={url}
                  >
                    {title}
                  </NavigationLink>
                );
              }
            } else {
              return (
                <NavigationButton
                  key={index}
                  onClick={() => openModal(<CardSetup />)}
                >
                  {title}
                </NavigationButton>
              );
            }
          })}
        </NavGroup>
        <ButtonGroup>
          <NavIconBackButton
            onClick={() => navigate(-1)}
            $visibleOnMobile={location.pathname !== '/'}
            icon={<IconArrowLeft />}
          />
          <NotificationButton />
          <NavIconButton
            onClick={() => {
              setMobileNavOpen(true);
              setSupportVisible(true);
              window.HubSpotConversations.widget.open();
            }}
            icon={<IconChat />}
          />
        </ButtonGroup>
        <MenuGroup>
          <AvatarHover
            text={userName}
            onClick={() => navigate('/settings')}
          />
          <Hamburger onClick={() => setMobileNavOpen(true)} />
        </MenuGroup>
      </Container>
    </>
  );
};

export default Navigation;
