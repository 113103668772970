// import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { electronicFormatIBAN, validateIBAN } from 'ibantools';

import { Commons, getFees } from '@shared/functions';

import { convertStringToNumber } from '@components/transaction/halper';

const regExName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const getAmountWithFees = (amount: string) => {
  const fees = getFees('SEPA');
  const amountNumber = convertStringToNumber(amount);

  return amountNumber + fees;
};
const getBalance = (balance: number) => {
  return balance > 0 ? Commons.formatWeavrAmountToPeanuds(balance) : 0;
};
export const amountValidate = (value: string, balance: number): string => {
  if (!value) {
    return 'Please enter the amount.';
  }

  const parsedValue = convertStringToNumber(value);

  if (parsedValue < 0) {
    return 'Please enter a valid amount.';
  }

  if (getBalance(balance) < getAmountWithFees(value)) {
    return 'The amount exceeds the available balance.';
  }

  return ''; // No error, validation passed
};

export const recipientValidate = (value: string) => {
  if (regExName.test(value)) {
    return '';
  } else {
    // eslint-disable-next-line quotes
    return "Please enter the recipient's full name.";
  }
};
export const ibanValidate = (value: string) => {
  if (value) {
    const iban = electronicFormatIBAN(value);
    if (iban) {
      const { valid, errorCodes } = validateIBAN(iban);

      if (valid) return '';
      if (errorCodes.length > 0) {
        return 'Please enter a valid IBAN.';
      }
    }
  }
  return 'Please enter a valid IBAN.';
};
export const descriptionValidate = (value: string) => {
  if (value) {
    if (value.length > 35) {
      return 'Description should not be more than 35 characters.';
    } else {
      return '';
    }
  } else {
    // eslint-disable-next-line quotes
    return 'A payment reference is required.';
  }
};
