import { useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CorporateService } from '@services/corporate-service';

import { useLogin } from '@api/Access/accessApi';
import { useResumeLostPassword } from '@api/Password/passwordApi';

import { useBoundStore } from '@stores/BoundStore';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import PasswordInfoBlock from '@components/PasswordInfoBlock/PasswordInfoBlock';

import Toast from '@elements/Toast/Toast';

import {
  CreatePasswordForm,
  CustomButton,
  CustomOnboardingStepNavigation,
  Heading,
  Label,
  PasswordErrorMessage,
  SubHeading,
  WeavrPassword,
} from './SetPassword.styles';

const SetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [nonce, setNonce] = useState('');
  const [email, setEmail] = useState('');

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [passwordInputFocused, setPasswordInputFocused] = useState<boolean>(false);
  const [confirmPasswordInputFocused, setConfirmPasswordInputFocused] = useState<boolean>(false);
  const secureFormRef = useRef<Form | null>(null); // Ref to window.OpcUxSecureClient.form()
  const formRef = useRef<HTMLFormElement>(null); // Ref to our form component
  const isWeavrUiComponentLibraryLoaded = useBoundStore(
    (state) => state.isWeavrUiComponentLibraryLoaded
  );
  const setIsLoggedIn = useBoundStore((state) => state.setIsLoggedIn);
  const { mutate: setPasswordMutation } = useResumeLostPassword();
  const { mutate: loginMutation } = useLogin();

  const errorHandling = useMemo(
    () => ({
      409: (error: unknown) => {
        const { errorCode } = error as { errorCode: string };
        if (errorCode === 'INVALID_NONCE_OR_EMAIL') {
          toast.error(
            <Toast
              title="General Error"
              message={t('multipleAccountsFound')}
            />
          );
        } else if (errorCode === 'PASSWORD_ALREADY_USED') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordKeyAlreadyInUse')}
            />
          );
        } else if (
          errorCode === 'PASSWORD_TOO_SHORT' ||
          errorCode === 'PASSWORD_TOO_LONG' ||
          errorCode === 'PASSWORD_TOO_SIMPLE'
        ) {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordTooSimple')}
            />
          );
        } else if (errorCode === 'PASSWORD_INCORRECT') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordNotMatch')}
            />
          );
        } else if (errorCode === 'PASSWORD_NOT_SET') {
          toast.error(
            <Toast
              title="Password Error"
              message={t('passwordNotSet')}
            />
          );
        }
      },
    }),
    [t]
  );

  const { handleHTTPErrors } = useHTTPErrorHandler(errorHandling);

  const queryClient = useQueryClient();

  const toggleError = (message: string) => {
    const errorMessageElement = document.getElementById('passwordConfirmResponse');
    if (errorMessageElement) {
      errorMessageElement.innerText = message || '';
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (submitting) return;
    if (!secureFormRef.current) return;

    setSubmitting(true);

    secureFormRef.current.tokenize(
      (response: TokenizedResponse) => {
        if (!response || !response.password || !response.confirmPassword || !nonce || !email) {
          setSubmitting(false);
          return;
        }

        const { password } = response;
        toggleError('');

        setPasswordMutation(
          { nonce, email, value: password },
          {
            onSuccess: async (data) => {
              if (data.token) {
                const { token } = data;
                loginMutation(
                  { email, password },
                  {
                    onSuccess: async () => {
                      try {
                        const data = await queryClient.fetchQuery({
                          queryKey: ['getCorporate'],
                          queryFn: async ({ signal }) => {
                            return await CorporateService.getCorporate(signal, token);
                          },
                        });

                        const { rootUser } = data;
                        const { mobileNumberVerified } = rootUser;

                        if (mobileNumberVerified) {
                          navigate('/otp/login', {
                            state: { ...(token ? { token } : {}) },
                          });
                        } else {
                          setIsLoggedIn(token);
                          navigate('/');
                        }
                      } catch (error) {
                        handleHTTPErrors([error]);
                        setSubmitting(false);
                      }
                    },
                    onError: (error) => {
                      handleHTTPErrors([error]);
                      setSubmitting(false);
                    },
                  }
                );
              }
            },
            onError: (error) => {
              handleHTTPErrors([error]);
              setSubmitting(false);
            },
          }
        );
      },
      () => {
        toast.error(
          <Toast
            title="Password Error"
            message={t('passwordNotMatch')}
          />
        );
        setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    if (isWeavrUiComponentLibraryLoaded && window.OpcUxSecureClient) {
      secureFormRef.current = window.OpcUxSecureClient.form();

      const passwordInput = secureFormRef.current.input('password', 'password', {
        placeholder: 'Password',
        maxlength: 30,
        style: {
          base: {
            fontSize: '16px',
            fontWeight: '500',
          },
        },
      });
      const confirmPasswordInput = secureFormRef.current.input(
        'confirmPassword',
        'confirmPassword',
        {
          placeholder: 'Confirm password',
          maxlength: 30,
          style: {
            base: {
              fontSize: '16px',
              fontWeight: '500',
            },
          },
        }
      );

      passwordInput.mount('#password');
      confirmPasswordInput.mount('#confirmPassword');
      confirmPasswordInput.on('submit', () => {
        if (formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
      });
      passwordInput.on('focus', () => {
        setPasswordInputFocused(true);
      });
      passwordInput.on('blur', () => {
        setPasswordInputFocused(false);
      });
      confirmPasswordInput.on('focus', () => {
        setConfirmPasswordInputFocused(true);
      });
      confirmPasswordInput.on('blur', () => {
        setConfirmPasswordInputFocused(false);
      });
    }
  }, [isWeavrUiComponentLibraryLoaded]);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    if (pathSegments.length > 2) {
      setNonce(pathSegments[2]);
    }

    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');
    if (emailParam) {
      setEmail(decodeURIComponent(emailParam));
    }

    if (pathSegments.length > 3) {
      setEmail(decodeURIComponent(pathSegments[3]));
    }
  }, [email, location, nonce]);

  return (
    <CreatePasswordForm
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <CustomOnboardingStepNavigation text="Previous Page" />
      <Heading>Setup your new password</Heading>
      <SubHeading>Create a secure Password for Application Access</SubHeading>
      <Label>Password</Label>
      <WeavrPassword
        id="password"
        $focused={passwordInputFocused}
      />
      <Label>Confirm Password</Label>
      <WeavrPassword
        id="confirmPassword"
        $focused={confirmPasswordInputFocused}
      />
      <PasswordErrorMessage
        id="passwordConfirmResponse"
        visible={true}
      />
      <PasswordInfoBlock />
      <CustomButton
        disabled={submitting}
        variant="blueWhite"
        text="Complete"
      />
    </CreatePasswordForm>
  );
};

export default SetPassword;
