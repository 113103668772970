import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  options: { name: string; label: string; value: string | number }[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  column?: boolean;
  onboarding?: boolean;
  className?: string;
}
const RadioButtonGroup = ({ options, column, onboarding, onChange, className }: Props) => {
  return (
    <Container
      className={className}
      $column={column}
    >
      {options.map((option, index) => {
        return (
          <RadioButtonContainer key={index}>
            <RadioButton
              id={option.label}
              name={option.name}
              type="radio"
              value={option.value}
              defaultChecked={index === 0}
              onChange={onChange}
            />
            {onboarding ? (
              <OnboardingLabel
                className={className}
                htmlFor={option.label}
              >
                {option.label}
              </OnboardingLabel>
            ) : (
              <Label
                className={className}
                htmlFor={option.label}
              >
                {option.label}
              </Label>
            )}
          </RadioButtonContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.div<{ $column?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$column ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const RadioButtonContainer = styled.div<{ $onboarding?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: ${(props) => (props.$onboarding ? 10 : 5)}px;
  box-sizing: border-box;
`;

const RadioButton = styled.input`
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 0;
  transition: 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: inherit;

  &:after {
    content: '';
    display: flex;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }

  &:hover,
  &:checked {
    border-color: ${(props) => props.theme.palette.greys.darkestGrey};

    &:after {
      background-color: ${(props) => props.theme.palette.greys.darkestGrey};
    }
  }

  &:checked {
    border-color: ${(props) => props.theme.palette.primary.blue};

    &:after {
      background-color: ${(props) => props.theme.palette.primary.blue};
    }
  }
`;

const Label = styled.label`
  ${FontStyles.h5};
  white-space: nowrap;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  display: flex;
  cursor: inherit;
  transition: 300ms ease-in-out;

  input[type='radio']:checked + & {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

const OnboardingLabel = styled.label`
  ${FontStyles.bodyMiddleGilroy};
  white-space: nowrap;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  display: flex;
  cursor: inherit;
  transition: 300ms ease-in-out;

  input[type='radio']:checked + & {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;
export default RadioButtonGroup;
