export const statuses = [
  { text: 'All Statuses', value: '' },
  { text: 'Completed', value: 'COMPLETED' },
  { text: 'Failed', value: 'FAILED' },
  { text: 'Pending', value: 'PENDING_CHALLENGE' },
  { text: 'Authorized', value: 'APPROVED' },
  { text: 'Rejected', value: 'REJECTED' },
  { text: 'Returned', value: 'RETURNED' },
  { text: 'Scheduled', value: 'SCHEDULED' },
  { text: 'Cancelled', value: 'CANCELLED' },
];
export const allTypes = [
  { text: 'All Types', value: '' },
  { text: 'International/Swift', value: 'SWIFT' },
  { text: 'Local/SEPA', value: 'SEPA' },
  { text: 'Card Payment', value: 'card' },
];
