import { ChangeEvent, RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconLocked, IconPin } from '@constants/icons';

import Modal from '@components/Modal/Modal';

import LargeButton from '@elements/LargeButton/LargeButton';

const MobileCardPopup = () => {
  const { t } = useTranslation();
  const pin = useRef<HTMLInputElement>(null);

  const closeModal = useBoundStore((state) => state.closeModal);
  const setWrongField = useBoundStore((state) => state.setWrongField);
  const wrongPin = useBoundStore((state) => state.wrongPin);
  const setWrongPin = useBoundStore((state) => state.setWrongPin);
  const attempts = useBoundStore((state) => state.attempts);
  const setAttempts = useBoundStore((state) => state.setAttempts);
  const setTimer = useBoundStore((state) => state.setTimer);
  const setHidden = useBoundStore((state) => state.setHidden);
  const setEnter = useBoundStore((state) => state.setEnter);

  const handleProceedClick = () => {
    if (pin.current) {
      const pinValue = pin.current.value;
      if (pinValue === '0000') {
        setAttempts(3);
        setWrongPin(false);
        setWrongField(false);
        setTimer(true);
        setHidden(false);
        closeModal();
      } else {
        setWrongPin(true);
        setWrongField(true);
        setAttempts(attempts > 0 ? attempts - 1 : 0);
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <IconLocked />
        <H4>{t('mobile_pin_header')}</H4>
        <Body2>{t('mobile_pin_subheader')}</Body2>
        <SubHeadline>{t('type_pin')}</SubHeadline>
        <IconInput inputRef={pin} />
        {wrongPin ? (
          <WrongPin>
            {t('wrong_pin_mobile')} {attempts.toString()} {t('wrong_pin_attempts')}
          </WrongPin>
        ) : (
          <Spacer />
        )}
        <LargeButton
          onClick={() => {
            setEnter(false);
            closeModal();
          }}
          text={t('close')}
          variant="transparentBlack"
          style={{ width: '100%', marginTop: '90px', marginBottom: '10px' }}
        />
        <LargeButton
          onClick={handleProceedClick}
          text={t('proceed')}
          variant="darkWhite"
          style={{ width: '100%' }}
        />
      </Wrapper>
    </Container>
  );
};

export default MobileCardPopup;

interface IconInputProps {
  inputRef: RefObject<HTMLInputElement>;
}

const IconInput = ({ inputRef }: IconInputProps) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.slice(0, 4);
  };

  const handleBlur = (e: any) => {
    if (!e.target.value) {
      setFocused(false);
    }
  };

  return (
    <InputContainer>
      <Icon style={{ opacity: focused ? 0 : 1 }} />
      <Input
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleOnChange}
        maxLength={4}
        minLength={4}
      />
    </InputContainer>
  );
};

const Container = styled(Modal)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 70px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50%;
`;

const H4 = styled.div`
  ${FontStyles.h4};
  margin-top: 15px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.palette.primary.black};
`;

const Body2 = styled.div`
  ${FontStyles.body2};
  margin-bottom: 34px;
  color: ${(props) => props.theme.palette.primary.black};
`;

const SubHeadline = styled.div`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  margin-bottom: 10px;
`;

const Input = styled.input.attrs({ type: 'password' })`
  ${FontStyles.buttonGilroyType2}
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  color: ${(props) => props.theme.palette.primary.black};
  border: none;
  border-radius: 10px;
  min-height: 70px;
  min-width: 250px;
  transition: border-color 0.3s, color 0.3s;
  text-align: center;
  &:focus {
    color: ${(props) => props.theme.palette.primary.blue};
    outline: 1px solid ${(props) => props.theme.palette.primary.blue};
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Icon = styled(IconPin)`
  position: absolute;
  left: 44%;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
`;

const WrongPin = styled.div`
  ${FontStyles.bodySmallGilroy}
  color: ${(props) => props.theme.palette.negative.lessContrastRed};
  margin-top: 10px;
`;

const Spacer = styled.div`
  height: 1em;
`;
