import { friendlyFormatIBAN } from 'ibantools';
import React, { ChangeEvent, FocusEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';

import { ibanValidate } from '@hooks/useValidation';

import IbanInputAutocompleteItem from '@components/IbanInputAutocompleteItem';

import TextFieldComponent from '@elements/input/TextField/TextFieldComponent';

interface Props {
  value: string;
  setValue: (value: string) => void;
  ibanList?: BankDetail[];
  className?: string;
  valid?: boolean;
  setValid: (name: string, value: boolean) => void;
}

const IbanInput = ({ value, setValue, ibanList, valid, setValid }: Props) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);

  const scrollingEnabled = ibanList ? ibanList.length > 3 : false;

  const selectedContact = useBoundStore((state) => state.selectedContact);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value.toUpperCase());
    setShowDropDown(true);
  };

  const handleValidate = (value: string) => {
    const validtext = ibanValidate(value);
    setValid('iban', !validtext);
    return validtext;
  };

  return (
    <Wrapper
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          const formatted = friendlyFormatIBAN(event.target.value);
          if (formatted) {
            setValue(formatted);
          }
          setShowDropDown(false);
        }
      }}
      onClick={() =>
        setShowDropDown(ibanList ? ibanList.length > 0 && selectedContact?.id !== '' : false)
      }
    >
      <TextFieldComponent
        labelTop="IBAN"
        placeholder="XXX - XXXX-XXXX-XXXX-XXXX-XXXX"
        value={value}
        validate={handleValidate}
        onChange={onChange}
      />
      {valid && (
        <IbanAutocomplete
          $scrollingEnabled={scrollingEnabled}
          $show={showDropDown && ibanList ? ibanList.length > 0 : false}
        >
          <Heading>
            <Trans t={t}>Suggested options</Trans>
          </Heading>
          <ContentWrapper>
            <Content $scrollingEnabled={scrollingEnabled}>
              {ibanList &&
                ibanList.map((iban, index) => {
                  return (
                    <IbanInputAutocompleteItem
                      key={index}
                      iban={iban.iban}
                      bankName="" //TODO Swift
                      setValue={setValue}
                      setShowDropDown={setShowDropDown}
                    />
                  );
                })}
            </Content>
          </ContentWrapper>
        </IbanAutocomplete>
      )}
    </Wrapper>
  );
};

export default IbanInput;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
`;

const IbanAutocomplete = styled.div<{
  $scrollingEnabled: boolean;
  $show: boolean;
}>`
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  top: 82px;
  flex: 1;
  max-height: 251px;
  padding: ${({ $scrollingEnabled }) => ($scrollingEnabled ? '15px 13px 15px 15px' : '15px')};
  width: 100%;
  border-radius: 15px;
  background-color: ${(props) => props.theme.palette.primary.white};
  box-shadow: 2px 7px 77px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  overflow-y: hidden;
  max-height: 221px;
`;

const Content = styled.div<{ $scrollingEnabled: boolean }>`
  ${Scrollbar};
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden auto;
  ${({ $scrollingEnabled }) =>
    $scrollingEnabled &&
    css`
      padding-right: 13px;
    `}
  }
`;

const Heading = styled.p`
  ${FontStyles.gilroy};
  color: ${(props) => props.theme.palette.greys.darkGrey};
`;
