import { WidgetInstance } from 'friendly-challenge';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  className?: string;
  onCaptchaSolved: (isValid: boolean) => void;
}

const Captcha = ({ className, onCaptchaSolved }: Props) => {
  const container = useRef<HTMLDivElement>(null);
  const widget = useRef<WidgetInstance | undefined>(undefined);

  useEffect(() => {
    const doneCallback = () => {
      onCaptchaSolved(true);
    };

    const errorCallback = (error: string) => {
      // TODO: use react-toastify here
      console.error('There was an error when trying to solve the Captcha.');
      console.error(error);
      onCaptchaSolved(false);
    };

    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: 'auto',
        doneCallback,
        errorCallback,
      });
    }

    return () => {
      if (widget.current !== undefined) {
        widget.current.reset();
      }
    };
  }, [container, onCaptchaSolved]);

  return (
    <Container
      ref={container}
      className={`frc-captcha${className ? ` ${className}` : ''}`}
      data-sitekey="FCMKKVENGMP2CRU1"
    />
  );
};

export default Captcha;

const Container = styled.div`
  ${FontStyles.bodySmallGilroy};
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 25px;

  & .frc-container {
    min-height: 0;
  }

  &.frc-captcha {
    border: unset;
    max-width: unset;
    padding: 0;
  }

  & .frc-banner {
    display: none;
  }

  & .frc-icon {
    margin: 0 10px 0 0;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 15px;
    margin-bottom: 0;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 0;
  }
`;
