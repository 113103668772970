import { Theme, createTheme } from '@mui/material';

let theme: Theme = createTheme({
  legacy: {
    palette: {
      primary: {
        black: '#151515',
        white: '#FFFFFF',
        blue: '#1099FD',
      },
      greys: {
        lightLightGrey: '#F3F3F9',
        middleGrey: '#D6DDEA',
        darkGrey: '#ACB3C0',
        darkestGrey: '#7B7B8A',
        asphaltTint: '#9B9FB6',
      },
      negative: {
        contrastRed: '#FD4734',
        lessContrastRed: '#FF7878',
        lessContrastRedLight: 'rgba(255, 120, 120, 0.5)',
        lessContrastRedLighter: 'rgba(255, 120, 120, 0.2)',
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
});

theme = createTheme(theme, {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          backgroundColor: theme.legacy.palette.greys.lightLightGrey,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.legacy.palette.negative.contrastRed,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.legacy.palette.primary.blue,
          },
        },
        input: {
          fontFamily: '"Gilroy Medium", sans-serif',
        },
        notchedOutline: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'transparent',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Gilroy Medium", sans-serif',
          '&.Mui-error': {
            color: theme.legacy.palette.negative.contrastRed,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          alignSelf: 'flex-end',
          '&.Mui-error': {
            fontFamily: '"Gilroy Medium", sans-serif',
            color: theme.legacy.palette.negative.contrastRed,
          },
        },
      },
    },
  },
});

export { theme };
