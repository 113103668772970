import { format } from 'date-fns';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { ExecutionContext, css, useTheme } from 'styled-components';

import { Commons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

import useMediaQuery from '@hooks/useMediaQuery';

interface Props {
  name: string;
  type: string;
  direction: string;
  icon: ReactElement;
  amount: number;
  timestamp: string;
  currency: Currency;
  onClick: () => void;
  status: string;
}

const getBackgroundColor = (variant: 'in' | 'out', props: ExecutionContext) => {
  return {
    in: props.theme.palette.positive.lessContrastGreenLighter,
    out: props.theme.palette.negative.lessContrastRedLighter,
  }[variant];
};

const getHoverBackgroundColor = (variant: 'in' | 'out', props: ExecutionContext) => {
  return {
    in: props.theme.palette.positive.lessContrastGreenLight,
    out: props.theme.palette.negative.lessContrastRedLight,
  }[variant];
};

const TransactionEntry = ({
  name,
  type,
  direction,
  icon,
  amount,
  timestamp,
  currency = 'EUR',
  onClick,
  status,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isLaptop = useMediaQuery(`(${theme.breakpoints.laptop})`);
  const isDesktop = useMediaQuery(`(${theme.breakpoints.desktop})`);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy h:mm a');
  };

  return (
    <Wrapper
      $status={status}
      kind={direction}
      onClick={onClick}
    >
      <Row>
        <IconWithBackground className="transaction-entry-icon">{icon}</IconWithBackground>
        <LeftCol>
          <Name>{name}</Name>
          <TransferKind>
            <Trans t={t}>{isLaptop ? `${type}` : 'date_and_time'}</Trans>
          </TransferKind>
        </LeftCol>
      </Row>
      <RightCol>
        <Balance className="balance">
          {Commons.currencyFormatter(direction === 'in' ? amount : -amount, currency)}
        </Balance>
        <Timestamp>
          {isDesktop ? `${t('date_and_time')} ${formatDate(timestamp)}` : formatDate(timestamp)}
        </Timestamp>
      </RightCol>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ kind: string; $status: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 15px;
  transition: background-color ease-in-out 300ms;
  border-top: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  min-height: 40px;
  box-sizing: border-box;
  flex-shrink: 0;
  cursor: pointer;

  ${(props) =>
    props.$status === 'PENDING' || props.$status === 'Pending'
      ? css`
          background-color: ${props.theme.palette.status.pendingFinishStatusBackground};

          & .transaction-entry-icon {
            background: ${props.theme.palette.status.pendingFinishStatusIcon};
          }

          &:hover {
            background: ${props.theme.palette.status.pendingFinishStatusBackgroundHover};

            & .transaction-entry-icon {
              background: ${props.theme.palette.status.pendingFinishStatusIconHover};
            }
          }
          @media (${(props) => props.theme.breakpoints.laptop}) {
            border: 1px solid ${(props) => props.theme.palette.status.pendingFinishStatus};
            border-radius: 10px;
            padding: 12px 15px;
            box-sizing: border-box;
            height: 64px;
          }
        `
      : css`
          ${props.$status === 'PENDING_CHALLENGE'
            ? css`
                & .transaction-entry-icon {
                  background: ${props.theme.palette.status.pendingFinishStatusIcon};
                }

                &:hover {
                  & .transaction-entry-icon {
                    background: ${props.theme.palette.status.pendingFinishStatusIconHover};
                  }
                }
              `
            : css`
                & .transaction-entry-icon {
                  background: ${getBackgroundColor(props.kind === 'in' ? 'in' : 'out', props)};
                }

                &:hover {
                  background: ${(props) => props.theme.palette.greys.lightLightGrey};

                  & .transaction-entry-icon {
                    background: ${getHoverBackgroundColor(
                      props.kind === 'in' ? 'in' : 'out',
                      props
                    )};
                  }
                }
              `}

          &:hover .balance {
            color: ${(props) => props.theme.palette.primary.blue};
          }

          @media (${(props) => props.theme.breakpoints.laptop}) {
            border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
            border-radius: 10px;
            padding: 12px 15px;
            box-sizing: border-box;
            height: 64px;
          }
        `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.spacing.s};
  gap: 2px;
  box-sizing: border-box;
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  box-sizing: border-box;
`;

const Name = styled.div`
  ${FontStyles.buttonGilroyType3}
  color: ${(props) => props.theme.palette.primary.black};
`;

const TransferKind = styled.div`
  ${FontStyles.bodySmallGilroy}
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const Balance = styled.div`
  ${FontStyles.buttonGilroyType3}
  color: ${(props) => props.theme.palette.primary.black};
  transition: ease-in-out 300ms;
  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

const Timestamp = styled.div`
  ${FontStyles.bodySmallGilroy}
  color: ${(props) => props.theme.palette.greys.asphaltTint};
`;

const IconWithBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: background-color 300ms ease-in-out;

  & svg {
    height: 15px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 40px;
    height: 40px;

    & svg {
      height: 24px;
    }
  }
`;

export default TransactionEntry;
