import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useBoundStore } from '@stores/BoundStore';

import { FormValidation } from '@shared/formValidation';

import { IconOnboardingCheck, IconOnboardingGetStarted } from '@constants/icons';

import {
  AccountWrapper,
  ButtonWrapper,
  Container,
  CustomCaptcha,
  CustomLargeButton,
  CustomTextInput,
  EmailErrorContainer,
  FirstNameErrorContainer,
  FormWrapper,
  HaveAnAccount,
  Label,
  LastNameErrorContainer,
  LoginText,
  NameWrapper,
  OnboardingLogo,
  OuterContainer,
  Subtitle,
  SwitchWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from './OnboardingAccountDetails.styles';

const OnboardingAccountDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setCorporateData = useBoundStore((state) => state.setCorporateData);
  const corporateData = useBoundStore((state) => state.corporateData);

  const [firstNameValid, setFirstNameValid] = useState<boolean>(true);
  const [lastNameValid, setLastNameValid] = useState<boolean>(true);
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [captchaValid, setCaptchaValid] = useState(false);

  const handleChange = useCallback(
    (name: string, value: string | boolean) => {
      setCorporateData((prevState) => ({ ...prevState, [name]: value }));
    },
    [setCorporateData]
  );

  const handleBlur = useCallback(() => {
    FormValidation.validateField(
      corporateData.firstName,
      FormValidation.RegEx.NAME,
      setFirstNameValid
    );
    FormValidation.validateField(
      corporateData.lastName,
      FormValidation.RegEx.NAME,
      setLastNameValid
    );
    FormValidation.validateField(corporateData.email, FormValidation.RegEx.EMAIL, setEmailValid);

    setFormValid(
      emailValid &&
        corporateData.email !== '' &&
        firstNameValid &&
        corporateData.firstName !== '' &&
        lastNameValid &&
        corporateData.lastName !== '' &&
        captchaValid
    );
  }, [
    corporateData.email,
    corporateData.firstName,
    corporateData.lastName,
    emailValid,
    firstNameValid,
    lastNameValid,
    captchaValid,
  ]);

  useEffect(() => {
    handleBlur();
  }, [handleBlur, handleChange]);

  return (
    <FormWrapper>
      <OnboardingLogo gradientId="onboardingLogo" />
      <OuterContainer>
        <Container>
          <TitleWrapper>
            <Title>Create your account</Title>
            <Subtitle>Your Trusted Specialist in Financial Payments</Subtitle>
          </TitleWrapper>
          <NameWrapper>
            <Wrapper>
              <Label>{t('first_name')}</Label>
              <CustomTextInput
                autoComplete="do-not-autofill"
                blurHandler={handleBlur}
                inputMode="text"
                placeholder={t('first_name')}
                icon={firstNameValid && corporateData.firstName !== '' && <IconOnboardingCheck />}
                value={corporateData.firstName}
                valid={firstNameValid}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange('firstName', event.target.value);
                }}
              />
              <FirstNameErrorContainer $visible={!firstNameValid}>
                Only letters (min. 2) are allowed
              </FirstNameErrorContainer>
            </Wrapper>
            <Wrapper>
              <Label>{t('last_name')}</Label>
              <CustomTextInput
                autoComplete="do-not-autofill"
                blurHandler={handleBlur}
                inputMode="text"
                placeholder={t('last_name')}
                icon={lastNameValid && corporateData.lastName !== '' && <IconOnboardingCheck />}
                value={corporateData.lastName}
                valid={lastNameValid}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange('lastName', event.target.value);
                }}
              />
              <LastNameErrorContainer $visible={!lastNameValid}>
                Only letters (min. 2) are allowed
              </LastNameErrorContainer>
            </Wrapper>
          </NameWrapper>
          <Wrapper>
            <Label>Email</Label>
            <CustomTextInput
              autoComplete="do-not-autofill"
              blurHandler={handleBlur}
              inputMode="email"
              placeholder="Email"
              icon={emailValid && corporateData.email !== '' && <IconOnboardingCheck />}
              value={corporateData.email}
              valid={emailValid}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleChange('email', event.target.value);
              }}
            />
          </Wrapper>
          <EmailErrorContainer $visible={!emailValid}>No valid E-Mail</EmailErrorContainer>
          <SwitchWrapper>
            <CustomCaptcha onCaptchaSolved={setCaptchaValid} />
          </SwitchWrapper>
          <ButtonWrapper>
            <AccountWrapper>
              <HaveAnAccount>Already have an account?</HaveAnAccount>
              <LoginText
                end
                to="/login"
              >
                Login
              </LoginText>
            </AccountWrapper>
            <CustomLargeButton
              onClick={() =>
                navigate('/register/2', {
                  state: {
                    from: location.pathname,
                  },
                })
              }
              text="Get Started"
              variant="blueWhite"
              icon={<IconOnboardingGetStarted />}
              disabled={!formValid}
            />
          </ButtonWrapper>
        </Container>
      </OuterContainer>
    </FormWrapper>
  );
};

export default OnboardingAccountDetails;
