import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useGetCorporate, useGetKYB, useStartKYB } from '@api/Corporate/corporateApi';
import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { useGetContacts } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { IconPlus } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import ContactsList from '@components/ContactsList/ContactsList';
import EmailModal from '@components/EmailModal/EmailModal';
import NoContactsPlaceholder from '@components/NoContactsPlaceholder/NoContactsPlaceholder';
import OTPModal from '@components/OTPModal/OTPModal';
import {
  AddIconButton,
  Container,
  Content,
  ContentWrapper,
  Fade,
  Footer,
  FooterWrapper,
  Header,
  HeaderTop,
  LargeMobileButton,
  Link,
  TopHeading,
  VerifyButton,
} from '@components/QuickTransfer/QuickTransfer.styles';
import SearchBar from '@components/SearchBar/SearchBar';

interface Props {
  className?: string;
}

const defaultContacts: ContactsWithBankDetails[] = [];

const QuickTransfer = ({ className }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);

  const collapsed = useBoundStore((state) => state.collapsed);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const openModal = useBoundStore((state) => state.openModal);

  const searchQuery = useBoundStore((state) => state.searchQuery);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: corporateData } = useGetCorporate();
  const { data: kybData } = useGetKYB();
  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const { data: contactData, isFetching } = useGetContacts(managedAccountId, searchQuery);

  const { contacts } = contactData ?? { contacts: [] };

  const { mutate: startCorporateKybMutation } = useStartKYB();

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (submitting) return;

    setSubmitting(true);

    if (!corporateData) {
      setSubmitting(false);
      return;
    }

    const { rootUser } = corporateData;
    const { emailVerified, mobileNumberVerified } = rootUser;

    if (!emailVerified) {
      openModal(<EmailModal />);
      setSubmitting(false);
    } else if (!mobileNumberVerified) {
      openModal(<OTPModal />);
      setSubmitting(false);
    } else {
      startCorporateKybMutation(undefined, {
        onSuccess: (data) => {
          if (data) {
            const { reference } = data;
            navigate('/kyb', { state: { kybRef: reference } });
          }
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container
      $verified={verifiedKYC}
      $collapsed={collapsed}
      className={className}
    >
      <Header>
        <HeaderTop>
          <TopHeading>{t('quick_transfer')}</TopHeading>
          <AddIconButton to="/send-money">
            <IconPlus />
          </AddIconButton>
        </HeaderTop>
        <div>
          <SearchBar />
        </div>
      </Header>
      <ContentWrapper>
        <Content $verified={verifiedKYC}>
          {!verifiedKYC || (contacts && contacts.length < 1 && !isFetching) ? (
            <>
              <NoContactsPlaceholder
                screen="dashboard"
                size={isTablet ? 'tablet' : 'mobile'}
                text="no_contacts_yet"
              />
              {!verifiedKYC && (
                <VerifyButton
                  disabled={submitting}
                  onClick={handleButtonClick}
                  text={checkIfKybInitiated() ? 'View Status' : t('verify_account')}
                  variant="transparentBlack"
                />
              )}
            </>
          ) : (
            <ContactsList
              contacts={contacts}
              searching={searchQuery}
            />
          )}
        </Content>
      </ContentWrapper>
      <FooterWrapper>
        {verifiedKYC && <Fade />}
        <Footer
          $verified={verifiedKYC}
          $contacts={contacts ? contacts.length : defaultContacts.length}
        >
          <Link to="/send-money">{t('contact_list')}</Link>
        </Footer>
      </FooterWrapper>
      <LargeMobileButton
        className={!verifiedKYC ? 'disabled' : ''}
        to="/send-money"
      >
        {t('contact_list')}
      </LargeMobileButton>
    </Container>
  );
};

export default QuickTransfer;
