import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { IconHamburgerClose, IconHamburgerCloseDark } from '@constants/icons';

import { useLockBodyScroll } from '@hooks/useLockBodyScroll';

interface Props {
  children: ReactNode;
  backdropBackground?: ModalBackdropBackgroundType;
  className?: string;
  showCloseIcon?: boolean;
  dark?: boolean;
}

const Modal = ({
  showCloseIcon = true,
  children,
  backdropBackground = 'dark',
  className,
  dark,
}: Props) => {
  const closeModal = useBoundStore((state) => state.closeModal);
  console.log('dark', dark);
  useLockBodyScroll(true);

  const onContentClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Overlay
      $backdropBackground={backdropBackground}
      onClick={closeModal}
    >
      <OverlayClickWrapper onClick={(e) => onContentClick(e)}>
        <ModalContainer className={className}>
          {showCloseIcon && (
            <CloseIcon $dark={!!dark}>
              {dark ? (
                <IconHamburgerCloseDark
                  onClick={() => {
                    closeModal();
                  }}
                />
              ) : (
                <IconHamburgerClose
                  onClick={() => {
                    closeModal();
                  }}
                />
              )}
            </CloseIcon>
          )}
          <ModalContentContainer>{children}</ModalContentContainer>
        </ModalContainer>
      </OverlayClickWrapper>
    </Overlay>
  );
};

const Overlay = styled.div<{
  $backdropBackground: ModalBackdropBackgroundType;
}>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: ${(props) =>
    props.$backdropBackground === 'light' ? css`blur(10.5px)` : css`blur(9px)`};
  -webkit-backdrop-filter: ${(props) =>
    props.$backdropBackground === 'light' ? css`blur(10.5px)` : css`blur(9px)`};
  background: ${(props) =>
    props.$backdropBackground === 'light'
      ? css`linear-gradient(249.15deg, rgba(255, 255, 255, 0.92) 2.79%, rgba(255, 255, 255, 0.92) 97.66%)`
      : props.theme.palette.gradients.greySilverGradient.alpha};
  z-index: 1000;
  justify-content: center;
  animation: ${(props) => props.theme.animations.fadeIn} 0.8s;
`;

const OverlayClickWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: initial;
    height: initial;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.primary.white};
  max-width: 320px;
  border-radius: 20px;
  @media (${({ theme }) => theme.breakpoints.mobile}) {
    max-width: 396px;
  }
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden auto;
  ${HideScrollbar};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    overflow: unset;
  }
`;
const CloseIcon = styled.div<{
  $dark: boolean;
}>`
  display: flex;
  justify-content: end;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.blue};
  border-bottom: ${(props) =>
    props.$dark ? '' : `1px solid ${props.theme.palette.greys.middleGrey}`};
  padding: 16px 0;

  width: 100%;
  //box-shadow: -3.8px -3.8px 9.5px 0 #fff, -5.067px -5.067px 12.667px 0 #fff,
  //  3.8px 3.8px 9.5px 0 rgba(123, 123, 138, 0.08),
  //  5.067px 5.067px 12.667px 0 rgba(174, 174, 192, 0.2);

  & > svg {
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-right: 16px;
    fill: ${(props) => (props.$dark ? '#fff' : '')};
  }
`;

export default Modal;
