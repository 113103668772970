import React, { useState } from 'react';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconBlueCheck, IconDeleteLarge } from '@constants/icons';
import {
  DocumentDeleteSuccessLargeSet,
  DocumentDeleteSuccessSmallSet,
  InviteSuccessBGsMobile,
} from '@constants/images';

import Modal from '@components/Modal/Modal';
import SettingsUserPanelForModal from '@components/Settings/SettingsUserPanel/SettingsUserPanelForModal';

import LargeButton from '@elements/LargeButton/LargeButton';

interface Props {
  name?: string;
  role?: string;
  verified: boolean;
  image?: string;
}

const RevokeUserAccessModal = ({ name, role, verified, image }: Props) => {
  const [deleted, setDeleted] = useState(false);

  const closeModal = useBoundStore((state) => state.closeModal);

  return (
    <MainWrapper className={deleted ? 'deleted' : ''}>
      <Container>
        <Content>
          <TopContent>
            <Icon>{deleted ? <IconBlueCheck /> : <IconDeleteLarge />}</Icon>
            <HeadLine>{deleted ? 'Success' : 'Delete user'}</HeadLine>
            <SubHeadLine>
              {deleted ? 'User access has been revoked' : 'Are you sure you want to revoke access?'}
            </SubHeadLine>
          </TopContent>
          <SettingsUserPanelForModal
            verified={verified}
            name={name}
            role={role}
            image={image}
            className={deleted ? 'user' : ''}
          />
          <BottomContent>
            {!deleted ? (
              <>
                <DeleteAllButton onClick={() => setDeleted(true)}>Yes, revoke</DeleteAllButton>
                <CloseButtonDelete onClick={() => closeModal()}>Close</CloseButtonDelete>
              </>
            ) : (
              <DoneButton
                onClick={() => {
                  closeModal();
                  setDeleted(false);
                }}
                text="Done"
                variant="blueWhite"
              />
            )}
          </BottomContent>
        </Content>
      </Container>
    </MainWrapper>
  );
};

export default RevokeUserAccessModal;

const MainWrapper = styled(Modal)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-size: cover;
  height: 100%;
  box-sizing: border-box;

  background-image: url(${DocumentDeleteSuccessSmallSet[0]});
  background-image: -webkit-image-set(
    url(${DocumentDeleteSuccessSmallSet[0]}) 1x,
    url(${DocumentDeleteSuccessSmallSet[1]}) 2x
  );
  background-image: image-set(
    url(${DocumentDeleteSuccessSmallSet[0]}) 1x,
    url(${DocumentDeleteSuccessSmallSet[1]}) 2x
  );

  &.deleted {
    background-image: url(${InviteSuccessBGsMobile[0]});
    background-image: -webkit-image-set(
      url(${InviteSuccessBGsMobile[0]}) 1x,
      url(${InviteSuccessBGsMobile[1]}) 2x
    );
    background-image: image-set(
      url(${InviteSuccessBGsMobile[0]}) 1x,
      url(${InviteSuccessBGsMobile[1]}) 2x
    );
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    border-radius: 20px;
    width: 650px;
    height: 760px;
    background-image: url(${DocumentDeleteSuccessLargeSet[0]});
    background-image: -webkit-image-set(
      url(${DocumentDeleteSuccessLargeSet[0]}) 1x,
      url(${DocumentDeleteSuccessLargeSet[1]}) 2x
    );
    background-image: image-set(
      url(${DocumentDeleteSuccessLargeSet[0]}) 1x,
      url(${DocumentDeleteSuccessLargeSet[1]}) 2x
    );

    &.deleted {
      background-image: url(${DocumentDeleteSuccessLargeSet[0]});
      background-image: -webkit-image-set(
        url(${DocumentDeleteSuccessLargeSet[0]}) 1x,
        url(${DocumentDeleteSuccessLargeSet[1]}) 2x
      );
      background-image: image-set(
        url(${DocumentDeleteSuccessLargeSet[0]}) 1x,
        url(${DocumentDeleteSuccessLargeSet[1]}) 2x
      );
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden auto;
  box-sizing: border-box;

  ${HideScrollbar};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    overflow: unset;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 0;

  & > .user {
    opacity: 0.15;
  }
`;

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 292px;
  padding-bottom: 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 376px;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: initial;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  width: 76px;
  background-color: ${(props) => props.theme.palette.primary.white};
  box-shadow: 8px 8px 20px 0 rgba(174, 174, 192, 0.2), 6px 6px 15px 0 rgba(123, 123, 138, 0.08),
    -8px -8px 20px 0 #fff, -6px -6px 15px 0 #fff;
  border-radius: 85px;

  svg {
    width: 38px;
    height: 38px;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 120px;
    width: 120px;

    svg {
      width: initial;
      height: initial;
    }
  }
`;

const HeadLine = styled.h3`
  ${FontStyles.h3};
  color: ${(props) => props.theme.palette.primary.black};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.h2};
  }
`;

const SubHeadLine = styled.h6`
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.primary.black};
  margin-top: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

const CommonButton = styled.button`
  display: flex;
  box-sizing: border-box;
  max-height: 50px;
  padding: 15px 39px;
  ${FontStyles.buttonGilroyType3};
  border-radius: 75px;
  width: 230px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 300ms ease-in-out;
`;

const DeleteAllButton = styled(CommonButton)`
  background-color: ${(props) => props.theme.palette.negative.lessContrastRed};
  color: ${(props) => props.theme.palette.primary.white};
  border: 1px solid transparent;

  &:hover {
    background-color: ${(props) => props.theme.palette.negative.contrastRed};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 30px;
  }
`;

const CloseButtonDelete = styled(CommonButton)`
  color: ${(props) => props.theme.palette.primary.black};
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  background: linear-gradient(145deg, rgba(0, 0, 0, 0%), rgba(0, 0, 0, 0%), #232222, #34354a);
  background-size: 500% auto;

  &:hover {
    border: 1px solid transparent;
    color: ${(props) => props.theme.palette.primary.white};
    background-position: 90% 120%;
  }
`;

const DoneButton = styled(LargeButton)`
  width: 240px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 40px;
  }
`;
