import { IconButton } from '@mui/material';
import { values } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { DeleteItem } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import { ExportButton } from '@components/ExportButton/ExportButton';
import Filter from '@components/Filters/Filters';
import { allTypes, statuses } from '@components/Filters/Statuses';
import PaginationMUI from '@components/Pagination/PaginationMUI';
import RadioSelectBox from '@components/RadioSelectBox/RadioSelectBox';
import SearchBar from '@components/SearchBar/SearchBar';
import AnalyticsTransactions from '@components/analytics/AnalyticsTransactions/AnalyticsTransactions';
import SelectedFilterList from '@components/analytics/AnalyticsTransactionsWrapper/SelectedFilterList';

import {
  Container,
  ItemCount,
  LeftWrapp,
  ScrollFilters,
  SelectedFilter,
  Title,
  Top,
} from './AnalyticsTransactionsWrapper.styles';

interface Props {
  data: DbTransaction[] | null;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  availableEntries: number;
  isLoading: boolean;
}

const AnalyticsTransactionsWrapper = ({
  setCurrentPage,
  availableEntries,
  data,
  currentPage,
  isLoading,
}: Props) => {
  const offset = useBoundStore((state) => state.offset);
  const setOffset = useBoundStore((state) => state.setOffset);
  const visibleRows = useBoundStore((state) => state.visibleRows);
  const setVisibleRows = useBoundStore((state) => state.setVisibleRows);
  const setFilterType = useBoundStore((state) => state.setFilterType);
  const filterType = useBoundStore((state) => state.filterType);
  const filterStatus = useBoundStore((state) => state.filterStatus);
  const selected = useBoundStore((state) => state.selected);
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  return (
    <Container>
      <Title>Transactions</Title>
      <Top>
        <LeftWrapp>
          <SearchBar />
          {isLaptop && (
            <RadioSelectBox
              setFilter={setFilterType}
              options={allTypes}
            />
          )}
        </LeftWrapp>
        <Filter />
        {/*<DropDownContainer>*/}
        {/*  <RadioSelectBox*/}
        {/*    setFilter={setFilterStatus}*/}
        {/*    options={[*/}
        {/*      { text: 'All Statuses', value: 'all_statuses' },*/}
        {/*      { text: 'Completed', value: 'COMPLETED' },*/}
        {/*      { text: 'Failed', value: 'FAILED' },*/}
        {/*      { text: 'Pending', value: 'PENDING' },*/}
        {/*      { text: 'Authorized', value: 'APPROVED' },*/}
        {/*      { text: 'Rejected', value: 'REJECTED' },*/}
        {/*      { text: 'Returned', value: 'RETURNED' },*/}
        {/*      { text: 'Scheduled', value: 'SCHEDULED' },*/}
        {/*      { text: 'Cancelled', value: 'CANCELLED' },*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*  <RadioSelectBox*/}
        {/*    setFilter={setFilterType}*/}
        {/*    options={[*/}
        {/*      { text: 'All Types', value: 'all_types' },*/}
        {/*      { text: 'International/Swift', value: 'SWIFT' },*/}
        {/*      { text: 'Local/SEPA', value: 'SEPA' },*/}
        {/*      { text: 'Card Payment', value: 'card' },*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</DropDownContainer>*/}
      </Top>
      {selected.length || filterType || filterStatus ? (
        <>
          {!isLaptop ? <SelectedFilterList /> : null}
          <Top>
            <LeftWrapp>
              {selected.length ? <ItemCount>{selected.length} items selected</ItemCount> : null}
              {isLaptop ? <SelectedFilterList /> : null}
            </LeftWrapp>

            <ExportButton />
          </Top>
        </>
      ) : null}
      <AnalyticsTransactions
        data={data}
        currentPage={currentPage}
        isLoading={isLoading}
      />
      <PaginationMUI
        visibleRows={visibleRows}
        setVisibleRows={setVisibleRows}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        availableEntries={availableEntries}
        offset={offset}
        setOffset={setOffset}
      />
    </Container>
  );
};

export default AnalyticsTransactionsWrapper;
