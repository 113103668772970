import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

export const Container = styled.div`
  display: flex;
  border-radius: 20px;
  min-width: 335px;
  background-color: ${(props) => props.theme.palette.primary.white};
  flex-direction: column;
  padding-bottom: 35px;
  margin-bottom: 40px;
  box-sizing: border-box;
  & > :last-child {
    margin-top: auto;
  }
`;

export const Title = styled.div`
  padding: 20px 20px 10px;
  ${FontStyles.h6Main};
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    padding: 35px 35px 15px 35px;
  }
`;

export const Top = styled.div`
  display: flex;
  padding: 9px 20px 16px;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  ${FontStyles.h5};
  justify-content: space-between;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 15px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    padding: 15px 35px 15px 35px;
    gap: 30px;
  }
`;

export const LeftWrapp = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  & > :nth-child(1) {
    margin-right: 10px;
  }
`;

export const ItemCount = styled.div`
  //styleName: table/header;
  ${FontStyles.bodySmallGilroy};
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;
export const SelectedFilter = styled.div`
  //styleName: table/header;
  display: flex;
  align-items: center;
  justify-content: center;
  ${FontStyles.gilroy};
  font-size: 13px;
  border-radius: 24px;
  height: 24px;
  padding: 3px 6px 3px 10px;
  background-color: ${(props) => props.theme.palette.greys.lightGrey};
  margin-left: 10px;
  & button {
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #dcdcdc;
    margin-left: 4px;
  }
  & svg {
    width: 10px;
    height: 10px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.primary};
  }
`;
export const ScrollFilters = styled.div`
  display: flex;
  margin: 0 10px 10px 10px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 0;
  }
`;
export const IconWrap = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  & svg {
    width: 100%;
    height: 100%;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 0;
  }
`;
