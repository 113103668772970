import React, { useState } from 'react';
import styled from 'styled-components';

import { useUpdateContact } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import TransactionConfirmation from '@components/transaction/TransactionConfirmation';

import BlueButton from '@elements/BlueButton/BlueButton';
import TextFieldComponent from '@elements/input/TextField/TextFieldComponent';

const IbanForm = () => {
  const { mutate: updateContact } = useUpdateContact();
  const selectedContact = useBoundStore((state) => state.selectedContact);

  const [iban, setIban] = useState('');
  const valid = true;

  const updateContactIban = () => {
    setIban('');
    if (selectedContact) {
      const newContact = { ...selectedContact };
      newContact.bankDetails = [...selectedContact.bankDetails, { iban: iban, bic: '' }];

      updateContact(newContact);
    }
  };

  return (
    <Container>
      <TextFieldComponent
        labelTop="Add new IBAN"
        placeholder="Add new IBAN"
        value={iban}
        onChange={(e) => setIban(e.target.value)}
        type="outlined"
      />
      <ButtonWrap>
        <BlueButton
          onClick={updateContactIban}
          size="small"
          disabled={!valid}
        >
          Add to IBAN’s list
        </BlueButton>
      </ButtonWrap>
    </Container>
  );
};
export default IbanForm;

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: end;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;
const ButtonWrap = styled.div`
  width: 147px;
  box-sizing: border-box;
`;
