import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from './Cards.styles';

const Cards = () => {
  return (
    <Container>
      Card View
      <Outlet />
    </Container>
  );
};

export default Cards;
