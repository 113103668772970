import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useGetCorporate, useGetKYB, useStartKYB } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import {
  IconStatusBar,
  IconStatusBarBlue,
  IconStatusBarStar,
  IconStatusBarStarBlue,
  IconVerify,
} from '@constants/icons';
import { KYCBackgroundEmailSet, KYCBackgroundPhoneSet, KYCBackgroundSet } from '@constants/images';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import EmailModal from '@components/EmailModal/EmailModal';
import OTPModal from '@components/OTPModal/OTPModal';

import LargeButton from '@elements/LargeButton/LargeButton';

interface Props {
  company: boolean;
}

const AccountDetailsKYC = ({ company }: Props) => {
  const navigate = useNavigate();

  const openModal = useBoundStore((state) => state.openModal);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const {
    data: corporate,
    isError: isGetCorporateError,
    error: getCorporateError,
  } = useGetCorporate();
  const { data: kybData, isError: isGetKYBError, error: getKYBError } = useGetKYB();

  const { mutate: startCorporateKybMutation } = useStartKYB();

  const emailVerified = corporate?.rootUser.emailVerified ?? false;
  const mobileNumberVerified = corporate?.rootUser.mobileNumberVerified ?? false;
  const rootUserId = corporate?.rootUser.id.id ?? '';

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (isGetKYBError && getKYBError) {
      errorsToHandle.push(getKYBError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [getCorporateError, getKYBError, handleHTTPErrors, isGetCorporateError, isGetKYBError]);

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (!emailVerified) {
      openModal(<EmailModal />);
    } else if (!mobileNumberVerified) {
      openModal(<OTPModal />);
    } else {
      startCorporateKybMutation(undefined, {
        onSuccess: (data) => {
          if (data) {
            const { reference } = data;
            navigate('/kyb', { state: { kybRef: reference } });
          }
        },
      });
    }
  };

  return corporate ? (
    <>
      {rootUserId !== '' && (
        <Container
          $email={!emailVerified}
          $phone={!mobileNumberVerified}
        >
          <WrapperContent className={company ? 'company' : ''}>
            <StatusBar
              $email={!emailVerified}
              $phone={!mobileNumberVerified}
            >
              {!emailVerified || !mobileNumberVerified ? (
                <IconVerify />
              ) : checkIfKybInitiated() ? (
                <>
                  <IconStatusBarStar />
                  <IconStatusBar />
                </>
              ) : (
                <>
                  <IconStatusBarStarBlue />
                  <IconStatusBarBlue />
                </>
              )}
            </StatusBar>
            <Text
              $email={!emailVerified}
              $phone={!mobileNumberVerified}
            >
              <p className="huge">
                {!emailVerified
                  ? 'Verify your email address'
                  : !mobileNumberVerified
                  ? 'Enable 2-factor authentication'
                  : checkIfKybInitiated()
                  ? 'Verification process initiated'
                  : company
                  ? 'Start verification process'
                  : 'Verify yourself to access all features'}
              </p>
              <p className="small">
                {!emailVerified
                  ? 'Complete your account setup by verifying your email address.'
                  : !mobileNumberVerified
                  ? 'Please confirm your phone number to finalize your registration.'
                  : checkIfKybInitiated()
                  ? 'We are currently processing the documents you provided'
                  : company
                  ? 'To get started you need to verify your organisation'
                  : 'To get started you need to verify yourself'}
              </p>
            </Text>
            <VerifyButton
              onClick={handleButtonClick}
              text={
                !emailVerified
                  ? 'Verify Email'
                  : !mobileNumberVerified
                  ? 'Get Started'
                  : checkIfKybInitiated()
                  ? 'View Status'
                  : company
                  ? 'Verify organization'
                  : 'Verify myself'
              }
              variant="blueBlack"
            />
          </WrapperContent>
        </Container>
      )}
    </>
  ) : null;
};

export default AccountDetailsKYC;

const Container = styled.div<{ $phone: boolean; $email: boolean }>`
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding: 35px;
  box-sizing: border-box;
  flex: 1;
  min-width: 943px;
  height: 313px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-image: url(${KYCBackgroundSet[0]});
  background-image: -webkit-image-set(
    url(${KYCBackgroundSet[0]}) 1x,
    url(${KYCBackgroundSet[1]}) 2x
  );

  ${(props) =>
    props.$email
      ? css`
          background-image: url(${KYCBackgroundEmailSet[0]});
          background-image: -webkit-image-set(
            url(${KYCBackgroundEmailSet[0]}) 1x,
            url(${KYCBackgroundEmailSet[1]}) 2x
          );
        `
      : props.$phone
      ? css`
          background-image: url(${KYCBackgroundPhoneSet[0]});
          background-image: -webkit-image-set(
            url(${KYCBackgroundPhoneSet[0]}) 1x,
            url(${KYCBackgroundPhoneSet[1]}) 2x
          );
        `
      : null}

  & > .company {
    width: 320px;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  & > .company {
    width: 320px;
  }
`;

const StatusBar = styled.div<{ $phone: boolean; $email: boolean }>`
  display: flex;
  gap: 7px;
  padding-bottom: 20px;

  ${(props) =>
    (props.$email || props.$phone) &&
    css`
      margin-left: -10px;
      padding-bottom: initial;
    `}
`;

const Text = styled.div<{ $phone: boolean; $email: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.palette.primary.white};
  gap: 10px;
  //padding-bottom: 25px;
  width: 278px;

  ${(props) =>
    (props.$email || props.$phone) &&
    css`
      padding-bottom: initial;
    `}

  & > .huge {
    ${FontStyles.h4};
  }

  & > .small {
    ${FontStyles.bodySmallGilroy};
    height: 52px;
  }
`;

const VerifyButton = styled(LargeButton)`
  display: flex;
  width: 190px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
`;
