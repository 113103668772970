import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconOnboardingCheck } from '@constants/icons';

import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';
import RadioButtonGroup from '@components/RadioButtonGroup/RadioButtonGroup';

import LargeButton from '@elements/LargeButton/LargeButton';
import TextInput, { Field } from '@elements/input/TextInput/TextInput';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
  padding: 60px 20px 50px 20px;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 0;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: unset;
    margin-top: 15px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 27px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 0 50px 0 95px;
  }
`;

export const CustomIconOnboardingCheck = styled(IconOnboardingCheck)`
  width: 17px;
  height: 15px;
`;

export const RadioInputRow = styled(InputRow)`
  margin-bottom: 40px;
`;

export const Label = styled.label`
  ${FontStyles.h5};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const RadioLabel = styled(Label)`
  margin-bottom: 20px;
`;

/*const DateOfBirthLabel = styled(Label)`
  margin-bottom: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 20px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 10px;
  }
  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-bottom: 20px;
  }
`;*/

export const CustomTextInput = styled(TextInput)`
  width: 100%;
  ${FontStyles.bodyMiddleGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    ${FontStyles.bodySmallGilroy};
    ${Field} {
      ${FontStyles.bodySmallGilroy};
    }
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    ${Field} {
      ${FontStyles.bodyMiddleGilroy};
    }
    height: 50px;
  }
  & ::placeholder {
    ${FontStyles.body2};
    ${Field} {
      ${FontStyles.body2};
    }
  }
`;

/*const DatepickerInput = styled.button<{ $opened: boolean }>`
  width: 100%;
  height: 50px;
  padding: 12px 20px;
  box-sizing: border-box;
  ${FontStyles.bodyMiddleGilroy};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.greys.lightLightGrey};
  color: ${(props) =>
    props.$opened ? props.theme.palette.primary.black : props.theme.palette.greys.darkestGrey};

  &:focus {
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    ${FontStyles.body2};
    ${Field} {
      ${FontStyles.body2};
    }
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    ${Field} {
      ${FontStyles.bodyMiddleGilroy};
    }
    height: 50px;
  }
`;*/
export const MobilePhoneRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CustomRadioGroup = styled(RadioButtonGroup)`
  align-self: start;
`;

export const CustomStepNavigation = styled(OnboardingStepNavigation)`
  margin-bottom: 50px;
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: start;
    margin-bottom: 40px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 52px;
  }
  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-top: 16px;
    margin-bottom: 80px;
  }
`;

export const ErrorContainer = styled.div<{ $visible: boolean }>`
  ${FontStyles.bodySmallGilroy};
  height: 25px;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0 0 0;
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  opacity: ${(props) => (props.$visible ? '1' : '0')};
  justify-content: end;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 30px;
    margin: 5px 0 5px 0;
  }
`;

export const ButtonRow = styled.div`
  display: flex;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    justify-content: end;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-top: 80px;
    padding-right: 50px;
  }
  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-top: 72px;
    padding-right: 95px;
  }
`;

export const CustomLargeButton = styled(LargeButton)`
  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    font-size: 14px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 50px;
    font-size: 16px;
  }
`;

export const EmailErrorMessage = styled.div<{ visible: boolean }>`
  box-sizing: border-box;
  ${FontStyles.bodySmallGilroy};
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  justify-content: end;
  height: 30px;
  align-items: center;
`;
