import { ReactComponent as NotFound } from '@assets/images/404NotFound.svg';
import AnalyticsCenter2x from '@assets/images/AnalyticCenter2x.png';
import AnalyticsCenter from '@assets/images/AnalyticCenter.png';
import AnalyticsCenterMin2x from '@assets/images/AnalyticCenterMin2x.png';
import AnalyticsCenterMin from '@assets/images/AnalyticCenterMin.png';
import AnalyticsCenterSmall from '@assets/images/AnalyticsCenterSmall.svg';
import BackgroundInviteUserSuccess2x from '@assets/images/BackgroundInviteUserSuccess2x.png';
import BackgroundInviteUserSuccess from '@assets/images/BackgroundInviteUserSuccess.png';
import BgMobileError2x from '@assets/images/BgErrorMobile2x.png';
import BgMobileError from '@assets/images/BgErrorMobile.png';
import BgMobileSuccess2x from '@assets/images/BgSuccessMobile2x.png';
import BgMobileSuccess from '@assets/images/BgSuccessMobile.png';
import { ReactComponent as BlueCheck } from '@assets/images/BlueCheck.svg';
import CardBefore2x from '@assets/images/CardBefore2x.png';
import CardBefore from '@assets/images/CardBefore.png';
import { ReactComponent as CardGradient } from '@assets/images/CardGradient.svg';
import CardModalDesktop2x from '@assets/images/CardModalDeskop2x.png';
import CardModalDesktop from '@assets/images/CardModalDesktop.png';
import CardModalMobile2x from '@assets/images/CardModalMobile2x.png';
import CardModalMobile from '@assets/images/CardModalMobile.png';
import { ReactComponent as CheckImage } from '@assets/images/CheckImage.svg';
import ComingSoonBackground from '@assets/images/ComingSoonBackground.png';
import DocumentDeleteSuccessLarge from '@assets/images/DocumentDeleteSuccessLarge1x.png';
import DocumentDeleteSuccessLarge2x from '@assets/images/DocumentDeleteSuccessLarge2x.png';
import DocumentDeleteSuccessSmall from '@assets/images/DocumentDeleteSuccessSmall1x.png';
import DocumentDeleteSuccessSmall2x from '@assets/images/DocumentDeleteSuccessSmall2x.png';
import ErrorBg2x from '@assets/images/ErrorBg-2x.png';
import ErrorBg from '@assets/images/ErrorBg.png';
import IconRectangleBlue from '@assets/images/IconBlueRectangle.svg';
import ImageCoin from '@assets/images/ImageCoin.svg';
import ImageCoinLarge from '@assets/images/ImageCoinLarge.svg';
import OnboardingSMSAuthMobile2x from '@assets/images/ImageOnboardingSMSAuthMobile2x.png';
import OnboardingSMSAuthMobile from '@assets/images/ImageOnboardingSMSAuthMobile.png';
import OnboardingSMSAuthTablet2x from '@assets/images/ImageOnboardingSMSAuthTablet2x.png';
import OnboardingSMSAuthTablet from '@assets/images/ImageOnboardingSMSAuthTablet.png';
import KYCBackground2x from '@assets/images/KYCBackground2x.png';
import KYCBackground from '@assets/images/KYCBackground.png';
import KYCBackgroundEmail2x from '@assets/images/KYCBackgroundEmail2x.png';
import KYCBackgroundEmail from '@assets/images/KYCBackgroundEmail.png';
import KYCBackgroundPhone2x from '@assets/images/KYCBackgroundPhone2x.png';
import KYCBackgroundPhone from '@assets/images/KYCBackgroundPhone.png';
import { ReactComponent as MobileCheckImage } from '@assets/images/MobileCheckImage.svg';
import { ReactComponent as MobileShadow } from '@assets/images/MobileShadow.svg';
import ImageMobileShape from '@assets/images/MobileShape.png';
import { ReactComponent as MobileShape } from '@assets/images/MobileShape.svg';
import NewsImage from '@assets/images/NewsImage.png';
import NoContactsDashboardMobile2x from '@assets/images/NoContactsDashboardMobile2x.png';
import NoContactsDashboardMobile from '@assets/images/NoContactsDashboardMobile.png';
import NoContactsDashboardTablet2x from '@assets/images/NoContactsDashboardTablet2x.png';
import NoContactsDashboardTablet from '@assets/images/NoContactsDashboardTablet.png';
import NoContactsSendMoneyMobile2x from '@assets/images/NoContactsSendMoneyMobile2x.png';
import NoContactsSendMoneyMobile from '@assets/images/NoContactsSendMoneyMobile.png';
import NoContactsSendMoneyTablet2x from '@assets/images/NoContactsSendMoneyTablet2x.png';
import NoContactsSendMoneyTablet from '@assets/images/NoContactsSendMoneyTablet.png';
import NoContactsSendMoneyVerifyMobile2x from '@assets/images/NoContactsSendMoneyVerifyMobile2x.png';
import NoContactsSendMoneyVerifyMobile from '@assets/images/NoContactsSendMoneyVerifyMobile.png';
import NoScheduledPaymentsMobile2x from '@assets/images/NoScheduledPaymentsMobile2x.png';
import NoScheduledPaymentsMobile from '@assets/images/NoScheduledPaymentsMobile.png';
import NoScheduledPaymentsTablet2x from '@assets/images/NoScheduledPaymentsTablet2x.png';
import NoScheduledPaymentsTablet from '@assets/images/NoScheduledPaymentsTablet.png';
import OTPModalBackground1x from '@assets/images/OTPModalBackground1x.png';
import OTPModalBackground2x from '@assets/images/OTPModalBackground2x.png';
import OnboardingImageLoginDesktop1x from '@assets/images/OnboardingImageLoginDesktop1x.png';
import OnboardingImageLoginDesktop2x from '@assets/images/OnboardingImageLoginDesktop2x.png';
import OnboardingImageLoginLaptop1x from '@assets/images/OnboardingImageLoginLaptop1x.png';
import OnboardingImageLoginLaptop2x from '@assets/images/OnboardingImageLoginLaptop2x.png';
import OnboardingImageRegisterDesktop1x from '@assets/images/OnboardingImageRegisterDesktop1x.png';
import OnboardingImageRegisterDesktop2x from '@assets/images/OnboardingImageRegisterDesktop2x.png';
import OnboardingImageRegisterLaptop1x from '@assets/images/OnboardingImageRegisterLaptop1x.png';
import OnboardingImageRegisterLaptop2x from '@assets/images/OnboardingImageRegisterLaptop2x.png';
import OnboardingImageRestoreDesktop1At1x from '@assets/images/OnboardingImageRestoreDesktop1@1x.png';
import OnboardingImageRestoreDesktop1At2x from '@assets/images/OnboardingImageRestoreDesktop1@2x.png';
import OnboardingImageRestoreDesktop2At1x from '@assets/images/OnboardingImageRestoreDesktop2@1x.png';
import OnboardingImageRestoreDesktop2At2x from '@assets/images/OnboardingImageRestoreDesktop2@2x.png';
import OnboardingImageRestoreDesktop3At1x from '@assets/images/OnboardingImageRestoreDesktop3@1x.png';
import OnboardingImageRestoreDesktop3At2x from '@assets/images/OnboardingImageRestoreDesktop3@2x.png';
import OnboardingImageRestoreLaptopMail1x from '@assets/images/OnboardingImageRestoreLaptopMail1x.png';
import OnboardingImageRestoreLaptopMail2x from '@assets/images/OnboardingImageRestoreLaptopMail2x.png';
import OnboardingImageRestoreLaptopOtp1x from '@assets/images/OnboardingImageRestoreLaptopOtp1x.png';
import OnboardingImageRestoreLaptopOtp2x from '@assets/images/OnboardingImageRestoreLaptopOtp2x.png';
import OnboardingImageRestoreLaptopPw1x from '@assets/images/OnboardingImageRestoreLaptopPw1x.png';
import OnboardingImageRestoreLaptopPw2x from '@assets/images/OnboardingImageRestoreLaptopPw2x.png';
import OnboardingImageSMSDesktop1x from '@assets/images/OnboardingImageSMSDesktop1x.png';
import OnboardingImageSMSDesktop2x from '@assets/images/OnboardingImageSMSDesktop2x.png';
import OnboardingImageSMSLaptop1x from '@assets/images/OnboardingImageSMSLaptop1x.png';
import OnboardingImageSMSLaptop2x from '@assets/images/OnboardingImageSMSLaptop2x.png';
import OnboardingTopLogin1x from '@assets/images/OnboardingTopLogin1x.png';
import OnboardingTopLogin2x from '@assets/images/OnboardingTopLogin2x.png';
import OnboardingTopRegister1x from '@assets/images/OnboardingTopRegister1x.png';
import OnboardingTopRegister2x from '@assets/images/OnboardingTopRegister2x.png';
import OnboardingTopRestore1x from '@assets/images/OnboardingTopRestore1x.png';
import OnboardingTopRestore2x from '@assets/images/OnboardingTopRestore2x.png';
import PaymentDeleteDesktop1x from '@assets/images/PaymentDeleteDesktop1x.png';
import PaymentDeleteDesktop2x from '@assets/images/PaymentDeleteDesktop2x.png';
import PaymentDeleteMobile1x from '@assets/images/PaymentDeleteMobile1x.png';
import PaymentDeleteMobile2x from '@assets/images/PaymentDeleteMobile2x.png';
import Rectangle from '@assets/images/Rectangle.svg';
import SaveChanges1x from '@assets/images/SaveChanges1x.png';
import SaveChanges2x from '@assets/images/SaveChanges2x.png';
import SaveChangesMobile1x from '@assets/images/SaveChangesMobile1x.png';
import SaveChangesMobile2x from '@assets/images/SaveChangesMobile2x.png';
import SettingsInfoBlurredBg from '@assets/images/SettingsInfoBlurredBg.png';
import SettingsKYCBackground2x from '@assets/images/SettingsKYCBackground2x.png';
import SettingsKYCBackground from '@assets/images/SettingsKYCBackground.png';
import SettingsKYCBackgroundMobile2x from '@assets/images/SettingsKYCBackgroundMobile2x.png';
import SettingsKYCBackgroundMobile from '@assets/images/SettingsKYCBackgroundMobile.png';
import SettingsKYCBackgroundTablet2x from '@assets/images/SettingsKYCBackgroundTablet2x.png';
import SettingsKYCBackgroundTablet from '@assets/images/SettingsKYCBackgroundTablet.png';
import { ReactComponent as Shadow } from '@assets/images/Shadow.svg';
import ImageShape from '@assets/images/Shape.png';
import { ReactComponent as Shape } from '@assets/images/Shape.svg';
import { ReactComponent as SortAscending } from '@assets/images/SortAscending.svg';
import { ReactComponent as SortDefault } from '@assets/images/SortDefault.svg';
import { ReactComponent as SortDescending } from '@assets/images/SortDescending.svg';
import SuccessBg2x from '@assets/images/SuccessBg-2x.png';
import SuccessBg from '@assets/images/SuccessBg.png';
import Triangle from '@assets/images/Triangle.svg';
import { ReactComponent as VerificationPromotionArrow } from '@assets/images/VerificationPromotionArrow.svg';
import VerifyBox1x from '@assets/images/VerifyBox1x.png';
import VerifyBox2x from '@assets/images/VerifyBox2x.png';

const VerifyBGs = [VerifyBox1x, VerifyBox2x];
const ErrorBGs = [ErrorBg, ErrorBg2x];
const ErrorBGsMobile = [BgMobileError, BgMobileError2x];
const SuccessBGs = [SuccessBg, SuccessBg2x];
const SuccessBGsMobile = [BgMobileSuccess, BgMobileSuccess2x];

const InviteSuccessBGsMobile = [BackgroundInviteUserSuccess, BackgroundInviteUserSuccess2x];

const OnboardingSMSAuthMobileSet = [OnboardingSMSAuthMobile, OnboardingSMSAuthMobile2x];
const OnboardingSMSAuthTabletSet = [OnboardingSMSAuthTablet, OnboardingSMSAuthTablet2x];

const DocumentDeleteSuccessLargeSet = [DocumentDeleteSuccessLarge, DocumentDeleteSuccessLarge2x];
const DocumentDeleteSuccessSmallSet = [DocumentDeleteSuccessSmall, DocumentDeleteSuccessSmall2x];
const PaymentDeleteDesktop = [PaymentDeleteDesktop1x, PaymentDeleteDesktop2x];
const PaymentDeleteMobile = [PaymentDeleteMobile1x, PaymentDeleteMobile2x];
const SaveChangesDesktop = [SaveChanges1x, SaveChanges2x];
const SaveChangesMobile = [SaveChangesMobile1x, SaveChangesMobile2x];

const CardBefores = [CardBefore, CardBefore2x];

const CardModalsMobile = [CardModalMobile, CardModalMobile2x];
const CardModalsDesktop = [CardModalDesktop, CardModalDesktop2x];

const AnalyticsCenters = [AnalyticsCenter, AnalyticsCenter2x];
const AnalyticsCentersMin = [AnalyticsCenterMin, AnalyticsCenterMin2x];

const ImageCheck = CheckImage;
const MobileImageCheck = MobileCheckImage;
const ImageBlueCheck = BlueCheck;

const NoContactsDashboardTabletSet = [NoContactsDashboardTablet, NoContactsDashboardTablet2x];
const NoContactsDashboardMobileSet = [NoContactsDashboardMobile, NoContactsDashboardMobile2x];
const NoContactsSendMoneyTabletSet = [NoContactsSendMoneyTablet, NoContactsSendMoneyTablet2x];
const NoContactsSendMoneyMobileSet = [NoContactsSendMoneyMobile, NoContactsSendMoneyMobile2x];
const NoContactsSendMoneyVerifyMobileSet = [
  NoContactsSendMoneyVerifyMobile,
  NoContactsSendMoneyVerifyMobile2x,
];
const NoScheduledPaymentsMobileSet = [NoScheduledPaymentsMobile, NoScheduledPaymentsMobile2x];
const NoScheduledPaymentsTabletSet = [NoScheduledPaymentsTablet, NoScheduledPaymentsTablet2x];

const OnboardingTopRegister = [OnboardingTopRegister1x, OnboardingTopRegister2x];
const OnboardingTopLogin = [OnboardingTopLogin1x, OnboardingTopLogin2x];
const OnboardingTopRestore = [OnboardingTopRestore1x, OnboardingTopRestore2x];
const OnboardingImageRegisterLaptop = [
  OnboardingImageRegisterLaptop1x,
  OnboardingImageRegisterLaptop2x,
];
const OnboardingImageRegisterDesktop = [
  OnboardingImageRegisterDesktop1x,
  OnboardingImageRegisterDesktop2x,
];
const OnboardingImageLoginLaptop = [OnboardingImageLoginLaptop1x, OnboardingImageLoginLaptop2x];
const OnboardingImageLoginDesktop = [OnboardingImageLoginDesktop1x, OnboardingImageLoginDesktop2x];
const OnboardingImageSMSLaptop = [OnboardingImageSMSLaptop1x, OnboardingImageSMSLaptop2x];
const OnboardingImageSMSDesktop = [OnboardingImageSMSDesktop1x, OnboardingImageSMSDesktop2x];
const OnboardingImageRestoreLaptopPw = [
  OnboardingImageRestoreLaptopPw1x,
  OnboardingImageRestoreLaptopPw2x,
];
const OnboardingImageRestoreLaptopOtp = [
  OnboardingImageRestoreLaptopOtp1x,
  OnboardingImageRestoreLaptopOtp2x,
];
const OnboardingImageRestoreLaptopMail = [
  OnboardingImageRestoreLaptopMail1x,
  OnboardingImageRestoreLaptopMail2x,
];
const OnboardingImageRestoreDesktop1 = [
  OnboardingImageRestoreDesktop1At1x,
  OnboardingImageRestoreDesktop1At2x,
];
const OnboardingImageRestoreDesktop2 = [
  OnboardingImageRestoreDesktop2At1x,
  OnboardingImageRestoreDesktop2At2x,
];
const OnboardingImageRestoreDesktop3 = [
  OnboardingImageRestoreDesktop3At1x,
  OnboardingImageRestoreDesktop3At2x,
];

const SettingsKYCBackgroundSet = [SettingsKYCBackground, SettingsKYCBackground2x];
const SettingsKYCBackgroundMobileSet = [SettingsKYCBackgroundMobile, SettingsKYCBackgroundMobile2x];
const SettingsKYCBackgroundTabletSet = [SettingsKYCBackgroundTablet, SettingsKYCBackgroundTablet2x];

const KYCBackgroundSet = [KYCBackground, KYCBackground2x];
const KYCBackgroundPhoneSet = [KYCBackgroundPhone, KYCBackgroundPhone2x];
const KYCBackgroundEmailSet = [KYCBackgroundEmail, KYCBackgroundEmail2x];
const OTPModalBackground = [OTPModalBackground1x, OTPModalBackground2x];

export {
  VerificationPromotionArrow,
  CardGradient,
  Shape,
  Shadow,
  MobileShape,
  MobileShadow,
  KYCBackgroundSet,
  KYCBackgroundPhoneSet,
  KYCBackgroundEmailSet,
  ErrorBGs,
  SuccessBGs,
  ErrorBGsMobile,
  SuccessBGsMobile,
  Rectangle,
  Triangle,
  NoContactsDashboardTabletSet,
  NoContactsDashboardMobileSet,
  NoContactsSendMoneyTabletSet,
  NoContactsSendMoneyVerifyMobileSet,
  NoContactsSendMoneyMobileSet,
  NoScheduledPaymentsMobileSet,
  NoScheduledPaymentsTabletSet,
  OnboardingTopRegister,
  OnboardingTopLogin,
  OnboardingTopRestore,
  SortDefault,
  SortAscending,
  SortDescending,
  ImageCheck,
  MobileImageCheck,
  NewsImage,
  BlueCheck,
  ImageCoin,
  ImageCoinLarge,
  ImageShape,
  ImageMobileShape,
  CardBefores,
  CardModalsMobile,
  ImageBlueCheck,
  CardModalsDesktop,
  AnalyticsCenterSmall,
  AnalyticsCenters,
  AnalyticsCentersMin,
  DocumentDeleteSuccessLargeSet,
  DocumentDeleteSuccessSmallSet,
  PaymentDeleteDesktop,
  PaymentDeleteMobile,
  SettingsInfoBlurredBg,
  SettingsKYCBackgroundSet,
  SettingsKYCBackgroundMobileSet,
  SettingsKYCBackgroundTabletSet,
  IconRectangleBlue,
  InviteSuccessBGsMobile,
  NotFound,
  VerifyBGs,
  SaveChangesDesktop,
  SaveChangesMobile,
  OnboardingImageRegisterLaptop,
  OnboardingImageRegisterDesktop,
  OnboardingImageLoginLaptop,
  OnboardingImageLoginDesktop,
  OnboardingImageSMSLaptop,
  OnboardingImageSMSDesktop,
  OnboardingImageRestoreLaptopPw,
  OnboardingImageRestoreLaptopOtp,
  OnboardingImageRestoreLaptopMail,
  OnboardingImageRestoreDesktop1,
  OnboardingImageRestoreDesktop2,
  OnboardingImageRestoreDesktop3,
  OnboardingSMSAuthMobileSet,
  OnboardingSMSAuthTabletSet,
  ComingSoonBackground,
  OTPModalBackground,
};
