import { friendlyFormatIBAN } from 'ibantools';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import {
  useGetAllManagedAccounts,
  useGetManagedAccountIban,
} from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';
import { Commons } from '@shared/functions';

import { IconArrowDown } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import BankDetails from '@components/BankDetails/BankDetails';
import CardBefore from '@components/CardBefore/CardBefore';
import MobileTimer from '@components/MobileTimer/MobileTimer';
import OverviewBox from '@components/OverviewBox/OverviewBox';
import ShareDetails from '@components/ShareDetails/ShareDetails';
import VirtualCard from '@components/VirtualCard/VirtualCard';
import VirtualCardCompact from '@components/VirtualCard/VirtualCardCompact';
import AccountDetailsKYC from '@components/account/AccountDetailsKYC/AccountDetailsKYC';

interface Props {
  collapsed: boolean;
  setCollapse: (value: boolean) => void;
}

const AccountDetails = ({ collapsed, setCollapse }: Props) => {
  const openModal = useBoundStore((state) => state.openModal);
  const timer = useBoundStore((state) => state.timer);
  const timeLeft = useBoundStore((state) => state.timeLeft);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const cardCreated = useBoundStore((state) => state.cardCreated);
  const setCardSetupVisible = useBoundStore((state) => state.setCardSetupVisible);
  const cardOrdered = useBoundStore((state) => state.cardOrdered);
  const fullAccessUser = useBoundStore((state) => state.fullAccessUser);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const {
    data: managedAccountData,
    isError: isGetAllManagedAccountsError,
    error: getAllManagedAccountsError,
  } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const {
    data: mainManagedAccountData,
    isError: isGetManagedAccountIbanError,
    error: getManagedAccountIbanError,
  } = useGetManagedAccountIban(managedAccountId);

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetAllManagedAccountsError && getAllManagedAccountsError) {
      errorsToHandle.push(getAllManagedAccountsError);
    }

    if (isGetManagedAccountIbanError && getManagedAccountIbanError) {
      errorsToHandle.push(getManagedAccountIbanError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [
    getAllManagedAccountsError,
    getManagedAccountIbanError,
    handleHTTPErrors,
    isGetAllManagedAccountsError,
    isGetManagedAccountIbanError,
  ]);

  const getIBAN = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      const IBAN = mainManagedAccountData.bankAccountDetails[1].details.iban;
      return IBAN ? (friendlyFormatIBAN(IBAN) as string) : '--';
    } else {
      return '--';
    }
  };

  const getBIC = () => {
    if (mainManagedAccountData && mainManagedAccountData.bankAccountDetails) {
      const BIC = mainManagedAccountData.bankAccountDetails[1].details.bankIdentifierCode;
      return BIC as string;
    } else {
      return '--';
    }
  };

  const getTotalBalance = () => {
    if (managedAccountData && managedAccountData.accounts) {
      const balance = managedAccountData.accounts[0].balances.availableBalance;
      return Commons.formatWeavrAmountToPeanuds(balance);
    } else {
      return 0;
    }
  };

  return (
    <Container $collapsed={collapsed}>
      {verifiedKYC ? (
        <Content $collapsed={collapsed}>
          <OverviewBox
            collapsed={collapsed}
            totalBalance={getTotalBalance()}
          />
          <Wrapper>
            <BankDetails
              IBAN={getIBAN()}
              BIC={getBIC()}
              onClick={() => {
                openModal(<ShareDetails />);
              }}
              collapsed={collapsed}
            />
            {!collapsed ? (
              cardCreated ? (
                <VirtualCard
                  cardNumber="2710 2710 2710 2710"
                  cvv={223}
                  companyName="The Company GmbH"
                  status="Active"
                  validThrough="09/23"
                />
              ) : (
                <CardBefore
                  ordered={cardOrdered}
                  setVisible={setCardSetupVisible}
                />
              )
            ) : (
              <VirtualCardCompact
                cardNumber="2710 2710 2710 2710"
                cvv={223}
                companyName="The Company GmbH"
                status="Inactive"
                validThrough="09/23"
              />
            )}
          </Wrapper>
        </Content>
      ) : (
        <AccountDetailsKYC company={fullAccessUser} />
      )}
      {timer && timeLeft >= 0 && (
        <TimerWrapper>
          <MobileTimer />
        </TimerWrapper>
      )}
      <ExpandButton
        $collapsed={collapsed}
        onClick={() => setCollapse(false)}
      >
        <IconArrowDown />
      </ExpandButton>
    </Container>
  );
};

const Container = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  ${HideScrollbar};
  scrollbar-width: none;
  padding: 0 20px;
  transition: 300ms ease-in-out;

  ${(props) =>
    !props.$collapsed &&
    css`
      overflow: auto hidden;
      min-height: 270px;
      width: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
    `};

  ${(props) =>
    props.$collapsed &&
    css`
      height: 80px;
      position: relative;
    `};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 24px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 0 40px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    padding: 0 0 0 40px;
  }
`;

const TimerWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  box-sizing: border-box;
  justify-content: end;
`;

const Content = styled.div<{ $collapsed: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex: 1 0;
  background: ${(props) => props.theme.palette.gradients.blueOrangeTint};
  gap: 40px;
  border-radius: 20px;
  padding: ${(props) => (props.$collapsed ? 20 : 35)}px;
  box-sizing: border-box;
`;

const ExpandButton = styled.button<{ $collapsed: boolean }>`
  display: ${(props) => (props.$collapsed ? 'flex' : 'none')};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  transition: 300ms ease-in-out;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -15px;
  cursor: pointer;
  left: 50%;
  right: 50%;
  background-color: #dfdfe7;
  z-index: 1;
  width: 50px;
  height: 25px;
  border-radius: 0 0 40px 40px;

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export default AccountDetails;
