import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useGetUserSessionLogs } from '@api/Access/accessApi';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar, Scrollbar } from '@shared/css';
import { formatDateObject } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

const SettingsLog = () => {
  const externalId = useBoundStore((state) => state.externalId);

  const { data: sessionLogs } = useGetUserSessionLogs(externalId);

  const [data, setData] = useState<SessionLog[]>([]);

  const tableHeaders = ['Location', 'Browser', 'Date', 'Time', 'IP'];

  useEffect(() => {
    if (sessionLogs && sessionLogs.length > 0) {
      setData(sessionLogs);
    }
  }, [sessionLogs]);

  return (
    <Container>
      <Headings>
        {tableHeaders.map((heading, index) => {
          return (
            <div
              key={index}
              className={heading}
            >
              {heading}
            </div>
          );
        })}
      </Headings>
      <Content>
        {data.map((log, index) => {
          return (
            <Row key={index}>
              <div className="Location">{log.location}</div>
              <div>{log.browser}</div>
              <div>{formatDateObject(new Date(log.dateAndTime as string))}</div>
              <div className="Date">
                {new Date(log.dateAndTime as string).toLocaleTimeString('en-EN')}
              </div>
              <div className="IP">{log.ip}</div>
            </Row>
          );
        })}
      </Content>
    </Container>
  );
};

export default SettingsLog;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  & > :last-child {
    margin-top: auto;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-width: calc(100vw - 660px);
  }
`;

const Headings = styled.div`
  display: flex;
  box-sizing: border-box;
  ${FontStyles.bodyMiddleGilroy};
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 15px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  text-align: start;

  & > div {
    min-width: fit-content;
    width: 100%;
  }

  & > .IP {
    text-align: end;
  }
`;

const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  ${FontStyles.bodyMiddleGilroy};
  flex-direction: column;
  padding-right: 15px;
  gap: 15px;
  overflow: auto;
  ${HideScrollbar};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${Scrollbar};
    min-height: 572px;
    max-height: 700px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  gap: 20px;
  text-align: start;

  & > .Location {
    ${FontStyles.buttonGilroyType3};
    color: ${(props) => props.theme.palette.primary.black};
  }

  & > div {
    min-width: fit-content;
    width: 100%;
    color: ${(props) => props.theme.palette.greys.darkestGrey};
  }

  & > .Date {
    color: ${(props) => props.theme.palette.greys.asphaltTint};
  }

  & > .IP {
    color: ${(props) => props.theme.palette.greys.asphaltTint};
    text-align: end;
  }
`;
