import { StateCreator } from 'zustand';

export interface TransactionSlice {
  amount: string;
  setAmount: (value: string) => void;
  defaultTransactionRecipient: ContactsWithBankDetails;
  transactionRecipient: ContactsWithBankDetails;
  setTransactionRecipient: (
    value: ContactsWithBankDetails | ((value: ContactsWithBankDetails) => ContactsWithBankDetails)
  ) => void;
  iban: string;
  setIban: (value: string) => void;
  bic: string;
  setBic: (value: string) => void;
  accountNumber: string;
  setAccountNumber: (value: string) => void;
  routingNumber: string;
  setRoutingNumber: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  dateOfTransactionExecution: string;
  setDateOfTransactionExecution: (value: string) => void;
  defaultTransactionFrequency: TransactionFrequency;
  transactionFrequency: TransactionFrequency;
  setTransactionFrequency: (transactionFrequency: TransactionFrequency) => void;
  tag: string;
  setTag: (value: string) => void;
  scheduledFormMode: boolean;
  setScheduledFormMode: (value: boolean) => void;
  isTransactionToday: boolean;
  setIsTransactionToday: (value: boolean) => void;
  selectedTransaction: TransactionWithContact | null;
  setSelectedTransaction: (
    value:
      | (TransactionWithContact | null)
      | ((transaction: TransactionWithContact | null) => TransactionWithContact | null)
  ) => void;
  repeat: boolean;
  setRepeat: (value: boolean) => void;
}

const defaultTransactionFrequency: TransactionFrequency = {
  type: 'Never',
  format: '',
};

const defaultTransactionRecipient: ContactsWithBankDetails = {
  id: '',
  name: '',
  tag: '',
  profileId: '',
  userId: '',
  address: '',
  postcode: '',
  city: '',
  country: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  bankDetails: [
    {
      iban: '',
      bic: '',
    },
  ],
};

export const createTransactionSlice: StateCreator<TransactionSlice> = (set) => ({
  amount: '0.00',
  setAmount: (value) => {
    set({ amount: value });
  },
  defaultTransactionRecipient: defaultTransactionRecipient,
  transactionRecipient: defaultTransactionRecipient,
  setTransactionRecipient: (value) =>
    set((state) => ({
      transactionRecipient: typeof value === 'function' ? value(state.transactionRecipient) : value,
    })),
  iban: '',
  setIban: (value) => set({ iban: value }),
  bic: '',
  setBic: (value) => set({ bic: value }),
  accountNumber: '',
  setAccountNumber: (value) => set({ accountNumber: value }),
  routingNumber: '',
  setRoutingNumber: (value) => set({ routingNumber: value }),
  description: '',
  setDescription: (value) => set({ description: value }),
  dateOfTransactionExecution: new Date().toLocaleDateString('de-DE'), // TODO: Rename
  setDateOfTransactionExecution: (date) => set({ dateOfTransactionExecution: date }), // TODO: Rename
  defaultTransactionFrequency: defaultTransactionFrequency,
  transactionFrequency: defaultTransactionFrequency,
  setTransactionFrequency: (value) => set({ transactionFrequency: value }),
  tag: '',
  setTag: (value) => set({ tag: value }),
  scheduledFormMode: false, // TODO: Rename
  setScheduledFormMode: (value) => set({ scheduledFormMode: value }), // TODO: Rename
  isTransactionToday: false,
  setIsTransactionToday: (value) => set({ isTransactionToday: value }),
  selectedTransaction: null,
  setSelectedTransaction: (value) =>
    set((state) => ({
      selectedTransaction: typeof value === 'function' ? value(state.selectedTransaction) : value,
    })),
  repeat: false,
  setRepeat: (value) => set({ repeat: value }),
});
