import React, { useEffect, useState } from 'react';

import {
  useAnalyticsChart,
  useMostCommonHashtag,
  useTransactions,
} from '@api/Analytics/analyticsApi';
import { useGetKYB } from '@api/Corporate/corporateApi';
import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import StatisticsWrapper from '@components/StatisticsWrapper/StatisticsWrapper';
import AnalyticsCenter from '@components/analytics/AnalyticsCenter/AnalyticsCenter';
import AnalyticsTransactionsWrapper from '@components/analytics/AnalyticsTransactionsWrapper/AnalyticsTransactionsWrapper';

import { Container, Top } from './Analytics.styles';

const Analytics = () => {
  const searchQuery = useBoundStore((state) => state.searchQuery);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [transactionData, setTransactionData] = useState<DbTransaction[] | null>(null);

  const [availableEntries, setAvailableEntries] = useState<number>(0);

  const timespan = useBoundStore((state) => state.timespan);

  const orderBy = useBoundStore((state) => state.orderBy);
  const orderByField = useBoundStore((state) => state.orderByField);
  const sortingDirection = useBoundStore((state) => state.sortingDirection);

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const setScrolled = useBoundStore((state) => state.setScrolled);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const offset = useBoundStore((state) => state.offset);
  const visibleRows = useBoundStore((state) => state.visibleRows);
  const filterType = useBoundStore((state) => state.filterType);
  const filterStatus = useBoundStore((state) => state.filterStatus);

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';
  const {
    data: kybData,
    isError: isGetKYBError,
    error: kybError,
    isLoading: KYBLoading,
  } = useGetKYB();

  const { data: chartData } = useAnalyticsChart(managedAccountId);
  const { data: hashtagData, isSuccess: isHashtagSuccess } = useMostCommonHashtag(
    timespan.start,
    managedAccountId
  );

  console.log(managedAccountData?.accounts?.[0].id || '', 'managedAccountData');
  const { data: fetchedTransactionData, isLoading } = useTransactions({
    start: timespan.start,
    end: timespan.end,
    searchQuery: searchQuery ?? '',
    orderBy: orderByField,
    sortingDirection: sortingDirection,
    offset: offset.toString(),
    limit: visibleRows.toString(),
    filterType: filterType,
    filterStatus: filterStatus,
    managedAccountId: managedAccountId,
  });
  console.log(verifiedKYC, 'verifiedKYC');
  useEffect(() => {
    if (verifiedKYC) {
      const getAnalyticsTransactions = async () => {
        const res = fetchedTransactionData;
        if (res) {
          setTransactionData(res.transactions);
          setAvailableEntries(res.availableEntries);
        }
      };
      getAnalyticsTransactions().catch((error) => console.error(error));
    } else {
      if (!verifiedKYC && verifiedKYC !== null) {
        setTransactionData([
          {
            id: '0',
            name: 'Peanuds',
            amount: 0,
            currency: 'EUR',
            dateAndTime: new Date().toISOString(),
            tag: 'VerifyNow',
            userId: '0',
            status: 'Pending',
            externalId: '123456',
            scheduledAt: new Date(),
            accountNumber: '',
            routingNumber: '',
            fee: 0,
            iban: '',
            bicSwift: '',
            direction: 'in',
            bankName: '',
            bankAddress: '',
            vendor: '',
            additionalBeneficiaryDetails: '',
            reason: '',
            reference: '',
            type: '',
          },
        ]);
      }

      setAvailableEntries(1);
    }
  }, [
    currentPage,
    filterStatus,
    filterType,
    offset,
    orderBy.amount,
    orderBy.dateAndTime,
    visibleRows,
    timespan.start,
    timespan.end,
    verifiedKYC,
    searchQuery,
    fetchedTransactionData,
  ]);

  useEffect(() => {
    setSelectedContact(null);
  }, [setSelectedContact]);

  useEffect(() => {
    setScrolled(false);
    const handleScroll = () => {
      setScrolled(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [setScrolled]);

  useEffect(() => {
    setCurrentPage(1);
  }, [timespan.start]);

  return (
    <Container>
      <Top>
        <AnalyticsCenter />
        <StatisticsWrapper
          hashtagStats={isHashtagSuccess ? hashtagData : { tag: '', sum: 0 }}
          analyticsChartData={chartData}
        />
      </Top>
      <AnalyticsTransactionsWrapper
        setCurrentPage={setCurrentPage}
        availableEntries={availableEntries}
        data={transactionData}
        currentPage={currentPage}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default Analytics;
