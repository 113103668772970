import React, { ReactNode, forwardRef, useRef } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconNavArrowRight } from '@constants/icons';

interface Props {
  startDate: Date;
  endDate: Date;
  minDate?: Date;
  currentIndex: number;
  onDateChange: (value: Date | Date[], event: React.MouseEvent<HTMLButtonElement>) => void;
}
type CalendarBoxProps = {
  children: ReactNode;
  className?: string;
};

const CalendarBox = forwardRef<HTMLDivElement, CalendarBoxProps>((props, ref) => (
  <div
    ref={ref}
    className={props.className}
  >
    {props.children}
  </div>
));

CalendarBox.displayName = 'CalendarBox';

const Datepicker = ({ startDate, endDate, currentIndex, onDateChange, minDate }: Props) => {
  const calendarRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledCalendarBox
      ref={calendarRef}
      className="yourClassName"
    >
      <Calendar
        defaultView="month"
        minDetail="month"
        view="month"
        calendarType="US"
        locale="en-US"
        prev2Label={null}
        next2Label={null}
        minDate={minDate}
        prevLabel={
          <NavigationArrow>
            <StyledIconArrow className="invert" />
          </NavigationArrow>
        }
        nextLabel={
          <NavigationArrow>
            <StyledIconArrow />
          </NavigationArrow>
        }
        selectRange={currentIndex > 0}
        onChange={(value, event) => onDateChange(value as Date | Date[], event)}
        value={endDate ? [startDate, endDate] : startDate}
      />
    </StyledCalendarBox>
  );
};

export default Datepicker;

const StyledCalendarBox = styled(CalendarBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 296px;
  max-height: 327px;
  text-align: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: unset;
    max-height: unset;
  }

  .react-calendar {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 2px 7px 15px rgba(0, 0, 0, 0.1);

    @media (${(props) => props.theme.breakpoints.tablet}) {
      box-shadow: 2px 7px 77px rgba(0, 0, 0, 0.1);
    }
  }

  .react-calendar__tile:disabled {
    abbr {
      color: ${(props) => props.theme.palette.greys.darkGrey};
    }
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    .react-calendar__navigation__label {
      color: ${(props) => props.theme.palette.primary.black};

      &:hover {
        background-color: transparent;
      }
      ${FontStyles.h4};
    }

    .react-calendar__navigation__arrow {
      cursor: pointer;
    }
  }

  abbr {
    text-decoration: none;
  }

  .react-calendar__month-view {
    .react-calendar__month-view__weekdays {
      text-decoration: none !important;
      ${FontStyles.h6};
      color: ${(props) => props.theme.palette.primary.black};
      padding-bottom: 20px;
    }

    .react-calendar__month-view__days {
      display: grid !important;
      justify-content: center;
      gap: 10px;
      align-items: center;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(5, 1fr);

      .react-calendar__tile--range {
        background: white;
        color: black;
        box-sizing: border-box;
        border: 1px solid ${(props) => props.theme.palette.primary.blue};
        &:hover {
          border: 1px solid ${(props) => props.theme.palette.greys.asphaltTint};
        }
      }

      .react-calendar__tile--rangeEnd,
      .react-calendar__tile--rangeStart,
      .react-calendar__tile--hasActive {
        background: center ${(props) => props.theme.palette.gradients.primaryBlueGradient};
        color: white;
        box-sizing: border-box;
        border: none;
        &:hover {
          background: ${(props) => props.theme.palette.primary.black};
          border: none;
        }
      }
    }
    .react-calendar__tile {
      ${FontStyles.body2};
    }
    .react-calendar__tile:focus {
      border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
      background-color: white;
    }

    button {
      cursor: pointer;
      display: flex;
      box-sizing: border-box;
      min-height: 32px;
      min-width: 34.29px;
      border-radius: 8px;
      border: 1px solid transparent;
      justify-content: center;
      align-items: center;
      background-color: white;

      &:hover {
        border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
        background-color: white;
      }
    }
  }
`;

const NavigationArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
  &:hover {
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    border: 1px solid ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

const StyledIconArrow = styled(IconNavArrowRight)`
  &.invert {
    rotate: 180deg;
  }
`;
