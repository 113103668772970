import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { Triangle } from '@constants/images';

import useMediaQuery from '@hooks/useMediaQuery';

import Avatar from '@components/Avatar/Avatar';

import { DUMMY_CONTACTS } from '@mocks/Contacts';

interface Props {
  transactionData: TransactionWithContact;
  iconVisible?: boolean;
}

const SendMoneyScheduledPayment = ({ transactionData, iconVisible }: Props) => {
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  const setScheduledFormMode = useBoundStore((state) => state.setScheduledFormMode);
  const openModal = useBoundStore((state) => state.openModal);
  const selectedTransaction = useBoundStore((state) => state.selectedTransaction);
  const setSelectedTransaction = useBoundStore((state) => state.setSelectedTransaction);

  const [isDateClose, setIsDateClose] = useState<boolean | null>(false);

  const { id, name, tag, amount, scheduledAt, currency } = transactionData.transactions;

  const handleClick = () => {
    setScheduledFormMode(true);
    if (isLaptop) {
      setSelectedTransaction((previousState: TransactionWithContact | null) => {
        if (previousState?.transactions.id === id) return null;
        return transactionData;
      });
    }
    if (!isLaptop) {
      setSelectedTransaction(transactionData);
      navigate('/send-money/transaction-form');
    }
  };

  const formatDate = (date: Date) => {
    let day: string | number = new Date(date).getDate();
    let month: string | number = new Date(date).getMonth() + 1;
    const year: string | number = new Date(date).getFullYear().toString();

    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;

    return `${day}/${month}/${year}`;
  };

  const getDifferenceInDays = (date1: Date, date2: Date) => {
    const diffInTime = date1.getTime() - date2.getTime();
    return diffInTime / (1000 * 3600 * 24);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });

  const formatBalance = (input: number) => {
    return formatter.format(input);
  };

  useEffect(() => {
    if (transactionData.transactions.scheduledAt) {
      const today = new Date();
      const nextPaymentDate = new Date(transactionData.transactions.scheduledAt);
      const difference = getDifferenceInDays(nextPaymentDate, today);

      setIsDateClose(difference <= 10);
    }
  }, [transactionData.transactions.scheduledAt]);

  return (
    <Container
      $selected={isLaptop && selectedTransaction?.transactions.id === id}
      onClick={handleClick}
    >
      <TopWrapper>
        <Avatar
          src={DUMMY_CONTACTS[4].avatar}
          alt={`Avatar ${name}`}
          text={name}
        />
        <List>
          <Name $selected={isLaptop && selectedTransaction?.transactions.id === id}>{name}</Name>
          {/*<Tag $selected={isLaptop && selectedTransaction?.transactions.id === id}>*/}
          {/*  {!tag || tag === '' ? 'No hashtag assigned' : tag}*/}
          {/*</Tag>*/}
        </List>
        {/*!iconVisible && (
          <Button
            type="button"
            onClick={(event) => {
              openModal(<DocumentHistory />);
              event.stopPropagation();
            }}
            $selected={isLaptop && selectedTransaction?.transactions.id === id}
          >
            <IconDocClock />
          </Button>
        )*/}
        <ResponsiveValue
          $selected={isLaptop && selectedTransaction?.transactions.id === id}
        >{`${formatBalance(amount)} ${currency}`}</ResponsiveValue>
      </TopWrapper>
      <DividerLine />
      <BottomWrapperSmall>
        <PaymentDate
          $selected={isLaptop && selectedTransaction?.transactions.id === id}
          $dateClose={isLaptop && isDateClose}
        >
          Next Payment:
        </PaymentDate>
        <PaymentDate
          $selected={isLaptop && selectedTransaction?.transactions.id === id}
          $dateClose={isDateClose}
        >
          {!scheduledAt ? 'Paused' : formatDate(scheduledAt)}
        </PaymentDate>
      </BottomWrapperSmall>
      <BottomWrapperLarge>
        <PaymentWrapper>
          <PaymentDate
            $selected={isLaptop && selectedTransaction?.transactions.id === id}
            $dateClose={isDateClose}
          >
            Next Payment:
          </PaymentDate>
          <PaymentDate
            $selected={isLaptop && selectedTransaction?.transactions.id === id}
            $dateClose={isDateClose}
          >
            {!scheduledAt ? 'Paused' : formatDate(scheduledAt)}
          </PaymentDate>
        </PaymentWrapper>
        <Value
          $selected={isLaptop && selectedTransaction?.transactions.id === id}
        >{`${formatBalance(amount)} ${currency}`}</Value>
      </BottomWrapperLarge>
    </Container>
  );
};

export default SendMoneyScheduledPayment;

const Container = styled.div<{ $selected: boolean }>`
  transition: background-color 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${(props) =>
    props.$selected
      ? 'linear-gradient(255.37deg, #BFC8DB 10.35%, #7A8599 89.65%)'
      : props.theme.palette.primary.white};
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  width: calc(100% - 13.5px);

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: calc(100% - 20px);
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${(props) =>
      props.$selected &&
      css`
        &::after {
          content: '';
          position: absolute;
          right: -9.5px;
          top: 50%;
          width: 11px;
          height: 22px;
          background: url(${Triangle});
          transform: translateY(-50%);
        }
      `}
    border: ${(props) =>
      props.$selected
        ? '1px solid transparent'
        : `1px solid ${props.theme.palette.greys.lightLightGrey}`};
    ${(props) =>
      props.$selected &&
      css`
        border-left: 1px solid rgb(124, 132, 151);
        border-right: 1px solid rgb(192, 199, 217);
      `};
    cursor: pointer;
    height: 64px;
    width: 289px;

    &:hover {
      background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
    }
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    width: 442px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

const Name = styled.p<{ $selected: boolean }>`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) =>
    props.$selected ? props.theme.palette.primary.white : props.theme.palette.primary.black};
  width: 148px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 148px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: initial;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    max-width: initial;
  }
`;

const Tag = styled.p<{ $selected: boolean }>`
  ${FontStyles.bodySmallGilroy};
  margin-top: 2px;
  color: ${(props) =>
    props.$selected ? props.theme.palette.primary.white : props.theme.palette.greys.darkestGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 148px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    max-width: initial;
  }
`;

const List = styled.div`
  height: 40px;
  display: flex;
  //flex-direction: column;
  margin-left: 10px;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    //align-items: start;
  }
`;

const Button = styled.button<{ $selected: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 300ms ease-in-out;
  color: ${(props) =>
    props.$selected ? props.theme.palette.primary.white : props.theme.palette.greys.darkGrey};

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-left: 10px;
  }
`;

const Value = styled.div<{ $selected: boolean }>`
  ${FontStyles.h5};
  transition: 300ms ease-in-out;
  height: 40px;
  min-width: 144px;
  border-radius: 54px;
  background: ${(props) =>
    props.$selected ? props.theme.palette.primary.white : props.theme.palette.greys.lightLightGrey};
  color: ${(props) =>
    props.$selected ? props.theme.palette.greys.darkGrey : props.theme.palette.greys.darkestGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const ResponsiveValue = styled(Value)`
  display: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    display: flex;
  }
`;

const DividerLine = styled.div`
  width: 100%;
  min-height: 1px;
  margin: 10px 0;
  background: ${(props) => props.theme.palette.greys.middleGrey};
`;

const BottomWrapperLarge = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

const BottomWrapperSmall = styled(BottomWrapperLarge)`
  display: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    display: flex;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

const PaymentDate = styled.p<{ $selected: boolean; $dateClose: boolean | null }>`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) =>
    props.$selected
      ? props.theme.palette.primary.white
      : props.$dateClose
      ? 'red'
      : props.theme.palette.greys.darkGrey};
`;
