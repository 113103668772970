import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  src?: string;
  srcSet?: string;
  alt?: string;
  text?: string;
  className?: string;
  onClick?: () => void;
  dark?: boolean;
  size?: Size;
}

const Avatar = ({ src, srcSet, alt, text, className, onClick, dark = false, size }: Props) => {
  const firstLetter = capitalizeFirstLetter(text);
  return src ? (
    <Image
      title={alt}
      src={src}
      srcSet={srcSet}
      className={className}
      onClick={onClick}
      size={size}
    />
  ) : (
    <NoImage
      $dark={!!dark}
      as="div"
      onClick={onClick}
      className={className}
      size={size}
    >
      {firstLetter}
    </NoImage>
  );
};

const capitalizeFirstLetter = (name = '') => {
  return name.charAt(0).toUpperCase();
};

const Image = styled.img<{ size?: string }>`
  border-radius: 50%;
  height: ${(props) => (props.size === 'medium' ? '54px' : '40px')};
  width: ${(props) => (props.size === 'medium' ? '50px' : '40px')};
  transition: 300ms ease-in-out;
  box-sizing: border-box;
`;

const NoImage = styled(Image)<{ $dark: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.$dark ? props.theme.palette.greys.lightLightGrey : props.theme.palette.greys.middleGrey};
  ${FontStyles.bodyMiddleGilroy};
  line-height: unset;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  flex-shrink: 0;
`;

export default Avatar;
