import { CanceledError } from 'axios';

import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const login = async (credentials: LoginCredentials) => {
  try {
    const { email, password } = credentials;
    if (!email || !password) return;
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/access/login', {
      email,
      password,
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
      throw error;
    }
  }
};

const logout = async () => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/access/logout');
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const acquireNewAccessToken = async (identity: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/access/renew', identity);
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};
const getUserSessionLogs = async (userId: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get(`/access/${userId}/session-logs`);
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

export const AccessService = {
  login,
  logout,
  acquireNewAccessToken,
  getUserSessionLogs,
};
