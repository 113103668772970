import { CanceledError } from 'axios';

import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const issueChallenge = async (data: { challenge: ChallengeRequest; signal?: AbortSignal }) => {
  try {
    const { challenge, signal } = data;
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/challenges/issue',
      { resourceType: challenge.resourceType, resourceIds: challenge.resourceIds },
      {
        headers: {
          'idempotency-ref': challenge.idempotency,
        },
        ...(signal ? { signal } : {}),
      }
    );
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const verifyChallenge = async (challengeVerificationRequest: ChallengeVerificationRequest) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/challenges/verify',
      {
        scaChallengeId: challengeVerificationRequest.scaChallengeId,
        verificationCode: challengeVerificationRequest.verificationCode,
        resourceType: challengeVerificationRequest.resourceType,
      },
      {
        headers: {
          'idempotency-ref': challengeVerificationRequest.idempotency,
        },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

export const ChallengesService = {
  issueChallenge,
  verifyChallenge,
};
