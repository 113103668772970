import { DefaultTheme, keyframes } from 'styled-components';

export const LightTheme: DefaultTheme = {
  palette: {
    primary: {
      black: '#151515',
      white: '#FFFFFF',
      blue: '#1099FD',
      middleBlue: '#1099FD4D',
      lightBlue: '#ecf7ff',
      secondBlue: '#0C81E9',
      deepPurple: 'rgba(237, 231, 246, 1)',
    },

    gradients: {
      turkishSapphireGradient: 'linear-gradient(16.05deg, #477A8F 22.34%, #96C2DB 90.77%)',
      stoneGreyBlueMix: 'linear-gradient(23.48deg, #3F3E62 6.8%, #7D7FAD 80%)',
      greySilverGradient: {
        solid: 'linear-gradient(249.15deg, #BFC8DB 2.79%, #7A8599 97.66%)',
        alpha:
          'linear-gradient(249.15deg, rgba(191, 200, 219, 0.26) 2.79%, rgba(122, 133, 153, 0.26) 97.66%)',
      },
      blueOrangeTint: 'linear-gradient(262.45deg, #E8E2DB 8.5%, #D6DBF3 101.62%)',
      goldishGradient: 'linear-gradient(195.14deg, #E9AF86 12.69%, #9B674A 80.29%)',
      tintRoseGradient: 'linear-gradient(193.34deg, #C7AEE7 9.58%, #8C78A7 79.6%)',
      primaryBlueGradient: 'linear-gradient(50.33deg, #1099FD 2%, #74C4FF 81.35%)',
      primaryBlackGradient: 'linear-gradient(270deg, #232222 0%, #34354A 98.33%)',
    },

    greys: {
      lightLightGrey: '#F3F3F9',
      lightGrey: '#F5F5F5',
      borderGrey: '#1f2023',
      middleGrey: '#D6DDEA',
      darkGrey: '#ACB3C0',
      darkestGrey: '#7B7B8A',
      asphaltTint: '#9B9FB6',
      blackGrey: '#212121',
    },

    status: {
      main: '#2E7D32',
      pendingFinishStatusBackground: 'rgba(255, 230, 130, 0.10);',
      pendingFinishStatusBackgroundHover: 'rgba(255, 230, 130, 0.30);',
      pendingFinishStatusIcon: 'rgba(255, 230, 130, 0.20);',
      pendingFinishStatusIconHover: 'rgba(255, 230, 130, 0.40);',
      pendingFinishStatus: '#FFE682',
    },

    negative: {
      contrastRed: '#FD4734',
      lessContrastRed: '#FF7878',
      lessContrastRedLight: 'rgba(255, 120, 120, 0.5)',
      lessContrastRedLighter: 'rgba(255, 120, 120, 0.2)',
    },

    positive: {
      contrastGreen: '#69C42D',
      lessContrastGreen: '#B9DB8D',
      lessContrastGreenLight: 'rgba(185, 219, 141, 0.5)',
      lessContrastGreenLighter: 'rgba(185, 219, 141, 0.2)',
    },
    success: {
      light: '#4CAF50',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#EF6C00',
      light: '#FF9800',
    },
  },

  spacing: {
    xs: '5px',
    s: '10px',
    sm: '20px',
    m: '30px',
    l: '40px',
    xl: '50px',
    xxl: '60px',
  },

  /*
      Reference: https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
      We took only the sweet spot for devices.
      This variable will and should be adjusted to our needs in the future.
   */
  breakpoints: {
    // Small devices (landscape phones, 576px and up)
    mobile: 'min-width: 576px',
    // Medium devices (tablets, 768px and up)
    tablet: 'min-width: 768px',
    // Large devices (laptops, 1200px and up)
    laptop: 'min-width: 1200px',
    // X-Large devices (small desktops, 1440px and up)
    smallDesktop: 'min-width: 1440px',
    // XX-Large devices (larger desktops, 1920px and up)
    desktop: 'min-width: 1920px',
  },

  animations: {
    fadeIn: keyframes` 0%{opacity: 0; visibility: hidden} 100% {opacity: 1;visibility: visible}`,
    fadeOut: keyframes` 0% {opacity: 1;visibility: visible;} 100% {opacity: 0;visibility: hidden;}`,
  },

  typography: {
    buttonsNeuePlakType1: {
      fontFamily: '"Neue Plak Extended Semi", sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '130%',
    },
    h1: {
      fontFamily: '"Neue Plak Extended", sans-serif',
      fontWeight: 400,
      fontSize: '96px',
      lineHeight: '120%',
    },
    h2: {
      fontFamily: '"Neue Plak Extended", sans-serif',
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: '130%',
    },
    h3: {
      fontFamily: '"Neue Plak Extended", sans-serif',
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '130%',
    },
    h4: {
      fontFamily: '"Neue Plak Extended Semi", sans-serif',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '130%',
    },
    h6Main: {
      fontFamily: '"Neue Plak Extended Semi", sans-serif',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '130%',
    },
    h5: {
      fontFamily: '"Neue Plak Extended Semi", sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '125%',
    },
    h6: {
      fontFamily: '"Neue Plak Extended Semi", sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '130%',
    },
    h7: {
      fontFamily: '"Neue Plak Extended Semi", sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '167%',
    },
    gilroy: {
      fontFamily: '"Gilroy", sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
    },
    primary: {
      fontFamily: '"Gilroy", sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '140%',
    },
    rareGilroy: {
      fontFamily: '"Gilroy Bold", sans-serif',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '83%',
    },
    bodySmallGilroy: {
      fontFamily: '"Gilroy Medium", sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '130%',
    },
    bodyMiddleGilroy: {
      fontFamily: '"Gilroy Medium", sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '130%',
    },
    bodyLargeGilroy: {
      fontFamily: '"Gilroy Medium", sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '130%',
    },
    subtitleForH1SlogansOnly: {
      fontFamily: 'Gilroy, sans-serif',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '120%',
    },
    subtitleGilroy: {
      fontFamily: '"Gilroy Bold", sans-serif',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '111%',
    },
    buttonGilroyType2: {
      fontFamily: '"Gilroy Semibold", sans-serif',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '130%',
    },
    buttonGilroyType3: {
      fontFamily: '"Gilroy Semibold", sans-serif',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
    },
    buttonGilroyType3SmallIbanOnly: {
      fontFamily: '"Gilroy Semibold", sans-serif',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
    buttonGilroySmall: {
      fontFamily: '"Gilroy Semibold", sans-serif',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '22px',
    },
    body2: {
      fontFamily: '"Gilroy Medium", sans-serif',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '167%',
    },
  },
};
