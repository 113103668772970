import styled from 'styled-components';

import ContactInformationWrap from '@components/transaction/ContactInformation/ContactInformation';
import TransactionFormWrapper from '@components/transaction/TransactionFormWrapper/TransactionFormWrapper';

export const Container = styled.div`
  display: flex;
  padding: 10px 20px 20px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 21px 24px 22px;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0 40px 40px;
    gap: 21px;
    justify-content: center;
  }

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    gap: 40px;
  }
`;

export const CustomTransactionFormWrapper = styled(TransactionFormWrapper)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;

export const Wrap = styled.div`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;
