import { useEffect } from 'react';
import styled from 'styled-components';

import { useGetCorporate } from '@api/Corporate/corporateApi';

import { convertCountryFromISO3166ToFullName } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { SettingsInfoBlurredBg } from '@constants/images';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

const SettingsInfo = () => {
  const { handleHTTPErrors } = useHTTPErrorHandler();
  const { data: corporate, isError, error } = useGetCorporate();

  useEffect(() => {
    if (isError && error) {
      handleHTTPErrors([error]);
    }
  }, [error, handleHTTPErrors, isError]);

  const { company } = corporate;
  const { name } = company;
  const { businessAddress } = company;
  const { country } = businessAddress;

  return (
    <Container>
      <CompanyInfo $nodata={!name && !country}>
        {(name || country) && (
          <CompanyTextContainer>
            {name && <CompanyHeading>{name}</CompanyHeading>}
            {country && <CompanyText>{convertCountryFromISO3166ToFullName(country)}</CompanyText>}
          </CompanyTextContainer>
        )}
      </CompanyInfo>
      <LastLoginInfo>
        <LastLoginHeading>Last login time:</LastLoginHeading>
        <LastLoginOuter>
          <LastLoginUpper>
            <LastLoginText>08/05/23</LastLoginText>
            <LastLoginText> 11:02:03</LastLoginText>
          </LastLoginUpper>
          <LastLoginText> IP: 31.43.30.61</LastLoginText>
        </LastLoginOuter>
      </LastLoginInfo>
    </Container>
  );
};
export default SettingsInfo;

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  border-radius: 20px;
  background-image: url(${SettingsInfoBlurredBg});
  background-size: cover;
  height: auto;
  flex-shrink: 0;
  justify-content: start;
  padding: 15px;
  flex-direction: column;
  width: calc(100vw - 40px);
  gap: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-items: center;
    flex-direction: initial;
    width: calc(100vw - 48px);
    height: 100px;
    gap: 30px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    justify-content: center;
    padding: 35px;
    width: 500px;
    height: 180px;
  }
`;

const CompanyInfo = styled.div<{ $nodata: boolean }>`
  display: flex;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 15px ${({ $nodata }) => ($nodata ? '15px' : '40px')} 15px 15px;
  gap: 10px;
  flex-shrink: 0;
  background: #fff;
  width: 100%;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 50%;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: initial;
    justify-content: center;
    background: linear-gradient(262deg, #e8e2db 8.5%, #d6dbf3 101.62%);
    padding: 26.5px 30px;
  }
`;

const CompanyLogo = styled.div`
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: ${(props) => props.theme.palette.greys.middleGrey};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  & svg {
    width: 24px;
    height: 24px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 70px;
    width: 70px;
    background-color: ${(props) => props.theme.palette.primary.white};

    & svg {
      width: initial;
      height: initial;
    }
  }
`;

const CompanyTextContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  max-height: 40px;
  justify-content: center;
  max-width: 150px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 5px;
  }
`;

const CompanyHeading = styled.p`
  ${FontStyles.h5};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
  }
`;

const CompanyText = styled.p`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 50%;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodyMiddleGilroy};
  }
`;

const LastLoginInfo = styled.div`
  display: none;
  box-sizing: border-box;
  gap: 9px;
  justify-content: space-between;
  flex: 1 0;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
    opacity: 0;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    justify-content: initial;
    flex-direction: column;
    white-space: nowrap;
    color: initial;
  }
`;

const LastLoginOuter = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: end;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 9px;
    align-items: start;
  }
`;

const LastLoginUpper = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 5px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 9px;
    flex-direction: column;
  }
`;

const LastLoginHeading = styled.p`
  ${FontStyles.h7};
  margin-bottom: 1px;
  color: ${(props) => props.theme.palette.greys.middleGrey};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    color: initial;
    ${FontStyles.h5};
  }
`;

const LastLoginText = styled.p`
  ${FontStyles.body2};
  color: #fff;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    color: ${(props) => props.theme.palette.primary.black};
    ${FontStyles.bodySmallGilroy};
  }
`;
