import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { formatDateObject } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  notificationType: 'news' | 'transaction' | 'important';
  onClick?: () => void;
  icon?: ReactElement;
  title: string;
  time: string;
  text: string;
  read: boolean;
  kind?: 'positive' | 'negative';
  image?: string;
}

const Notification = ({
  notificationType,
  onClick,
  icon,
  time,
  text,
  read,
  title,
  kind,
  image,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const notify = useBoundStore((state) => state.notify);
  const countdown = useBoundStore((state) => state.countdown);
  const setCountdown = useBoundStore((state) => state.setCountdown);
  const setNotify = useBoundStore((state) => state.setNotify);
  const setMessage = useBoundStore((state) => state.setMessage);
  const setPopUpType = useBoundStore((state) => state.setPopUpType);

  useEffect(() => {
    if (notify) {
      const timerId = setInterval(() => {
        if (countdown <= 1) {
          clearInterval(timerId);
          setNotify(false);
          setMessage('');
          setPopUpType('open');
          setCountdown(5);
        } else {
          setCountdown((prevValue) => prevValue - 1);
        }
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [countdown, notify, setCountdown, setMessage, setNotify, setPopUpType]);

  return (
    <Container
      onClick={() => {
        if (onClick) {
          onClick();
        }
        notificationType === 'news' && setExpanded(!expanded);
      }}
      className={expanded ? 'expanded' : ''}
    >
      <Icon
        $read={read}
        className={kind ? kind : ''}
      >
        {icon}
      </Icon>
      <Content>
        <Title>{title}</Title>
        <Time>{formatDateObject(new Date(time))}</Time>
        <Image
          className={expanded && notificationType === 'news' ? 'show' : ''}
          $image={image || ''}
        />
        <Text
          className={
            notificationType === 'transaction' || (notificationType === 'news' && expanded)
              ? ''
              : 'short'
          }
        >
          {text}
        </Text>
        <Footer className={expanded && notificationType === 'news' ? 'show' : ''}>
          <Mail href="mailto:email@example.com">Luciano@gmail.com</Mail>
          <FooterLower>
            <LargeButtonTransparent>Button if needed</LargeButtonTransparent>
            <LargeButtonBlue>Button if needed</LargeButtonBlue>
          </FooterLower>
        </Footer>
      </Content>
    </Container>
  );
};
export default Notification;

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  gap: 10px;
  padding: 20px 29px 20px 25px;
  cursor: pointer;
  transition: 300ms ease-in-out;
  box-sizing: border-box;

  &:hover {
    background: ${(props) => props.theme.palette.greys.middleGrey};
  }

  &.expanded {
    background: ${(props) => props.theme.palette.gradients.blueOrangeTint};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div<{ $read?: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  flex-shrink: 0;
  transition: border ease-in-out 300ms;
  color: ${(props) => props.theme.palette.primary.black};
  border: 1px solid
    ${(props) =>
      props.$read ? props.theme.palette.greys.darkGrey : props.theme.palette.primary.blue};

  &.positive {
    color: ${(props) => props.theme.palette.positive.contrastGreen};
  }
  &.negative {
    color: ${(props) => props.theme.palette.negative.contrastRed};
  }
`;

const Title = styled.p`
  ${FontStyles.h5};
  padding-bottom: 2px;
`;

const Time = styled.p`
  ${FontStyles.bodySmallGilroy};
  background: linear-gradient(23.48deg, #3f3e62 6.8%, #7d7fad 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-bottom: 10px;
`;

const Image = styled.div<{ $image: string }>`
  display: none;
  background: url(${(props) => props.$image});
  width: 310px;
  height: 180px;
  margin-bottom: 10px;
  &.show {
    display: flex;
  }
`;

const Text = styled.div`
  ${FontStyles.bodySmallGilroy};
  white-space: pre-wrap;

  &.short {
    display: -webkit-box;
    max-width: 310px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Footer = styled.div`
  margin-top: 10px;
  display: none;
  gap: 20px;
  flex-direction: column;
  &.show {
    display: flex;
  }
`;

const FooterLower = styled.div`
  display: flex;
  gap: 20px;
`;

const Mail = styled.a`
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.primary.blue};
  text-decoration: none;
`;

const CommonButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 75px;
  max-height: 50px;
  width: 100%;
  ${FontStyles.buttonGilroyType3};
  cursor: pointer;

  &:disabled {
    background: ${(props) => props.theme.palette.greys.darkGrey};
    color: #fff;
    cursor: unset;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: 300px;
  }
`;

const LargeButtonBlue = styled(CommonButton)`
  color: ${(props) => props.theme.palette.primary.white};
  background: linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a);
  background-size: 500% auto;
  transition: 300ms ease-in-out;
  height: 34px;
  max-width: 148px;
  ${FontStyles.bodySmallGilroy};

  &:hover {
    background-position: 90% 120%;
    cursor: pointer;
  }
`;

const LargeButtonTransparent = styled(CommonButton)`
  color: ${(props) => props.theme.palette.primary.black};
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  transition: 300ms ease-in-out;
  height: 34px;
  max-width: 148px;
  ${FontStyles.bodySmallGilroy};

  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
    cursor: pointer;
  }
`;
