import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetCorporate, useGetKYB, useStartKYB } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import EmailModal from '@components/EmailModal/EmailModal';
import {
  Avatar,
  Container,
  NoTransactionsText,
  Text,
  TextBlock,
  TransactionContainer,
  TransactionTextWrapper,
  Wrapper,
} from '@components/NoScheduledPaymentsPlaceholder/NoScheduledPaymentsPlaceholder.styles';
import OTPModal from '@components/OTPModal/OTPModal';

import LargeButton from '@elements/LargeButton/LargeButton';

interface Props {
  size: 'mobile' | 'tablet';
  verify: boolean;
  text: string;
}

const NoScheduledPaymentsPlaceholder = ({ size, verify, text }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openModal = useBoundStore((state) => state.openModal);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: corporateData } = useGetCorporate();
  const { data: kybData } = useGetKYB();

  const { mutate: startCorporateKybMutation } = useStartKYB();

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (submitting) return;

    setSubmitting(true);

    if (!corporateData) {
      setSubmitting(false);
      return;
    }

    const { rootUser } = corporateData;
    const { emailVerified, mobileNumberVerified } = rootUser;

    if (!emailVerified) {
      openModal(<EmailModal />);
      setSubmitting(false);
    } else if (!mobileNumberVerified) {
      openModal(<OTPModal />);
      setSubmitting(false);
    } else {
      startCorporateKybMutation(undefined, {
        onSuccess: (data) => {
          if (data) {
            const { reference } = data;
            navigate('/kyb', { state: { kybRef: reference } });
          }
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        },
      });
    }
  };

  return !verify ? (
    <>
      <NoTransactionsText>No scheduled payments yet</NoTransactionsText>
      <Wrapper>
        {Array.from({ length: 4 }).map((_, index) => (
          <TransactionPlaceholder key={index} />
        ))}
      </Wrapper>
    </>
  ) : (
    <Container $verify={verify}>
      <Text
        size={size}
        $verify={verify}
      >
        <Trans t={t}>{text}</Trans>
      </Text>
      {verify && (
        <LargeButton
          disabled={submitting}
          onClick={handleButtonClick}
          text={checkIfKybInitiated() ? 'View Status' : t('verify_account')}
          variant="transparentBlack"
          style={{ maxWidth: '190px', maxHeight: '50px' }}
        />
      )}
    </Container>
  );
};

const TransactionPlaceholder = () => {
  return (
    <TransactionContainer>
      <Avatar />
      <TransactionTextWrapper>
        <TextBlock
          $width={85}
          $height={18}
        />
        <TextBlock
          $width={70}
          $height={8}
        />
      </TransactionTextWrapper>
    </TransactionContainer>
  );
};

export default NoScheduledPaymentsPlaceholder;
