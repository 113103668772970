import { useMutation } from '@tanstack/react-query';

import { ChallengesService } from '@services/challenges-service';

const useIssueChallenge = () => {
  return useMutation({
    mutationFn: (data: { challenge: ChallengeRequest; signal?: AbortSignal }) => {
      return ChallengesService.issueChallenge(data);
    },
  });
};

const useVerifyChallenge = () => {
  return useMutation({
    mutationFn: (challengeVerificationRequest: ChallengeVerificationRequest) => {
      return ChallengesService.verifyChallenge(challengeVerificationRequest);
    },
  });
};

export { useIssueChallenge, useVerifyChallenge };
