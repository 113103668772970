import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  useGetNotifications,
  useUpdateNotificationReadStatus,
} from '@api/Notifications/notificationsApi';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconInfo } from '@constants/icons';

import { useClickedOutside } from '@hooks/useClickedOutside';
import { useLockBodyScroll } from '@hooks/useLockBodyScroll';

import Notification from '@components/Notification/Notification';

const weavrNotificationTypeToPeanudsType = (type: string): 'news' | 'transaction' | 'important' => {
  switch (type) {
    case 'corporate-kyb-status-update':
    case 'login-attempt':
    case 'corporate-activation':
    case 'corporate-deactivation':
      return 'important';
    case 'outgoing-wire-transfer-transaction-update':
    case 'transfer-status-update':
    case 'send-transfer-status-update':
    case 'managed-account-deposit':
      return 'transaction';
    default:
      return 'important';
  }
};

const NotificationCenter = () => {
  const notificationCenterRef = useRef<HTMLDivElement>(null);
  const notificationsOpen = useBoundStore((state) => state.notificationsOpen);
  const setNotificationsOpen = useBoundStore((state) => state.setNotificationsOpen);
  const notifications = useBoundStore((state) => state.notifications);
  const setNotifications = useBoundStore((state) => state.setNotifications);
  const [filter, setFilter] = useState('');

  useLockBodyScroll(notificationsOpen);
  useClickedOutside(notificationCenterRef, setNotificationsOpen);

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const { data: notificationData } = useGetNotifications();
  const { mutate: updateNotificationReadStatusMutation } = useUpdateNotificationReadStatus();

  const queryClient = useQueryClient();

  const notificationsResult: notificationsFromDb[] = useMemo(() => {
    if (!notificationData?.notifications?.length) {
      return [];
    }

    if (!filter) {
      return notificationData.notifications;
    }

    return notificationData.notifications.filter(
      (notification: notificationsFromDb) =>
        weavrNotificationTypeToPeanudsType(notification.type) === filter
    );
  }, [notificationData, filter]);

  useEffect(() => {
    if (notificationsOpen) {
      updateNotificationReadStatusMutation(undefined, {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['getNotifications'],
            exact: false,
            refetchType: 'active',
          });
        },
        onError: (error) => {
          console.log(error.message);
        },
      });
    }
  }, [notificationsOpen, queryClient, updateNotificationReadStatusMutation]);

  return (
    <Container
      id="notificationCenter"
      ref={notificationCenterRef}
      className={notificationsOpen ? '' : 'hide'}
    >
      <Top>
        Notifications
        <LargeButtonBlue
          type="button"
          onClick={() => setNotificationsOpen(false)}
        >
          Close Notifications
        </LargeButtonBlue>
      </Top>
      <FilterWrapper>
        <Filter>
          <Input
            type="radio"
            value=""
            name="filter"
            hidden
            id="0"
            defaultChecked
            onChange={(event) => onChangeValue(event)}
          />
          <FilterButton htmlFor="0">All</FilterButton>
          <Input
            type="radio"
            value="transaction"
            name="filter"
            hidden
            id="1"
            onChange={(event) => onChangeValue(event)}
          />
          <FilterButton htmlFor="1">Transactions</FilterButton>
          <Input
            type="radio"
            value="important"
            name="filter"
            hidden
            id="2"
            onChange={(event) => onChangeValue(event)}
          />
          <FilterButton htmlFor="2">Important</FilterButton>
        </Filter>
      </FilterWrapper>
      <Content>
        {notificationsResult?.map((item, index) => {
          return (
            <Notification
              icon={<IconInfo />}
              key={index}
              notificationType={weavrNotificationTypeToPeanudsType(item.type)}
              text={item.message}
              time={item.date}
              title={item.title}
              read={item.read}
            />
          );
        })}
      </Content>
      <Footer>
        {notifications ? (
          <LargeButtonTransparent
            type="button"
            onClick={() => setNotifications(false)}
          />
        ) : (
          'No new notifications found'
        )}
      </Footer>
    </Container>
  );
};

export default NotificationCenter;

const Container = styled.div`
  display: flex;
  height: calc(100% - 60px);
  width: 100vw;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  opacity: 1;
  right: 0;
  top: 60px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  animation: show 300ms;

  @keyframes show {
    from {
      display: none;
      opacity: 0;
      right: -100%;
    }
    to {
      display: flex;
      opacity: 1;
      right: 0;
    }
  }

  @keyframes hide {
    from {
      display: flex;
      opacity: 1;
      right: 0;
    }
    to {
      display: none;
      opacity: 0;
      right: -100%;
    }
  }

  &.hide {
    display: none;
    opacity: 0;
    right: -100%;
    animation: hide 300ms;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 410px;
    border-radius: 20px 0 0 0;
    box-shadow: -7px 0 44px rgba(123, 123, 138, 0.1);
    background-color: ${(props) => props.theme.palette.primary.white};
    max-height: calc(100% - 100px);
    top: 100px;
  }
`;

const Top = styled.div`
  display: flex;
  padding: 33px 35px 30px;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  gap: 23px;
  ${FontStyles.h4};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
const Filter = styled.fieldset`
  display: flex;
  max-height: 53px;
  padding: 15px 22px;
  align-items: center;
  justify-content: space-between;
  ${FontStyles.buttonGilroyType2};
  max-width: 366px;
  width: 100%;
`;

const FilterWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: calc(100%);
  overflow: hidden auto;
  ${HideScrollbar};
`;

const Footer = styled.div`
  display: flex;
  height: 55px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${FontStyles.bodySmallGilroy};
  margin-top: auto;
  border-top: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
`;

const CommonButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 75px;
  max-height: 50px;
  width: 100%;
  ${FontStyles.buttonGilroyType3};
  cursor: pointer;

  &:disabled {
    background: ${(props) => props.theme.palette.greys.darkGrey};
    color: #fff;
    cursor: unset;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: 300px;
  }
`;

const LargeButtonBlue = styled(CommonButton)`
  color: ${(props) => props.theme.palette.primary.white};
  background: linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a);
  background-size: 500% auto;
  transition: 300ms ease-in-out;
  height: 34px;
  max-width: 161px;
  flex-shrink: 0;
  ${FontStyles.bodySmallGilroy};

  &:hover {
    background-position: 90% 120%;
    cursor: pointer;
  }
`;

const LargeButtonTransparent = styled(CommonButton)`
  color: ${(props) => props.theme.palette.primary.black};
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  transition: 300ms ease-in-out;
  height: 34px;
  max-width: 364px;
  ${FontStyles.bodySmallGilroy};

  &:hover {
    color: ${(props) => props.theme.palette.primary.blue};
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
    cursor: pointer;
  }
`;

const Input = styled.input`
  &:checked + label {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;

const FilterButton = styled.label`
  display: flex;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  cursor: pointer;
  transition: 300ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }

  &:active {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;
