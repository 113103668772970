import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useBoundStore } from '@stores/BoundStore';

import ContactsListSendMoney from '@components/ContactsListSendMoney/ContactsListSendMoney';
import ContactInformationWrap from '@components/transaction/ContactInformation/ContactInformation';

import { Container, Wrap } from './SendMoney.styles';

const SendMoney = () => {
  const setScrolled = useBoundStore((state) => state.setScrolled);
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const navigate = useNavigate();

  if (!selectedContact) navigate('/send-money');

  useEffect(() => {
    setScrolled(false);
  }, [setScrolled]);

  useEffect(() => {
    if (!selectedContact) {
      navigate(-1);
    }
  }, [selectedContact]);

  return (
    <Container>
      <Wrap>
        <ContactsListSendMoney />
      </Wrap>
      <ContactInformationWrap />
    </Container>
  );
};

export default SendMoney;
