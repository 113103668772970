import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const getContacts = async (managedAccountId: string, searchQuery?: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/contacts/getContacts', {
      params: { managedAccountId, searchQuery },
    });
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getContactsByTransactionId = async (managedAccountId: string, transactionId: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get(
      '/contacts/getContactsByTransactionId',
      {
        params: { managedAccountId, transactionId },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteContact = async (contactId: string) => {
  try {
    return await weavrAuthorizedAxiosClient.delete(`/contacts/deleteContact/${contactId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteIban = async (iban: string) => {
  try {
    return await weavrAuthorizedAxiosClient.delete(`/contacts/deleteIban/${iban}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateContact = async (contact: ContactsWithBankDetails) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/contacts/update', { contact });
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
  }
};

export const ContactService = {
  getContacts,
  deleteContact,
  deleteIban,
  getContactsByTransactionId,
  updateContact,
};
