import { useQueryClient } from '@tanstack/react-query';
import React, { ComponentType, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useGetKYB } from '@api/Corporate/corporateApi';
import {
  useCreateManagedAccount,
  useGetAllManagedAccounts,
  useUpgradeManagedAccountToIban,
} from '@api/ManagedAccounts/managedAccountsApi';

import { useBoundStore } from '@stores/BoundStore';

import { getToken } from '@shared/functions';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import InactivityTimer from '@components/InactivityTimer/InactivityTimer';
import Navigation from '@components/Navigation/Navigation';
import NotificationCenter from '@components/NotificationCenter/NotificationCenter';

import Toast from '@elements/Toast/Toast';

import getSocket from '../websocket';

interface Props {
  component: ComponentType;
}

const ProtectedRoute = ({ component: Component }: Props) => {
  const isLoggedIn = useBoundStore((state) => state.isLoggedIn);
  const location = useLocation();
  const queryClient = useQueryClient();

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const setVerifiedKYC = useBoundStore((state) => state.setVerifiedKYC);

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const { data: kybData, isError: isGetKYBError, error: kybError } = useGetKYB();
  const {
    data: managedAccounts,
    isError: isGetAllManagedAccountsError,
    error: managedAccountsError,
  } = useGetAllManagedAccounts(isGetKYBError);

  const { mutate: createManagedAccountMutation } = useCreateManagedAccount();
  const { mutate: upgradeManagedAccountToIbanMutation } = useUpgradeManagedAccountToIban();

  useEffect(() => {
    const errorsToHandle = [];
    if (isGetKYBError && kybError) {
      errorsToHandle.push(kybError);
    }

    if (isGetAllManagedAccountsError && managedAccountsError) {
      errorsToHandle.push(managedAccountsError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [
    isGetKYBError,
    kybError,
    isGetAllManagedAccountsError,
    managedAccountsError,
    handleHTTPErrors,
  ]);

  useEffect(() => {
    if (isLoggedIn) {
      if (kybData?.kybStatus) setVerifiedKYC(kybData.kybStatus === 'APPROVED');
    } else {
      setVerifiedKYC(false);
    }
  }, [kybData, isLoggedIn, setVerifiedKYC]);

  useEffect(() => {
    if (verifiedKYC && managedAccounts) {
      const { count } = managedAccounts;
      if (count === 0) {
        const idempotency = crypto.randomUUID();
        createManagedAccountMutation(
          { friendlyName: 'main-account', currency: 'EUR', idempotency },
          {
            onSuccess: (data) => {
              if (data) {
                const { id, state } = data;
                if (state.state === 'ACTIVE') {
                  upgradeManagedAccountToIbanMutation(id, {
                    onError: (error) => {
                      handleHTTPErrors([error]);
                    },
                  });
                }
              }
            },
            onError: (error) => {
              handleHTTPErrors([error]);
            },
          }
        );
      }
    }
  }, [
    createManagedAccountMutation,
    handleHTTPErrors,
    managedAccounts,
    queryClient,
    upgradeManagedAccountToIbanMutation,
    verifiedKYC,
  ]);

  useEffect(() => {
    const token = getToken();
    if (!token) return;

    const socket = getSocket(token);

    socket.connect();

    return () => {
      if (socket && socket.connected) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const token = getToken();
    if (!token) return;

    const socket = getSocket(token);

    const handleMessage = async ({
      title,
      message,
      frontendRefetchKeys,
    }: {
      title: string;
      message: string;
      frontendRefetchKeys?: string[];
    }) => {
      toast.info(
        <Toast
          title={title}
          message={message}
        />
      );
      if (frontendRefetchKeys && frontendRefetchKeys.length > 0) {
        await queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey.some((key) => frontendRefetchKeys.includes(key as string)),
          refetchType: 'active',
        });
      }
    };

    const handleDisconnect = () => {
      // NOOP
    };

    socket.on('notification', handleMessage);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('notification', handleMessage);
      socket.off('disconnect', handleDisconnect);
    };
  }, [queryClient]);

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/login"
        replace
        state={{ wantedPath: location.pathname }}
      />
    );
  } else {
    return (
      <>
        <InactivityTimer />
        <Navigation />
        <NotificationCenter />
        <Component />
      </>
    );
  }
};

export default ProtectedRoute;
