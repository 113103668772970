import { useMutation, useQuery } from '@tanstack/react-query';

import { CorporateService } from '@services/corporate-service';
import { OnboardingService } from '@services/onboarding-service';

export const useGetCorporate = () => {
  const defaultCorporateData: WeavrCorporateResponse = {
    id: {
      type: '',
      id: '',
    },
    profileId: '',
    tag: '',
    rootUser: {
      id: {
        type: '',
        id: '',
      },
      name: '',
      surname: '',
      email: '',
      mobile: {
        countryCode: '',
        number: '',
      },
      companyPosition: '',
      active: false,
      emailVerified: false,
      mobileNumberVerified: false,
      dateOfBirth: {
        year: 0,
        month: 0,
        day: 0,
      },
      tag: '',
    },
    company: {
      name: '',
      type: '',
      registrationNumber: '',
      registeredAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postCode: '',
        state: '',
        country: '',
      },
      businessAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postCode: '',
        state: '',
        country: '',
      },
      countryOfRegistration: '',
      incorporatedOn: {
        year: 0,
        month: 0,
        day: 0,
      },
    },
    industry: '',
    sourceOfFunds: '',
    sourceOfFundsOther: '',
    acceptedTerms: false,
    ipAddress: '',
    baseCurrency: '',
    feeGroup: '',
    creationTimestamp: 0,
  };

  return useQuery({
    queryKey: ['getCorporate'],
    placeholderData: defaultCorporateData,
    queryFn: async ({ signal }) => {
      return await CorporateService.getCorporate(signal);
    },
  });
};

export const useStartKYB = () => {
  return useMutation({
    mutationFn: () => {
      return CorporateService.startKYB();
    },
  });
};

export const useUpdateCorporate = () => {
  return useMutation({
    mutationFn: (updatedCorporateData: WeavrUpdatedCorporateData) => {
      return CorporateService.updateCorporate(updatedCorporateData);
    },
  });
};

export const useCreateCorporate = () => {
  return useMutation({
    mutationFn: (corporateData: CorporateRegistrationData) => {
      return CorporateService.createCorporate(corporateData);
    },
  });
};

export const useChargeFee = () => {
  return useMutation({
    mutationFn: (feeBody: WeavrChargeFeeBody) => {
      return CorporateService.chargeFee(feeBody);
    },
  });
};

export const useSendEmailVerificationCodeCorporateRegistration = () => {
  return useMutation({
    mutationFn: (email: string) => {
      return OnboardingService.sendEmailVerificationCode(email);
    },
  });
};

export const useVerifyCorporateEmail = () => {
  return useMutation({
    mutationFn: ({
      email,
      verificationCode,
      signal,
    }: WeavrEmailVerification & { signal: AbortSignal }) => {
      return OnboardingService.verifyEmail({ email, verificationCode, signal });
    },
  });
};

export const useGetKYB = () => {
  return useQuery({
    queryKey: ['getKYB'],
    queryFn: async ({ signal }) => {
      return await CorporateService.getKYB(signal);
    },
  });
};
