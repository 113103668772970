import React, { useCallback, useState } from 'react';

import {
  ErrorContainer,
  Label,
  SubLabel,
} from '@views/Onboarding/OnboardingCompanyDetailsForm/OnboardingCompanyDetailsForm.styles';

import { InputRow, StyledTextField } from './TextFieldStyles';

interface TextFieldComponentProps {
  label?: string;
  labelTop?: React.ReactNode;
  subLabel?: React.ReactNode;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  placeholder?: string;
  errorText?: string;
  icon?: React.ReactNode;
  error?: boolean;
  validate?: (value: string) => string;
  type?: 'outlined' | 'solid';
}

const TextFieldComponent = ({
  label,
  labelTop,
  subLabel,
  value,
  onChange,
  onBlur,
  placeholder,
  icon,
  validate,
  type = 'solid',
}: TextFieldComponentProps) => {
  const [error, setError] = useState<string | undefined>('');
  const [touched, setTouched] = useState<boolean>(false);

  // useEffect(() => {
  //   const errormessage = validate?.(value);
  //   if (touched && validate) {
  //     setError(errormessage);
  //   }
  // }, [touched, validate, value]);

  const handleBlur = useCallback(() => {
    setTouched(true);
    validate && setError(validate(value));
  }, [validate, value]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e);
    const errormessage = validate?.(value);
    if (touched && validate) {
      setError(errormessage);
    }
  };

  return (
    <InputRow>
      <Label>
        {labelTop} {subLabel && <SubLabel>{subLabel}</SubLabel>}
      </Label>
      <StyledTextField
        label={label}
        error={touched && Boolean(error)}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleOnChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        autoComplete="off"
        InputProps={{
          endAdornment: icon,
        }}
        type={type}
      />
      <ErrorContainer $visible={touched && Boolean(error)}>{error}</ErrorContainer>
    </InputRow>
  );
};

export default TextFieldComponent;
