import React, { UIEvent, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconComingSoon, IconDelete } from '@constants/icons';
import { ComingSoonBackground } from '@constants/images';
import { LogoSEPA, LogoSwift } from '@constants/logos';

import useMediaQuery from '@hooks/useMediaQuery';

import ContactTransactionHistory from '@components/ContactTransactionHistory/ContactTransactionHistory';
import ScheduleDeleteModal from '@components/ScheduleDeleteModal';
import TabNavigation from '@components/TabNavigation/TabNavigation';
import AddIbanSection from '@components/transaction/ContactInformation/AddIbanSection/AddIbanSection';
import TransactionForm from '@components/transaction/TransactionForm/TransactionForm';

import DeleteButton from '@elements/DeleteButton/DeleteButton';

import ContactHeader from './ContactHeader';

interface Props {
  className?: string;
}

const TransactionFormWrapper = ({ className }: Props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(`(${theme.breakpoints.tablet})`);

  const openModal = useBoundStore((state) => state.openModal);
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const scheduledFormMode = useBoundStore((state) => state.scheduledFormMode);
  const setScrolled = useBoundStore((state) => state.setScrolled);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // const [transactionPaused, setTransactionPaused] = useState<boolean>(false);

  const tabs = [{ label: 'Local' }, { label: 'International' }];

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    setScrolled(event.currentTarget.scrollTop > 1);
  };

  return (
    <Container
      onScroll={(event) => handleScroll(event)}
      className={className}
    >
      <TransactionFormContainer>
        <Wrapper>
          {selectedContact && <ContactHeader contact={selectedContact} />}
          <Navigation></Navigation>
        </Wrapper>
        <ContentContainer $currentIndex={currentIndex}>
          <AddIbanSection />
        </ContentContainer>
      </TransactionFormContainer>
      {selectedContact?.id && verifiedKYC && (
        <CustomContactTransactionHistory contactId={selectedContact.id} />
      )}
    </Container>
  );
};
export default TransactionFormWrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  box-sizing: border-box;
  flex: 1 0;
  margin-left: 20px;
  margin-right: 20px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    align-self: flex-start;
    max-width: 740px;
    max-height: calc(100vh - 140px);
    ${HideScrollbar};
    margin-left: unset;
    margin-right: unset;
    min-height: 664px;
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    max-width: 880px;
  }
`;

const H4 = styled.div`
  ${FontStyles.h4};
`;

const TransactionFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: ${(props) => props.theme.palette.primary.white};
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.gradients.blueOrangeTint};
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
  padding: 20px 20px 9px 20px;
  height: 88px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 28px 28px 21px 35px;
    height: 100px;
  }
`;

const SepaLogo = styled(LogoSEPA)`
  width: 45px;
  height: auto;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: unset;
  }
`;

const SwiftLogo = styled(LogoSwift)`
  width: 31px;
  height: auto;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: unset;
  }
`;

const ContentContainer = styled.div<{ $currentIndex: number }>`
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    position: relative;
  }
`;

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.white};
  margin-top: 7.5px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 5.5px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 31px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: 45px;
  }
`;

const CustomDeleteButtonTablet = styled(DeleteButton)`
  display: none;
  min-width: 207px;
  max-width: 207px;
  min-height: 50px;
  text-align: center;
  padding: 0;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    min-width: 207px;
    max-width: 207px;
    min-height: 50px;
    text-align: center;
  }
`;

const ScheduledSetupText = styled.div`
  ${FontStyles.h5};
  color: ${(props) => props.theme.palette.greys.darkGrey};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteButtonMobile = styled.div`
  height: 34px;
  width: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: ${(props) => props.theme.palette.negative.lessContrastRed};
  border: 1px solid transparent;
  color: white;
  ${FontStyles.bodySmallGilroy};
  transition: 300ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.negative.lessContrastRed};
    background-color: transparent;
    border-color: ${(props) => props.theme.palette.negative.lessContrastRed};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const PauseButton = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.palette.negative.lessContrastRed};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.negative.lessContrastRed};
  transition: 300ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: ${(props) => props.theme.palette.negative.lessContrastRed};
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: 50px;
    height: 50px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CustomContactTransactionHistory = styled(ContactTransactionHistory)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;

const ComingSoonScreen = styled.div`
  width: 100%;
  height: 564px;
  background-image: url(${ComingSoonBackground});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ComingSoonLogo = styled(IconComingSoon)`
  height: 88px;
  width: 88px;
  padding: 16px;
  margin-bottom: 40px;
  border-radius: 50%;
  background: ${(props) => props.theme.palette.primary.white};
  box-shadow: 8px 8px 20px 0 rgba(174, 174, 192, 0.2);
`;

const ComingSoonHeadText = styled.div`
  ${FontStyles.h4};
  color: ${(props) => props.theme.palette.primary.black};
  margin-bottom: 20px;
  text-align: center;
`;

const ComingSoonSubText = styled.div`
  ${FontStyles.bodyLargeGilroy};
  color: ${(props) => props.theme.palette.primary.black};
  width: 307px;
  text-align: center;
`;
