import React from 'react';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  title: string;
  message: string;
  link?: string;
}

const Toast = ({ title, message, link }: Props) => {
  return (
    <ToastifyContainer>
      <ToastTitle>{title}</ToastTitle>
      <div>{message}</div>
      {link && <a href={link}>{link}</a>}
    </ToastifyContainer>
  );
};

export default Toast;

export const ToastifyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToastTitle = styled.div`
  ${FontStyles.h5};
`;
