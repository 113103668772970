import { css } from 'styled-components';

export const FontStyles = {
  buttonsNeuePlakType1: css`
    font-family: ${(props) => props.theme.typography.buttonsNeuePlakType1.fontFamily};
    font-weight: ${(props) => props.theme.typography.buttonsNeuePlakType1.fontWeight};
    font-size: ${(props) => props.theme.typography.buttonsNeuePlakType1.fontSize};
    line-height: ${(props) => props.theme.typography.buttonsNeuePlakType1.lineHeight};
  `,
  h1: css`
    font-family: ${(props) => props.theme.typography.h1.fontFamily};
    font-weight: ${(props) => props.theme.typography.h1.fontWeight};
    font-size: ${(props) => props.theme.typography.h1.fontSize};
    line-height: ${(props) => props.theme.typography.h1.lineHeight};
  `,
  h2: css`
    font-family: ${(props) => props.theme.typography.h2.fontFamily};
    font-weight: ${(props) => props.theme.typography.h2.fontWeight};
    font-size: ${(props) => props.theme.typography.h2.fontSize};
    line-height: ${(props) => props.theme.typography.h2.lineHeight};
  `,
  h3: css`
    font-family: ${(props) => props.theme.typography.h3.fontFamily};
    font-weight: ${(props) => props.theme.typography.h3.fontWeight};
    font-size: ${(props) => props.theme.typography.h3.fontSize};
    line-height: ${(props) => props.theme.typography.h3.lineHeight};
  `,
  h4: css`
    font-family: ${(props) => props.theme.typography.h4.fontFamily};
    font-weight: ${(props) => props.theme.typography.h4.fontWeight};
    font-size: ${(props) => props.theme.typography.h4.fontSize};
    line-height: ${(props) => props.theme.typography.h4.lineHeight};
  `,
  h5: css`
    font-family: ${(props) => props.theme.typography.h5.fontFamily};
    font-weight: ${(props) => props.theme.typography.h5.fontWeight};
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    line-height: ${(props) => props.theme.typography.h5.lineHeight};
  `,
  h6: css`
    font-family: ${(props) => props.theme.typography.h6.fontFamily};
    font-weight: ${(props) => props.theme.typography.h6.fontWeight};
    font-size: ${(props) => props.theme.typography.h6.fontSize};
    line-height: ${(props) => props.theme.typography.h6.lineHeight};
  `,
  h6Main: css`
    font-family: ${(props) => props.theme.typography.h6Main.fontFamily};
    font-weight: ${(props) => props.theme.typography.h6Main.fontWeight};
    font-size: ${(props) => props.theme.typography.h6Main.fontSize};
    line-height: ${(props) => props.theme.typography.h6Main.lineHeight};
  `,
  h7: css`
    font-family: ${(props) => props.theme.typography.h7.fontFamily};
    font-weight: ${(props) => props.theme.typography.h7.fontWeight};
    font-size: ${(props) => props.theme.typography.h7.fontSize};
    line-height: ${(props) => props.theme.typography.h7.lineHeight};
  `,
  gilroy: css`
    font-family: ${(props) => props.theme.typography.gilroy.fontFamily};
    font-weight: ${(props) => props.theme.typography.gilroy.fontWeight};
    font-size: ${(props) => props.theme.typography.gilroy.fontSize};
    line-height: ${(props) => props.theme.typography.gilroy.lineHeight};
  `,
  primary: css`
    font-family: ${(props) => props.theme.typography.primary.fontFamily};
    font-weight: ${(props) => props.theme.typography.primary.fontWeight};
    font-size: ${(props) => props.theme.typography.primary.fontSize};
    line-height: ${(props) => props.theme.typography.primary.lineHeight};
  `,
  rareGilroy: css`
    font-family: ${(props) => props.theme.typography.rareGilroy.fontFamily};
    font-weight: ${(props) => props.theme.typography.rareGilroy.fontWeight};
    font-size: ${(props) => props.theme.typography.rareGilroy.fontSize};
    line-height: ${(props) => props.theme.typography.rareGilroy.lineHeight};
  `,
  bodySmallGilroy: css`
    font-family: ${(props) => props.theme.typography.bodySmallGilroy.fontFamily};
    font-weight: ${(props) => props.theme.typography.bodySmallGilroy.fontWeight};
    font-size: ${(props) => props.theme.typography.bodySmallGilroy.fontSize};
    line-height: ${(props) => props.theme.typography.bodySmallGilroy.lineHeight};
  `,
  bodyMiddleGilroy: css`
    font-family: ${(props) => props.theme.typography.bodyMiddleGilroy.fontFamily};
    font-weight: ${(props) => props.theme.typography.bodyMiddleGilroy.fontWeight};
    font-size: ${(props) => props.theme.typography.bodyMiddleGilroy.fontSize};
    line-height: ${(props) => props.theme.typography.bodyMiddleGilroy.lineHeight};
  `,
  bodyLargeGilroy: css`
    font-family: ${(props) => props.theme.typography.bodyLargeGilroy.fontFamily};
    font-weight: ${(props) => props.theme.typography.bodyLargeGilroy.fontWeight};
    font-size: ${(props) => props.theme.typography.bodyLargeGilroy.fontSize};
    line-height: ${(props) => props.theme.typography.bodyLargeGilroy.lineHeight};
  `,
  subtitleForH1SlogansOnly: css`
    font-family: ${(props) => props.theme.typography.subtitleForH1SlogansOnly.fontFamily};
    font-weight: ${(props) => props.theme.typography.subtitleForH1SlogansOnly.fontWeight};
    font-size: ${(props) => props.theme.typography.subtitleForH1SlogansOnly.fontSize};
    line-height: ${(props) => props.theme.typography.subtitleForH1SlogansOnly.lineHeight};
  `,
  subtitleGilroy: css`
    font-family: ${(props) => props.theme.typography.subtitleGilroy.fontFamily};
    font-weight: ${(props) => props.theme.typography.subtitleGilroy.fontWeight};
    font-size: ${(props) => props.theme.typography.subtitleGilroy.fontSize};
    line-height: ${(props) => props.theme.typography.subtitleGilroy.lineHeight};
  `,
  buttonGilroyType2: css`
    font-family: ${(props) => props.theme.typography.buttonGilroyType2.fontFamily};
    font-weight: ${(props) => props.theme.typography.buttonGilroyType2.fontWeight};
    font-size: ${(props) => props.theme.typography.buttonGilroyType2.fontSize};
    line-height: ${(props) => props.theme.typography.buttonGilroyType2.lineHeight};
  `,
  buttonGilroyType3: css`
    font-family: ${(props) => props.theme.typography.buttonGilroyType3.fontFamily};
    font-weight: ${(props) => props.theme.typography.buttonGilroyType3.fontWeight};
    font-size: ${(props) => props.theme.typography.buttonGilroyType3.fontSize};
    line-height: ${(props) => props.theme.typography.buttonGilroyType3.lineHeight};
  `,
  buttonGilroyType3SmallIbanOnly: css`
    font-family: ${(props) => props.theme.typography.buttonGilroyType3SmallIbanOnly.fontFamily};
    font-weight: ${(props) => props.theme.typography.buttonGilroyType3SmallIbanOnly.fontWeight};
    font-size: ${(props) => props.theme.typography.buttonGilroyType3SmallIbanOnly.fontSize};
    line-height: ${(props) => props.theme.typography.buttonGilroyType3SmallIbanOnly.lineHeight};
  `,
  body2: css`
    font-family: ${(props) => props.theme.typography.body2.fontFamily};
    font-weight: ${(props) => props.theme.typography.body2.fontWeight};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: ${(props) => props.theme.typography.body2.lineHeight};
  `,
  buttonMedium: css`
    font-family: ${(props) => props.theme.typography.body2.fontFamily};
    font-weight: ${(props) => props.theme.typography.body2.fontWeight};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: ${(props) => props.theme.typography.body2.lineHeight};
  `,
};
