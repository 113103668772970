import hexRgb from 'hex-rgb';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IconOnboardingLock } from '@constants/icons';
import { OnboardingTopLogin, OnboardingTopRegister, OnboardingTopRestore } from '@constants/images';
import { IconLogoWhite } from '@constants/logos';

import OnboardingNavigationTopButton from '@components/OnboardingNavigationTopButton/OnboardingNavigationTopButton';

interface Props {
  variant: 'register' | 'login' | 'restore' | 'sms' | 'email' | 'change';
}

const OnboardingTopBar = ({ variant }: Props) => {
  const location = useLocation();

  const OTP = location.pathname === '/otp';
  const REGISTER = location.pathname === '/register';
  const LOGIN = location.pathname === '/login';
  const REGISTER_PASS = location.pathname === '/register/6';

  const RENDER_CONDITION = LOGIN || OTP || REGISTER || REGISTER_PASS;

  return (
    <Container className={variant}>
      {!RENDER_CONDITION && <OnboardingNavigationTopButton />}
      <LogoWrapper className={variant}>
        <CustomLogoPeanuds className={variant} />
        <CustomLogoLock className={variant} />
      </LogoWrapper>
    </Container>
  );
};
export default OnboardingTopBar;

const Container = styled.div`
  width: 100%;
  border-radius: 0 0 50px 50px;
  position: relative;
  display: flex;
  justify-content: center;

  &.sms {
    display: none;
  }

  &.register,
  &.change,
  &.email {
    min-height: 74px;
    background: image-set(url(${OnboardingTopRegister[0]}) 1x, url(${OnboardingTopRegister[1]}) 2x)
      no-repeat 30% 60% / 140% auto;
  }

  &.login {
    min-height: 155px;
    background: image-set(url(${OnboardingTopLogin[0]}) 1x, url(${OnboardingTopLogin[1]}) 2x)
      no-repeat center 15% / 290% auto;
  }

  &.restore {
    min-height: 155px;

    background: image-set(url(${OnboardingTopRestore[0]}) 1x, url(${OnboardingTopRestore[1]}) 2x)
      no-repeat 100% 60% / 140% auto;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    &.register,
    &.change,
    &.email {
      height: 130px;
      background: image-set(
          url(${OnboardingTopRegister[0]}) 1x,
          url(${OnboardingTopRegister[1]}) 2x
        )
        no-repeat 15% 45% / 140% auto;
    }

    &.login {
      height: 185px;
      background: image-set(url(${OnboardingTopLogin[0]}) 1x, url(${OnboardingTopLogin[1]}) 2x)
        no-repeat center 20% / 150% auto;
    }

    &.restore {
      height: 185px;
      background: image-set(url(${OnboardingTopRestore[0]}) 1x, url(${OnboardingTopRestore[1]}) 2x)
        no-repeat 65% 70% / 140% auto;
    }
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  width: 79px;
  height: 79px;
  position: absolute;
  bottom: -39.5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: ${({ theme }) =>
    `${hexRgb(theme.palette.greys.asphaltTint, { format: 'css', alpha: 0.47 })}`};
  backdrop-filter: blur(20px);

  &.restore {
    border: 1px solid ${({ theme }) => theme.palette.primary.white};
  }
`;

const CustomLogoPeanuds = styled(IconLogoWhite)`
  width: 53px;
  height: 53px;
  display: inline;

  &.restore {
    display: none;
  }
`;

const CustomLogoLock = styled(IconOnboardingLock)`
  width: 53px;
  height: 53px;
  display: none;

  &.restore {
    display: inline;
  }
`;
