import React, { UIEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { useGetContacts, useGetScheduledTransactions } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { Scrollbar } from '@shared/css';

import { FontStyles } from '@constants/fontStyles';
import { IconPlus } from '@constants/icons';

import useDebounce from '@hooks/useDebounce';
import useMediaQuery from '@hooks/useMediaQuery';

import NoContactsPlaceholder from '@components/NoContactsPlaceholder/NoContactsPlaceholder';
import NoScheduledPaymentsPlaceholder from '@components/NoScheduledPaymentsPlaceholder/NoScheduledPaymentsPlaceholder';
import SearchBar from '@components/SearchBar/SearchBar';
import SendMoneyContact from '@components/SendMoneyContact/SendMoneyContact';
import SendMoneyScheduledPayment from '@components/SendMoneyScheduledPayment/SendMoneyScheduledPayment';
import TabNavigation from '@components/TabNavigation/TabNavigation';

const defaultContacts: ContactResponse = {
  contacts: [],
};

const defaultScheduledTransactions: TransactionWithContact[] = [];

const ContactsListSendMoney = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);
  const isSmallDesktop = useMediaQuery(`(${breakpoints.smallDesktop})`);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const tabs = [
    { label: 'Contacts' },
    // { label: (isTablet && !isLaptop) || isSmallDesktop ? 'Scheduled Payments' : 'Scheduled' },
  ];

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const [isHeaderFadeVisible, setIsHeaderFadeVisible] = useState(false);
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const setScheduledFormMode = useBoundStore((state) => state.setScheduledFormMode);
  const selectedTransaction = useBoundStore((state) => state.selectedTransaction);
  const setSelectedTransaction = useBoundStore((state) => state.setSelectedTransaction);
  const searchQuery = useBoundStore((state) => state.searchQuery);

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const { data: contactData, isFetching } = useGetContacts(managedAccountId, searchQuery);
  const { data: scheduledTransactionsData, isFetching: isScheduledFetching } =
    useGetScheduledTransactions(managedAccountId, searchQuery);

  const contacts = contactData?.contacts ?? defaultContacts.contacts;
  const scheduledTransactions = scheduledTransactionsData ?? defaultScheduledTransactions;

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    setIsHeaderFadeVisible(scrollTop > 15);
  };

  useEffect(() => {
    if (currentIndex === 1) {
      setSelectedContact(null);
    }
  }, [currentIndex, setSelectedContact]);

  useEffect(() => {
    if (currentIndex === 0) setSelectedTransaction(null);
    if (!selectedTransaction) setScheduledFormMode(false);
  }, [currentIndex, selectedTransaction, setScheduledFormMode, setSelectedTransaction]);

  return (
    <Container $verified={verifiedKYC}>
      <HeadingContainer>
        <TabNavigation
          currentTabIndex={currentIndex}
          setCurrentTabIndex={setCurrentIndex}
          tabs={tabs}
          uniqueName="contact-list"
          topOffset={!isTablet ? 12 : 13}
        />
      </HeadingContainer>
      <Divider />
      <SearchBarContainer>
        <SearchBar />
      </SearchBarContainer>
      <HeaderWrapper>{isHeaderFadeVisible && verifiedKYC && <HeaderFade />}</HeaderWrapper>
      <ContentWrapper>
        <Content
          onScroll={(event) => handleScroll(event)}
          className={isLaptop ? 'resetButton' : ''}
        >
          {currentIndex === 0 ? (
            !verifiedKYC || (contacts.length === 0 && !isFetching) ? (
              <NoContactsPlaceholder
                show={false}
                screen="sendMoney"
                size={
                  isSmallDesktop ? 'tablet' : isLaptop ? 'mobile' : isTablet ? 'tablet' : 'mobile'
                }
                verify={!verifiedKYC}
                text={
                  !verifiedKYC
                    ? 'Verify Account and create your first transaction'
                    : 'No contacts found. Do your first transaction to create contact.'
                }
              />
            ) : (
              <ContactListContainer>
                <Wrapper className="recent">
                  <CategoryHeading>
                    {searchQuery === '' ? t('recent_contacts') : 'Results'}
                  </CategoryHeading>
                  {contacts.length < 15
                    ? contacts.map((contact: ContactsWithBankDetails, index: number) => (
                        <SendMoneyContact
                          key={index}
                          contact={contact}
                          selected={selectedContact?.id === contact.id}
                          setSelected={setSelectedContact}
                          iconVisible={index % 3 == 0}
                        />
                      ))
                    : contacts
                        .slice(0, 3)
                        .map((contact: ContactsWithBankDetails, index: number) => (
                          <SendMoneyContact
                            key={index}
                            contact={contact}
                            selected={selectedContact?.id === contact.id}
                            setSelected={setSelectedContact}
                            iconVisible={index % 3 == 0}
                          />
                        ))}
                </Wrapper>
                {contacts.length >= 15 && (
                  <Wrapper>
                    <CategoryHeading>{t('contacts')}</CategoryHeading>
                    {contacts.slice(3).map((contact: ContactsWithBankDetails, index: number) => (
                      <SendMoneyContact
                        key={index}
                        contact={contact}
                        selected={selectedContact?.id === contact.id}
                        setSelected={setSelectedContact}
                        iconVisible={index % 3 == 0}
                      />
                    ))}
                  </Wrapper>
                )}
              </ContactListContainer>
            )
          ) : !verifiedKYC || (scheduledTransactions.length === 0 && !isFetching) ? (
            <ContactListContainer>
              <NoScheduledPaymentsPlaceholder
                size={isTablet ? 'tablet' : 'mobile'}
                verify={!verifiedKYC}
                text="Verify Account to recieve access to full feature list"
              />
            </ContactListContainer>
          ) : (
            <ContactListContainer>
              <Wrapper>
                {scheduledTransactions.map((result, index) => {
                  return (
                    <SendMoneyScheduledPayment
                      key={index}
                      transactionData={result}
                    />
                  );
                })}
              </Wrapper>
            </ContactListContainer>
          )}
        </Content>
        {verifiedKYC && (
          <ButtonContainerMobile>
            <LargeButtonBlue to="/send-money/transaction-form">+ New Transaction</LargeButtonBlue>
          </ButtonContainerMobile>
        )}
        {verifiedKYC && (
          <ButtonContainerLaptop className="border">
            <ResetButton
              onClick={() => {
                setSelectedContact(null);
                setSelectedTransaction(null);
                setCurrentIndex(0);
                setScheduledFormMode(false);
              }}
            >
              New Payment
              <div className="circle">
                <IconPlus />
              </div>
            </ResetButton>
          </ButtonContainerLaptop>
        )}
      </ContentWrapper>
      <FooterWrapper className={isLaptop ? 'largeScreen' : ''}>
        {verifiedKYC && <Fade />}
      </FooterWrapper>
    </Container>
  );
};

export default ContactsListSendMoney;

const HeaderWrapper = styled.div`
  position: relative;
`;

const Container = styled.div<{ $verified: VerifiedKYCType }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 335px;
  border-radius: 10px;
  padding: 20px 7.5px 20px 20px;
  background-color: ${(props) => props.theme.palette.primary.white};
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    border-radius: 20px;
    padding: 35px 15px 35px 35px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    min-width: 359px;
    max-width: 359px;
    min-height: 743px;
    max-height: calc(100vh - 140px);
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    min-height: 688px;
    min-width: 512px;
    max-width: 512px;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
`;

const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  width: calc(100% - 20px);

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 5.5px;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  margin-top: 15px;
  box-sizing: border-box;
  width: calc(100% - 13.5px);

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 20px;
    width: calc(100% - 20px);
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    max-width: 442px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  //min-height: 600px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${Scrollbar};
  overflow: hidden auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 518px;
  height: calc(100vh - 325px);

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: calc(100vh - 365px);
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    min-height: 478px;
    height: calc(100vh - 325px);
    max-height: calc(100vh - 325px);

    &.resetButton {
      max-height: calc(100vh - 405px);
    }
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    min-height: 423px;
  }
`;

const ButtonContainerMobile = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }

  &.border {
    border-top: 1px solid ${({ theme }) => theme.palette.greys.darkGrey};
    height: 50px;
    align-items: end;
    width: calc(100% - 20px);
    align-self: start;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-end;
    align-items: center;
  }
`;

const ButtonContainerLaptop = styled(ButtonContainerMobile)`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
  }
`;

const LargeButtonBlue = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 75px;
  max-height: 50px;
  width: 100%;
  text-decoration: none;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.white};
  background: linear-gradient(145deg, #74c4ff, #1099fd, #232222, #34354a);
  background-size: 500% auto;
  transition: 300ms ease-in-out;

  &:hover {
    background-position: 90% 120%;
    cursor: pointer;
  }

  &:disabled {
    background: none;
    border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
    color: ${(props) => props.theme.palette.greys.middleGrey};
    cursor: unset;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    max-width: 300px;
  }
`;

const ContactListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  & h2:not(:first-child) {
    margin-top: 20px;
  }

  & > :not(.recent) {
    display: flex;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    & > :not(.recent) {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 10px;
    & > :not(:nth-child(-n + 4)) {
      display: flex;
    }
  }
`;

const CategoryHeading = styled.h2`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;

const ResetButton = styled.button`
  display: flex;
  box-sizing: border-box;
  gap: 15px;
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.blue};
  cursor: pointer;
  align-items: center;
  transition: 300ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
    div {
      background-color: ${(props) => props.theme.palette.primary.black};
    }
  }

  & > .circle {
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.primary.blue};
    color: ${(props) => props.theme.palette.primary.white};
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms ease-in-out;
  }
`;

const Fade = styled.div`
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 79.8%, #ffffff 100%);
  height: 40px;
  display: flex;
  width: 100%;
  z-index: 1;
  bottom: 60px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    bottom: 100%;
  }
`;

const HeaderFade = styled.div`
  position: absolute;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 79.8%, #ffffff 100%);
  height: 40px;
  display: flex;
  width: 100%;
  z-index: 1;
  top: 20px;
`;

const FooterWrapper = styled.div`
  position: relative;

  &.largeScreen {
    bottom: 60px;
  }
`;
