import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import AddIbanForm from './AddIbanForm';
import IbanList from './IbanList';

const AddIbanSection = () => {
  const selectedContact = useBoundStore((state) => state.selectedContact);
  console.log('selectedContact', selectedContact);
  return (
    <Container>
      <AddIbanForm />
      <IbanList />
    </Container>
  );
};
export default AddIbanSection;

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
`;
