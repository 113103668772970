import TablePagination from '@mui/material/TablePagination';
import React, { Dispatch, SetStateAction, useEffect } from 'react';

interface Props {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  availableEntries: number;
  visibleRows: number;
  setVisibleRows: (value: number) => void;
  offset: number;
  setOffset: (value: number) => void;
}

const PaginationMUI = ({
  setVisibleRows,
  visibleRows,
  availableEntries,
  setCurrentPage,
  currentPage,
  offset,
  setOffset,
}: Props) => {
  const totalPages = Math.ceil(availableEntries / visibleRows);

  useEffect(() => {
    if (availableEntries === 0 || availableEntries === 1) setOffset(0);
    else setOffset(visibleRows * (currentPage - 1));
  }, [availableEntries, visibleRows, currentPage, setOffset]);

  useEffect(() => {
    setCurrentPage(1);
  }, [setCurrentPage, visibleRows]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setVisibleRows(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage + 1);
  };
  return (
    <TablePagination
      component="div"
      count={availableEntries}
      page={currentPage - 1}
      onPageChange={handleChangePage}
      rowsPerPage={visibleRows}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default PaginationMUI;
