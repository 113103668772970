import { friendlyFormatIBAN } from 'ibantools';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';

const IbanList = () => {
  const selectedContact = useBoundStore((state) => state.selectedContact);

  return (
    <Container>
      <Label>Saved IBAN’s</Label>
      <List>
        {selectedContact?.bankDetails?.map((bank, i) => {
          return (
            <ListItem key={i}>
              <span>{friendlyFormatIBAN(bank.iban)}</span>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};
export default IbanList;

const Container = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;
const List = styled.div`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;
const Label = styled.div`
  ${FontStyles.bodyMiddleGilroy};
`;
const ListItem = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  font-family: ${(props) => props.theme.typography.primary.fontFamily};
`;
