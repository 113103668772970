import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    padding: 10px 40px 27px 40px;
  }
`;
