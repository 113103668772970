import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconOnboardingCheck } from '@constants/icons';

import OnboardingCountryInput from '@components/OnboardingCountryInput/OnboardingCountryInput';
import OnboardingStepNavigation from '@components/OnboardingStepNavigation/OnboardingStepNavigation';

import LargeButton from '@elements/LargeButton/LargeButton';
import TextInput, { Field } from '@elements/input/TextInput/TextInput';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
  padding: 65px 25px;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: unset;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 15px 25px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 31px 50px 0 95px;
  }
`;

export const CustomStepNavigation = styled(OnboardingStepNavigation)`
  align-self: center;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: start;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
`;

export const Heading = styled.p`
  ${FontStyles.h3};
  width: 100%;
  text-align: center;
  display: flex;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 35px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 20px;
    margin-top: 35px;
    align-self: start;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    margin-bottom: 15px;
    margin-top: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h3};
    margin-bottom: 20px;
  }
`;

export const SubHeadline = styled.p`
  display: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    align-self: start;
    ${FontStyles.bodyMiddleGilroy};
    color: ${(props) => props.theme.palette.greys.darkestGrey};
    margin-bottom: 38px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
    margin-bottom: 30px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    margin-bottom: 45px;
  }
`;

export const Label = styled.label`
  ${FontStyles.h5};
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const CustomTextInput = styled(TextInput)`
  width: 100%;
  ${FontStyles.bodyMiddleGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    ${FontStyles.bodySmallGilroy};
    ${Field} {
      ${FontStyles.bodySmallGilroy};
    }
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    ${Field} {
      ${FontStyles.bodyMiddleGilroy};
    }
    height: 50px;
  }
  & ::placeholder {
    ${FontStyles.body2};
    ${Field} {
      ${FontStyles.body2};
    }
  }
`;

export const CustomIconOnboardingCheck = styled(IconOnboardingCheck)`
  width: 17px;
  height: 15px;
`;

export const ErrorContainer = styled.div<{ $visible: boolean }>`
  ${FontStyles.bodySmallGilroy};
  height: 25px;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0 0 0;
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  opacity: ${(props) => (props.$visible ? '1' : '0')};
  justify-content: start;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 20px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 25px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: end;
    margin-top: 40px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-top: 56.5px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    margin-top: 52px;
  }
`;

export const CustomLargeButton = styled(LargeButton)`
  transition: unset;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    font-size: 14px;

    @media (${(props) => props.theme.breakpoints.desktop}) {
      height: 50px;
      font-size: 16px;
    }
`;

export const CustomOnboardingCountryInput = styled(OnboardingCountryInput)`
  margin-bottom: 30px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: unset;
  }
`;

export const SubLabel = styled.div`
  display: flex;
  align-items: center;
  ${FontStyles.bodySmallGilroy};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
`;
