import React from 'react';
import styled, { css } from 'styled-components';

const Status = ({ status }: { status: string }) => {
  let className = 'approved';
  switch (status) {
    case 'INVALID':
    case 'CANCELLED':
      className = 'warning';
      break;
    case 'PENDING_CHALLENGE':
      className = 'warningLight';
      break;
    case 'REJECTED':
    case 'FAILED':
      className = 'error';
      break;
    case 'RETURNED':
      className = 'returned';
      break;
  }

  // 'INVALID' |
  //   'PENDING_CHALLENGE' |
  //   'SUBMITTED' |
  //   'REJECTED' |
  //   'APPROVED' |
  //   'FAILED' |
  //   'COMPLETED' |
  //   'RETURNED' |
  //   'SCHEDULED' |
  //   'CANCELLED';
  return (
    <StatusField
      $status={status}
      className={className}
    >
      <span>{status}</span>
    </StatusField>
  );
};

export default Status;
export const StatusField = styled.div<{ $status?: string }>`
  display: flex;

  justify-content: end;
  align-items: center;
  padding-right: 0;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  height: 45px;
  text-transform: lowercase;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding-right: 35px;
    justify-content: start;
    height: 50px;
    min-width: 240px;
  }

  &.approved {
    color: ${({ theme }) => theme.palette.primary.blue};
  }
  &.warning {
    color: ${({ theme }) => theme.palette.warning.main};
  }
  &.warningLight {
    color: ${({ theme }) => theme.palette.warning.light};
  }
  &.error {
    color: ${({ theme }) => theme.palette.error.main};
  }
  &.returned {
    color: ${({ theme }) => theme.palette.success.light};
  }
  & span {
    ${({ $status }) =>
      ($status === 'Completed' || $status === 'Authorized') &&
      css`
        color: ${({ theme }) => theme.palette.primary.blue};
      `}

    ${({ $status }) =>
      ($status === 'Cancelled' ||
        $status === 'Failed' ||
        $status === 'Reversed' ||
        $status === 'Hold' ||
        $status === 'Disputed') &&
      css`
        color: ${({ theme }) => theme.palette.negative.contrastRed};
      `}

    ${({ $status }) =>
      $status === 'Pending' &&
      css`
        background: ${({ theme }) => theme.palette.gradients.goldishGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      `}
  }
`;
export const StatusHeader = styled.div`
  min-width: 240px;
  display: flex;
  align-items: center;
`;

// "INVALID  PENDING_CHALLENGE SUBMITTED REJECTED APPROVED FAILED COMPLETED RETURNED SCHEDULED CANCELLED "
