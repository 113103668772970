import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetCorporate, useGetKYB, useStartKYB } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import {
  IconStatusBar,
  IconStatusBarBlue,
  IconStatusBarStar,
  IconStatusBarStarBlue,
} from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import EmailModal from '@components/EmailModal/EmailModal';
import OTPModal from '@components/OTPModal/OTPModal';
import {
  Container,
  CustomIconSettingsLock,
  CustomIconVerify,
  CustomLargeButtonLink,
  CustomLargeButtonLinkTablet,
  ProgressContainer,
  StatusBar,
  TextContainer,
  Top,
} from '@components/Settings/SettingsKYC/SettingsKYC.styles';

const SettingsKYC = () => {
  const navigate = useNavigate();

  const openModal = useBoundStore((state) => state.openModal);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const {
    data: corporate,
    isError: isGetCorporateError,
    error: getCorporateError,
  } = useGetCorporate();
  const { data: kybData, isError: isGetKYBError, error: getKYBError } = useGetKYB();

  const { mutate: startCorporateKybMutation } = useStartKYB();

  const { handleHTTPErrors } = useHTTPErrorHandler();

  const emailVerified = corporate?.rootUser.emailVerified ?? false;
  const mobileNumberVerified = corporate?.rootUser.mobileNumberVerified ?? false;

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (isGetKYBError && getKYBError) {
      errorsToHandle.push(getKYBError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [getCorporateError, getKYBError, handleHTTPErrors, isGetCorporateError, isGetKYBError]);

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (submitting) return;

    setSubmitting(true);

    if (!emailVerified) {
      openModal(<EmailModal />);
      setSubmitting(false);
    } else if (!mobileNumberVerified) {
      openModal(<OTPModal />);
      setSubmitting(false);
    } else {
      startCorporateKybMutation(undefined, {
        onSuccess: (data) => {
          if (data) {
            const { reference } = data;
            navigate('/kyb', { state: { kybRef: reference } });
          }
        },
        onError: () => {
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container>
      <Top>
        <ProgressContainer>
          {!emailVerified || !mobileNumberVerified ? (
            emailVerified ? (
              <CustomIconSettingsLock />
            ) : (
              <CustomIconVerify />
            )
          ) : checkIfKybInitiated() ? (
            <StatusBar>
              <IconStatusBarStar />
              <IconStatusBar />
            </StatusBar>
          ) : (
            <StatusBar>
              <IconStatusBarStarBlue />
              <IconStatusBarBlue />
            </StatusBar>
          )}
        </ProgressContainer>
        <CustomLargeButtonLinkTablet
          disabled={submitting}
          onClick={handleButtonClick}
          text={
            !emailVerified
              ? 'Verify Email'
              : !mobileNumberVerified
              ? 'Get Started'
              : checkIfKybInitiated()
              ? 'View Status'
              : 'Verify organization'
          }
          variant="blueBlack"
        />
      </Top>
      <TextContainer>
        <div>
          {!emailVerified
            ? 'Verify your email address'
            : !mobileNumberVerified
            ? 'Enable 2-factor authentication'
            : checkIfKybInitiated()
            ? 'Verification process initiated'
            : 'Start your verification process'}
        </div>
        {!emailVerified
          ? 'Complete your account setup by verifying your email address.'
          : !mobileNumberVerified
          ? 'Please confirm your phone number to finalize your Registration.'
          : checkIfKybInitiated()
          ? 'We are currently processing the documents you provided.'
          : 'To get started you need to verify your organisation.'}
      </TextContainer>
      <CustomLargeButtonLink
        disabled={submitting}
        onClick={handleButtonClick}
        text={
          !emailVerified
            ? 'Verify Email'
            : !mobileNumberVerified
            ? 'Get Started'
            : checkIfKybInitiated()
            ? 'View Status'
            : 'Verify organization'
        }
        variant="blueBlack"
      />
    </Container>
  );
};
export default SettingsKYC;
