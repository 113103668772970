import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconArrowDown } from '@constants/icons';

interface Props {
  options: SelectOption[];
  setFilter: (value: string | undefined) => void;
}

const RadioSelectBox = ({ options, setFilter }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>(options[0].text);

  const { t } = useTranslation();

  const handleClick = (input: { text: string; value: string }) => {
    setValue(input.text);
    setIsVisible(false);
    if (value === options[0].text) setFilter(undefined);
    else setFilter(input.value);
  };

  const handleVisibleChange = (value: boolean) => {
    setIsVisible(value);
  };

  return (
    <Container onMouseOut={() => handleVisibleChange(false)}>
      <Selection
        tabIndex={0}
        onFocus={() => handleVisibleChange(true)}
        onMouseOver={() => handleVisibleChange(true)}
      >
        <Trans t={t}>{value}</Trans>
        <Icon $isVisible={isVisible} />
      </Selection>
      <DropDown
        $isVisible={isVisible}
        tabIndex={0}
        onFocus={() => handleVisibleChange(true)}
        onBlur={() => handleVisibleChange(false)}
        onMouseOver={() => handleVisibleChange(true)}
      >
        {options.map((option, index) => {
          return (
            <RadioButtonContainer
              tabIndex={0}
              key={index}
              onClick={() => handleClick(option)}
            >
              <Label
                htmlFor={option.value}
                className={value === option.text ? 'active' : ''}
              >
                <RadioButton
                  type="radio"
                  checked={value === option.text}
                  onChange={() => handleClick(option)}
                  value={option.value}
                  id={option.value}
                />
                {option.text}
              </Label>
            </RadioButtonContainer>
          );
        })}
      </DropDown>
    </Container>
  );
};

export default RadioSelectBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const Selection = styled.div`
  ${FontStyles.bodySmallGilroy};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 20px 11px 15px;
  border-radius: 40px;
  border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  transition: all 300ms;
  gap: 10px;

  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.palette.greys.darkestGrey};
  }
`;

const DropDown = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  top: 42px;
  display: ${(props) => (props.$isVisible ? 'inline-block' : 'none')};
  padding: 20px 18px;
  background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  border-radius: 10px;
  filter: drop-shadow(0 2px 14px rgba(0, 0, 0, 0.07));
  transition: ease-in-out 300ms;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  min-width: fit-content;
  z-index: 1;
`;

const Icon = styled(IconArrowDown)<{ $isVisible: boolean }>`
  transition: ease-in-out 300ms;
  transform: ${(props) => props.$isVisible && 'scaleY(-1)'};
`;

const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  margin-top: 13px;

  &:hover > * {
    color: ${(props) => props.theme.palette.primary.black};
    border-color: ${(props) => props.theme.palette.primary.black};
  }
  &:first-child {
    margin-top: initial;
  }
`;

const RadioButton = styled.input`
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 0;
  transition: 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: inherit;
  flex-shrink: 0;

  &:after {
    content: '';
    display: flex;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.black};
    &:after {
      transition: 300ms ease-in-out;
      background-color: ${(props) => props.theme.palette.primary.black};
    }
  }

  &:checked {
    border-color: ${(props) => props.theme.palette.primary.blue};
    &:after {
      background-color: ${(props) => props.theme.palette.primary.blue};
    }
  }
`;

const Label = styled.label`
  ${FontStyles.bodySmallGilroy};
  padding-left: 5px;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  display: flex;
  cursor: inherit;
  transition: 300ms ease-in-out;
  white-space: nowrap;
  gap: 10px;

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }

  &.active {
    color: ${(props) => props.theme.palette.primary.blue};
  }
`;
