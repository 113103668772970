import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconArrowLeft } from '@constants/icons';

import IconButton from '@elements/IconButton/IconButton';

interface Props {
  className?: string;
  text: string;
  to?: string;
}
const OnboardingStepNavigation = ({ className, text, to }: Props) => {
  const navigate = useNavigate();

  const navigateHandler = (to?: string) => {
    if (to) navigate(to);
    else navigate(-1);
  };

  return (
    <Container className={className}>
      <CustomIconButton
        icon={<IconArrowLeft />}
        onClick={() => navigateHandler(to)}
      />
      <Text>{text}</Text>
    </Container>
  );
};

export default OnboardingStepNavigation;

const Container = styled.div`
  display: none;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    gap: 17px;
  }
`;

const Text = styled.div`
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  ${FontStyles.h5};
`;

const CustomIconButton = styled(IconButton)`
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  border: 1px solid ${({ theme }) => theme.palette.greys.darkGrey};
  cursor: pointer;
  transition: color, background-color 300ms ease-in-out;

  &:hover,
  &:focus {
    color: white;
    background-color: ${({ theme }) => theme.palette.greys.darkGrey};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 36px;
    height: 36px;
    ${FontStyles.h6};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    width: 44px;
    height: 44px;
    ${FontStyles.h5};
  }
`;
