import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { GlobalReset } from '@constants/reset';

import { theme } from '@theme/muiTheme';
import { LightTheme } from '@theme/theme';

import ModalOutlet from '@components/ModalOutlet/ModalOutlet';
import NotificationPopup from '@components/NotificationPopup/NotificationPopup';
import SupportChat from '@components/SupportChat/SupportChat';

import ScrollToTop from '@elements/ScrollToTop/ScrollToTop';

import './fonts.css';
import './i18n';
import Routes from './routes/Routes';

const App = () => {
  const loadWeavrUiComponentLibrary = useBoundStore((state) => state.loadWeavrUiComponentLibrary);
  const isWeavrUiComponentLibraryInitialized = useRef(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });

  useEffect(() => {
    if (!isWeavrUiComponentLibraryInitialized.current) {
      loadWeavrUiComponentLibrary();
      isWeavrUiComponentLibraryInitialized.current = true;
    }
  }, [loadWeavrUiComponentLibrary]);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <MUIThemeProvider theme={theme}>
          <ThemeProvider theme={LightTheme}>
            <GlobalReset />
            <QueryClientProvider client={queryClient}>
              <SupportChat />
              <Routes />
              <ModalOutlet />
              <NotificationPopup />
              <ToastContainer
                autoClose={10000}
                position="top-left"
              />
            </QueryClientProvider>
          </ThemeProvider>
        </MUIThemeProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
