import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useGetCorporate, useGetKYB, useUpdateCorporate } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { FormValidation } from '@shared/formValidation';

import { IconOnboardingCheck } from '@constants/icons';

import { useHTTPErrorHandler } from '@hooks/useHTTPErrorHandler';

import ChangeDetailsModal from '@components/ChangeDetailsModal/ChangeDetailsModal';
import ChangePasswordModal from '@components/ChangePasswordModal';
import EmailModal from '@components/EmailModal/EmailModal';
import {
  ButtonWrapper,
  ChangePasswordButton,
  ChangePasswordFieldButton,
  ChangeWrapper,
  Container,
  Content,
  CustomPhonePrefixInput,
  FieldContainer,
  FieldRow,
  PasswordFieldContainer,
  PhoneFieldContainer,
  PhoneWrapper,
  RequestButton,
  SaveChangesButton,
  TitleBar,
  TitleCheckPricing,
  TitleWrapper,
} from '@components/Settings/SettingsUserDetails/SettingsUserDetails.styles';

import Toast from '@elements/Toast/Toast';
import Input from '@elements/input/Input/Input';

const SettingsUserDetails = () => {
  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const {
    data: corporate,
    isError: isGetCorporateError,
    error: getCorporateError,
  } = useGetCorporate();
  const { data: kybData, isError: isGetKYBError, error: getKYBError } = useGetKYB();

  const { mutate: updateCorporateMutation } = useUpdateCorporate();

  const { handleHTTPErrors } = useHTTPErrorHandler();

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (isGetKYBError && getKYBError) {
      errorsToHandle.push(getKYBError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [getCorporateError, getKYBError, handleHTTPErrors, isGetCorporateError, isGetKYBError]);

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  const openModal = useBoundStore((state) => state.openModal);
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const setExternalId = useBoundStore((state) => state.setExternalId);

  const [editable, setEditable] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [phonePrefixValid, setPhonePrefixValid] = useState<boolean>(true);
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(true);

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phonePrefix, setPhonePrefix] = useState<string>('');

  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [currentLastName, setCurrentLastName] = useState<string>('');
  const [currentFirstName, setCurrentFirstName] = useState<string>('');
  const [currentPrefix, setCurrentPrefix] = useState<string>('');
  const [currentPhone, setCurrentPhone] = useState<string>('');

  useEffect(() => {
    const errorsToHandle = [];

    if (isGetCorporateError && getCorporateError) {
      errorsToHandle.push(getCorporateError);
    }

    if (errorsToHandle.length > 0) {
      handleHTTPErrors(errorsToHandle);
    }
  }, [getCorporateError, handleHTTPErrors, isGetCorporateError]);

  useEffect(() => {
    if (!editable) {
      setEmail(corporate.rootUser.email);
      setCurrentEmail(corporate.rootUser.email);
      setFirstName(corporate.rootUser.name);
      setCurrentFirstName(corporate.rootUser.name);
      setLastName(corporate.rootUser.surname);
      setCurrentLastName(corporate.rootUser.surname);
      setPhoneNumber(corporate.rootUser.mobile.number);
      setCurrentPhone(corporate.rootUser.mobile.number);
      setPhonePrefix(corporate.rootUser.mobile.countryCode);
      setCurrentPrefix(corporate.rootUser.mobile.countryCode);
    }
  }, [
    corporate.rootUser.email,
    corporate.rootUser.mobile.countryCode,
    corporate.rootUser.mobile.number,
    corporate.rootUser.name,
    corporate.rootUser.surname,
    editable,
  ]);

  useEffect(() => {
    setExternalId(corporate.rootUser.id.id);
  }, [corporate.rootUser.id.id, setExternalId]);

  const handleSaveChanges = () => {
    if (submitting) return;

    setSubmitting(true);

    setEditable(false);

    const updateObject: WeavrUpdatedCorporateData = {
      ...(currentFirstName !== firstName &&
        firstName !== '' && {
          firstName: firstName,
        }),
      ...(currentLastName !== lastName &&
        lastName !== '' && {
          lastName: lastName,
        }),
      ...(currentEmail !== email &&
        emailValid && {
          email: email,
        }),
      ...((currentPhone !== phoneNumber || phonePrefix !== currentPrefix) &&
        phonePrefixValid &&
        phoneNumberValid && {
          phone: {
            phone: phoneNumber,
            phonePrefix: phonePrefix,
          },
        }),
    };

    if (Object.keys(updateObject).length === 0) {
      setSubmitting(false);
      return;
    }

    updateCorporateMutation(updateObject, {
      onSuccess: async () => {
        if (updateObject.email) {
          openModal(<EmailModal updatedMail={email} />);
        }
        toast.success(
          <Toast
            title="User Details updated"
            message="Your data has been successfully updated."
          />
        );
        await queryClient.invalidateQueries({
          queryKey: ['getCorporate'],
          exact: true,
          refetchType: 'active',
        });
        setSubmitting(false);
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status === 409) {
              const { errorCode } = data;
              if (errorCode === 'MOBILE_NO_CHANGE_LIMIT_EXCEEDED') {
                toast.error(
                  <Toast
                    title="Phone Number not updated"
                    message="Mobile number change limit exceeded, please contact our support."
                  />
                );

                delete updateObject.phone;
                updateCorporateMutation(updateObject, {
                  onSuccess: async () => {
                    toast.success(
                      <Toast
                        title="User Details updated"
                        message="Your data has been successfully updated."
                      />
                    );
                    await queryClient.invalidateQueries({
                      queryKey: ['getCorporate'],
                      exact: true,
                      refetchType: 'active',
                    });
                  },
                  onError: (error) => {
                    console.error('error after second try', error);
                  },
                });
              }
            }
          }
        }
        setSubmitting(false);
      },
    });
  };

  return (
    <Container>
      <TitleBar>
        <TitleWrapper>
          User Details
          <TitleCheckPricing />
        </TitleWrapper>
        {verifiedKYC || checkIfKybInitiated() ? (
          <RequestButton onClick={() => openModal(<ChangeDetailsModal />)}>
            Request change of details
          </RequestButton>
        ) : (
          <ChangeWrapper>
            <RequestButton
              disabled={submitting}
              onClick={() => setEditable((prevState) => !prevState)}
            >
              {editable ? 'Cancel' : 'Change Details'}
            </RequestButton>
            {editable && (
              <SaveChangesButton
                disabled={submitting}
                onClick={handleSaveChanges}
                text="Save Changes"
                variant="blueWhite"
              />
            )}
          </ChangeWrapper>
        )}
      </TitleBar>
      <Content>
        <FieldRow>
          <FieldContainer>
            Company Name
            <Input
              placeholder="-"
              onChange={() => undefined}
              locked={true}
              value={corporate.company.name}
            />
          </FieldContainer>
          <FieldContainer>
            Email
            <Input
              placeholder="-"
              locked={!editable}
              type="email"
              autoComplete="do-not-autofill"
              icon={emailValid && email !== '' && <IconOnboardingCheck />}
              value={email}
              valid={emailValid}
              onChange={(event) => {
                setEmail(event.target.value);
                FormValidation.validateField(
                  event.target.value,
                  FormValidation.RegEx.EMAIL,
                  setEmailValid
                );
              }}
            />
          </FieldContainer>
        </FieldRow>
        <FieldRow>
          <FieldContainer>
            First Name
            <Input
              placeholder="-"
              onChange={(event) => setFirstName(event.target.value)}
              locked={!editable}
              value={firstName}
            />
          </FieldContainer>
          <FieldContainer>
            Last Name
            <Input
              placeholder="-"
              onChange={(event) => setLastName(event.target.value)}
              locked={!editable}
              value={lastName}
            />
          </FieldContainer>
        </FieldRow>
        <FieldRow>
          <ButtonWrapper>
            {verifiedKYC || checkIfKybInitiated() ? (
              <RequestButton onClick={() => openModal(<ChangeDetailsModal />)}>
                Request change of details
              </RequestButton>
            ) : (
              <ChangeWrapper>
                {editable && (
                  <SaveChangesButton
                    disabled={submitting}
                    onClick={handleSaveChanges}
                    text="Save Changes"
                    variant="blueWhite"
                  />
                )}
                <RequestButton
                  disabled={submitting}
                  onClick={() => setEditable((prevState) => !prevState)}
                >
                  {editable ? 'Cancel' : 'Change Details'}
                </RequestButton>
              </ChangeWrapper>
            )}
          </ButtonWrapper>
          <PasswordFieldContainer>
            Password
            <ChangePasswordFieldButton onClick={() => openModal(<ChangePasswordModal />)}>
              ********
            </ChangePasswordFieldButton>
            <ChangePasswordButton onClick={() => openModal(<ChangePasswordModal />)}>
              Change Password
            </ChangePasswordButton>
          </PasswordFieldContainer>
          <PhoneFieldContainer>
            Phone Number
            <PhoneWrapper>
              <CustomPhonePrefixInput
                locked={!editable}
                onChange={(event) => setPhonePrefix(event.target.value)}
                setPrefixValidated={setPhonePrefixValid}
                value={phonePrefix}
              />
              <Input
                placeholder="-"
                onChange={(event) => {
                  FormValidation.validateField(
                    event.target.value,
                    FormValidation.RegEx.PHONE_NUMBER,
                    setPhoneNumberValid
                  );
                  setPhoneNumber(event.target.value);
                }}
                locked={!editable}
                value={phoneNumber}
              />
            </PhoneWrapper>
          </PhoneFieldContainer>
        </FieldRow>
      </Content>
    </Container>
  );
};

export default SettingsUserDetails;
