import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import Captcha from '@components/Captcha/Captcha';
import PeanudsLogo from '@components/PeanudsLogo/PeanudsLogo';

import LargeButton from '@elements/LargeButton/LargeButton';
import TextInput, { Field } from '@elements/input/TextInput/TextInput';

export const CustomLargeButton = styled(LargeButton)`
  transition: unset;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    padding: 8px 38px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 15px 40px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 40px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    gap: 48.33px;
  }
`;

export const OnboardingLogo = styled(PeanudsLogo)`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: initial;
    width: 90px;
    align-self: end;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    width: 110px;
  }
`;

export const OuterContainer = styled.div`
  display: flex;
  align-items: start;
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  margin-top: 50px;
  padding: 0 20px 0 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: unset;
    height: unset;
    width: 100%;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    align-items: center;
    width: 100%;
    padding: 0 25px 0 60px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    padding: 0 50px 0 95px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    height: unset;
  }
`;

export const Title = styled.div`
  ${FontStyles.h3};
  text-align: center;
  margin-bottom: 25px;
  width: 250px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: start;
    margin-bottom: 20px;
    width: initial;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.h4};
    margin-bottom: 15px;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.h3};
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.div`
  display: none;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    align-self: start;
    ${FontStyles.bodyMiddleGilroy};
    margin-bottom: 55px;
    color: ${({ theme }) => theme.palette.greys.darkestGrey};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 45px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${FontStyles.bodySmallGilroy};
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    margin-bottom: 51px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    align-self: start;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  gap: 25px;
  align-items: center;
  max-width: 197px;
  margin: 0 0 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin: unset;
    flex-direction: row;
    align-items: initial;
    gap: unset;
    justify-content: space-between;
    max-width: unset;
  }
`;

export const AccountWrapper = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: start;
    margin-bottom: unset;
  }
`;

export const HaveAnAccount = styled.div`
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  ${FontStyles.bodySmallGilroy};
  white-space: nowrap;
`;

export const LoginText = styled(NavLink)`
  color: ${(props) => props.theme.palette.primary.blue};
  ${FontStyles.buttonGilroyType3};
  text-decoration: none;
  cursor: pointer;
  transition: 300ms ease-in-out;

  &:hover,
  &:focus {
    border: none;
    outline: none;
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Label = styled.label<{ color?: 'white' }>`
  ${FontStyles.h5};
  color: ${(props) =>
    props.color ? props.theme.palette.primary.white : props.theme.palette.primary.black};
  height: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: end;
`;

export const CustomTextInput = styled(TextInput)`
  width: 100%;
  ${FontStyles.bodyMiddleGilroy};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 40px;
    ${FontStyles.bodySmallGilroy};
    ${Field} {
      ${FontStyles.bodySmallGilroy};
    }
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    ${FontStyles.bodyMiddleGilroy};
    ${Field} {
      ${FontStyles.bodyMiddleGilroy};
    }
    height: 50px;
  }
  & ::placeholder {
    ${FontStyles.body2};
    ${Field} {
      ${FontStyles.body2};
    }
  }
`;

export const FirstNameErrorContainer = styled.div<{ $visible: boolean }>`
  ${FontStyles.bodySmallGilroy};
  height: 25px;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0 0 0;
  color: ${({ theme }) => theme.palette.negative.lessContrastRed};
  display: flex;
  opacity: ${(props) => (props.$visible ? '1' : '0')};
  justify-content: end;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

export const LastNameErrorContainer = styled(FirstNameErrorContainer)`
  margin: 5px 0 5px 0;
  height: 30px;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin: 5px 0 5px 0;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    height: 25px;
    margin: 5px 0 0 0;
  }

  @media (${(props) => props.theme.breakpoints.desktop}) {
    height: 30px;
    margin: 5px 0 5px 0;
  }
`;

export const EmailErrorContainer = styled(FirstNameErrorContainer)`
  height: 30px;
  margin: 5px 0 0 0;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin: 5px 0 5px 0;
  }
`;

export const SwitchArea = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 62px;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: 15px;
  }
`;

export const SwitchText = styled.div`
  ${FontStyles.bodySmallGilroy};
  display: flex;
  flex-wrap: wrap;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.blue};
  cursor: pointer;
  transition: 300ms ease-in-out;
  display: inline-block;

  &:hover,
  &:focus {
    outline: none;
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export const CustomCaptcha = styled(Captcha)`
  margin-bottom: 35px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 0;
  }
  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-top: 20px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: column;
    margin-top: 40px;
  }
`;

export const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: row;
    gap: 30px;
    margin-bottom: 30px;
  }
`;
