import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetCorporate, useGetKYB, useStartKYB } from '@api/Corporate/corporateApi';

import { useBoundStore } from '@stores/BoundStore';

import { IconLargePlus } from '@constants/icons';

import EmailModal from '@components/EmailModal/EmailModal';
import {
  Circle,
  Container,
  Text,
} from '@components/NoContactsPlaceholder/NoContactsPlaceholder.styles';
import OTPModal from '@components/OTPModal/OTPModal';

import LargeButton from '@elements/LargeButton/LargeButton';

interface Props {
  screen: 'dashboard' | 'sendMoney';
  size: 'mobile' | 'tablet';
  verify?: boolean;
  text: string;
  show?: boolean;
}

const NoContactsPlaceholder = ({ screen, size, verify, text, show }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openModal = useBoundStore((state) => state.openModal);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: corporateData } = useGetCorporate();
  const { data: kybData } = useGetKYB();

  const { mutate: startCorporateKybMutation } = useStartKYB();

  const checkIfKybInitiated = () => {
    if (kybData) {
      return (
        kybData.kybStatus === 'INITIATED' ||
        kybData.kybStatus === 'INITIALIZED' ||
        kybData.kybStatus === 'REJECTED'
      );
    } else {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (submitting) return;

    setSubmitting(true);

    if (!corporateData) {
      setSubmitting(false);
      return;
    }

    const { rootUser } = corporateData;
    const { emailVerified, mobileNumberVerified } = rootUser;

    if (!emailVerified) {
      openModal(<EmailModal />);
      setSubmitting(false);
    } else if (!mobileNumberVerified) {
      openModal(<OTPModal />);
      setSubmitting(false);
    } else {
      startCorporateKybMutation(undefined, {
        onSuccess: (data) => {
          if (data) {
            const { reference } = data;
            navigate('/kyb', { state: { kybRef: reference } });
          }
          setSubmitting(false);
        },
        onError: () => {
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container
      $show={show}
      size={size}
      $screen={screen}
      $verify={verify}
    >
      {screen === 'dashboard' && (
        <Circle size={size}>
          <IconLargePlus />
        </Circle>
      )}
      <Text
        $screen={screen}
        size={size}
        $verify={verify}
      >
        <Trans t={t}>{text}</Trans>
      </Text>
      {screen === 'sendMoney' && verify === true && (
        <LargeButton
          disabled={submitting}
          onClick={handleButtonClick}
          text={checkIfKybInitiated() ? 'View Status' : t('verify_account')}
          variant="transparentBlack"
          style={{ maxHeight: '50px' }}
        />
      )}
    </Container>
  );
};

export default NoContactsPlaceholder;
