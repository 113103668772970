import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { IconRotatedPlus } from '@constants/icons';

import IconButton from '@elements/IconButton/IconButton';

const MobileTimer = () => {
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const closeModal = useBoundStore((state) => state.closeModal);
  const timeLeft = useBoundStore((state) => state.timeLeft);
  const setTimer = useBoundStore((state) => state.setTimer);
  const setTimeLeft = useBoundStore((state) => state.setTimeLeft);
  const setHidden = useBoundStore((state) => state.setHidden);
  const setEnter = useBoundStore((state) => state.setEnter);

  const handleAbort = () => {
    setTimer(false);
    setTimeLeft(300);
    setHidden(true);
    setEnter(false);
    closeModal();
  };
  return (
    <Container>
      <TextContainer>
        <div>Card</div>
        <div>Timer</div>
      </TextContainer>
      <TimerBox>
        <Timer>{formatTime(timeLeft)}</Timer>
        <CancelTimerIconButton
          onClick={() => handleAbort()}
          icon={<IconRotatedPlus />}
        />
      </TimerBox>
    </Container>
  );
};

export default MobileTimer;

const CancelTimerIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.white};
  background-color: ${(props) => props.theme.palette.negative.lessContrastRedLight};

  &:hover {
    background-color: ${(props) => props.theme.palette.negative.lessContrastRed};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(3.5px);
  background: linear-gradient(white, white) padding-box,
    ${(props) => props.theme.palette.gradients.tintRoseGradient} border-box;
  border: 1px solid transparent;
  border-radius: 0 20px 20px 0;
  border-left: 0;
  width: 233px;
  padding: 15px 16px;
`;

const TextContainer = styled.div`
  ${FontStyles.buttonsNeuePlakType1};
  display: flex;
  flex-direction: column;
`;

const Timer = styled.div`
  ${FontStyles.buttonGilroyType2};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  color: ${(props) => props.theme.palette.greys.darkGrey};
  background: ${(props) => props.theme.palette.gradients.primaryBlackGradient};
  border-radius: 20px;
`;

const TimerBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
