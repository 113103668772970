import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

import CheckPricing from '@components/CheckPricing/CheckPricing';
import OnboardingCountryInput from '@components/OnboardingCountryInput/OnboardingCountryInput';

import LargeButton from '@elements/LargeButton/LargeButton';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: calc(100vw - 40px);
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: calc(100vw - 48px);
    margin-bottom: 60px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    gap: 30px;
    padding: 30px 35px;
    width: 100%;
    min-height: 882px;
    margin-bottom: 0;
  }
`;

export const TitleBar = styled.div`
  box-sizing: border-box;
  background: linear-gradient(262deg, #e8e2db 8.5%, #d6dbf3 101.62%);
  color: ${(props) => props.theme.palette.primary.black};
  ${FontStyles.h4};
  padding: 33px 35px 33px 25px;
  border-radius: 20px;
  max-height: 100px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: none;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
  }
`;

export const RequestButton = styled.button`
  display: flex;
  box-sizing: border-box;
  transition: 300ms ease-in-out;
  color: ${(props) => props.theme.palette.primary.black};
  padding: 8px 20px;
  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.palette.primary.black};
  ${FontStyles.bodySmallGilroy};
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.palette.primary.black};
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: initial;
  }
`;

export const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 20px;
  width: 100%;
  flex-direction: column;
  align-items: start;
`;

export const FieldContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 10px;
  ${FontStyles.h5};
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

export const FieldRow = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 10px;
  ${FontStyles.h5};
  width: 100%;
  flex-direction: column;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: initial;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 15px;
  gap: 20px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    gap: 0;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: none;
  }
`;

export const TitleWrapper = styled.div`
  color: ${(props) => props.theme.palette.primary.black};
  ${FontStyles.h4};
  gap: 10px;
`;

export const TitleCheckPricing = styled(CheckPricing)`
  display: none;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    display: flex;
  }
`;

export const SaveChangesButton = styled(LargeButton)`
  padding: 8px 20px;
  ${FontStyles.bodySmallGilroy};
`;

export const ChangeWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 10px;
`;

export const CustomOnboardingCountryInput = styled(OnboardingCountryInput)`
  @media (${(props) => props.theme.breakpoints.laptop}) {
    & > .selection {
      height: 50px;
      ${FontStyles.bodyMiddleGilroy};
    }
  }
`;
export const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: 50%;
  }
`;
