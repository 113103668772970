import { format } from 'date-fns';
import React, { UIEvent, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { useGetContactsByTransactionId } from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { NoKYC } from '@constants/icons';
import { SortAscending, SortDefault, SortDescending } from '@constants/images';

import useMediaQuery from '@hooks/useMediaQuery';

import NoMatchesFound from '@components/NoMatchesFound/NoMatchesFound';

import CheckBox from '@elements/CheckBox/CheckBox';

import Preload from '../Preload';
import { StatusHeader } from '../Status';
import {
  AmountHeadingMobile,
  AmountHeadingTablet,
  CheckBoxField,
  Container,
  DataSort,
  DateHeading,
  FromToField,
  HeadingRow,
  IconField,
  NoKYCWraper,
  NoMatchesFoundRow,
  Scrollable,
  TagField,
} from './AnaliticsTransaction.styles';
import TransactionRow from './TransactionRow';

interface Props {
  data: DbTransaction[] | null;
  currentPage: number;
  isLoading: boolean;
}

const AnalyticsTransactions = ({ data, isLoading }: Props) => {
  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);

  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const selectedTransaction = useBoundStore((state) => state.selectedTransaction);

  const visibleRows = useBoundStore((state) => state.visibleRows);
  const setOrderBy = useBoundStore((state) => state.setOrderBy);
  const orderBy = useBoundStore((state) => state.orderBy);
  const selected = useBoundStore((state) => state.selected);
  const setSelected = useBoundStore((state) => state.setSelected);
  const navigate = useNavigate();
  const { breakpoints } = useTheme();

  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  // const [hovered, setHovered] = useState<number | null>(null);
  const [scrolled, setScrolled] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.99,
  });

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';
  const { data: contactData } = useGetContactsByTransactionId(
    managedAccountId,
    selectedTransaction?.transactions.id || ''
  );

  useEffect(() => {
    if (contactData && selectedTransaction) {
      setSelectedContact(contactData.contacts[0]);
    }
  }, [contactData, selectedTransaction, setSelectedContact]);

  useEffect(() => {
    if (selectedTransaction && selectedContact) {
      navigate('/send-money');
    }
  }, [navigate, selectedContact, selectedTransaction]);

  const SortIcon = {
    0: <SortDefault />,
    1: <SortAscending />,
    2: <SortDescending />,
  };

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollLeft > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleSelect = (id: string) => {
    const element = selected.find((el) => el.id === id);
    setSelected(
      element ? selected.filter((el) => el.id !== id) : [...selected, { id, checked: true }]
    );
  };

  const handleSelectAll = () => {
    if (data) {
      if (selectAll) {
        const dataIds = new Set(data.map((item) => item.id));
        setSelected(selected.filter((el) => !dataIds.has(el.id)));
      } else {
        const prevCheckedIds = new Set(selected.map((item) => item.id));
        const newChecked = data
          .filter((entry) => !prevCheckedIds.has(entry.id))
          .map((entry) => ({
            id: entry.id,
            checked: true,
          }));
        setSelected([...selected, ...newChecked]);
      }
      setSelectAll((prevState) => !prevState);
    }
  };

  useEffect(() => {
    data?.length && setSelectAll(data.every((item) => selected.some((el) => el.id === item.id)));
  }, [selected, data]);
  const formatDateForSsort = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd.MM.yyyy');
  };
  console.log('isLoading', data, isLoading);

  let dataForSorting: string;
  return (
    <Container>
      <Scrollable
        id="analyticsScroll"
        onScroll={(event) => handleScroll(event)}
        $scrolled={scrolled}
        $inView={inView}
      >
        {isLaptop && (
          <HeadingRow>
            <CheckBoxField>
              <CheckBox
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </CheckBoxField>
            <AmountHeadingTablet
              onClick={() =>
                setOrderBy({
                  dateAndTime: 0,
                  amount: orderBy.amount === 2 ? 0 : orderBy.amount + 1,
                })
              }
            >
              Amount
              {SortIcon[orderBy.amount as keyof typeof SortIcon]}
            </AmountHeadingTablet>
            <AmountHeadingMobile
              onClick={() =>
                setOrderBy({
                  ...orderBy,
                  amount: orderBy.amount === 2 ? 0 : orderBy.amount + 1,
                })
              }
            >
              Amount
              {SortIcon[orderBy.amount as keyof typeof SortIcon]}
            </AmountHeadingMobile>
            <DateHeading
              onClick={() =>
                setOrderBy({
                  amount: 0,
                  dateAndTime: orderBy.dateAndTime === 2 ? 0 : orderBy.dateAndTime + 1,
                })
              }
            >
              Date & Time
              {SortIcon[orderBy.dateAndTime as keyof typeof SortIcon]}
            </DateHeading>
            {/*<TagField>Hashtag</TagField>*/}
            <IconField>Type</IconField>
            <FromToField>From / To</FromToField>
            <StatusHeader ref={ref}>Status</StatusHeader>
          </HeadingRow>
        )}
        {data && data.length < 1 && !isLoading && verifiedKYC !== null ? (
          <NoMatchesFoundRow>
            <NoMatchesFound />
          </NoMatchesFoundRow>
        ) : isLoading || verifiedKYC === null || data === null ? (
          <Preload
            visibleRows={visibleRows}
            isLaptop={isLaptop}
          />
        ) : !verifiedKYC ? (
          <NoKYCWraper>
            <NoKYC />
          </NoKYCWraper>
        ) : (
          data?.map((entry) => {
            const dataSort = formatDateForSsort(
              entry.scheduledAt !== null ? entry.scheduledAt.toString() : entry.dateAndTime
            );

            const showData = dataForSorting === dataSort;
            dataForSorting = dataSort;
            return (
              <React.Fragment key={entry.id}>
                {!isLaptop && !showData ? <DataSort key={dataSort}>{dataSort}</DataSort> : null}
                <TransactionRow
                  key={entry.id}
                  selected={selected.find((element) => element.id === entry.id)?.checked || false}
                  entry={entry}
                  handleSelect={handleSelect}
                />
              </React.Fragment>
            );
          })
        )}
      </Scrollable>
    </Container>
  );
};
export default AnalyticsTransactions;
