import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { Triangle } from '@constants/images';

import useMediaQuery from '@hooks/useMediaQuery';

import Avatar from '@components/Avatar/Avatar';

interface Props {
  contact: ContactsWithBankDetails;
  selected: boolean;
  setSelected: (contact: ContactsWithBankDetails | null) => void;
  iconVisible?: boolean;
}

const SendMoneyContact = ({ contact, selected, setSelected, iconVisible }: Props) => {
  const navigate = useNavigate();
  const { breakpoints } = useTheme();

  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);

  const openModal = useBoundStore((state) => state.openModal);

  const { name, tag } = contact;
  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);

  const handleClick = () => {
    if (isLaptop) {
      const newSelectedContact = selectedContact?.id === contact.id ? null : contact;
      setSelected(newSelectedContact);
    }

    if (!isLaptop) {
      setSelectedContact(contact);
      navigate('/send-money/transaction-form');
    }
  };

  return (
    <Container
      $selected={isLaptop && selected}
      onClick={handleClick}
    >
      <Avatar
        src=""
        alt={`Avatar ${name}`}
        text={name ?? 'A'}
      />

      <List>
        <Name $selected={isLaptop && selected}>{name}</Name>
        {/*<Tag $selected={isLaptop && selected}>*/}
        {/*  {!tag || tag === '' ? 'No hashtag assigned' : tag}*/}
        {/*</Tag>*/}
      </List>
      {/* iconVisible && (
        <Button
          type="button"
          onClick={(event) => {
            openModal(<DocumentHistory />);
            event.stopPropagation();
          }}
          $selected={isLaptop && selected}
        >
          <IconDocClock />
        </Button>
      )*/}
    </Container>
  );
};

const Container = styled.div<{ $selected: boolean }>`
  transition: background-color 300ms ease-in-out;
  display: flex;
  align-items: center;
  position: relative;
  background: ${(props) =>
    props.$selected
      ? 'linear-gradient(255.37deg, #BFC8DB 10.35%, #7A8599 89.65%)'
      : props.theme.palette.primary.white};
  border-radius: 10px;
  padding: 11px;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;
  width: calc(100% - 7.5px);

  @media (${(props) => props.theme.breakpoints.tablet}) {
    border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
    width: calc(100% - 15px);
  }

  & > :first-child {
    height: 32px;
    width: 32px;
  }

  @media (${(props) => props.theme.breakpoints.laptop}) {
    ${(props) =>
      props.$selected &&
      css`
        &::after {
          content: '';
          position: absolute;
          right: -9.5px;
          top: 50%;
          width: 11px;
          height: 22px;
          background: url(${Triangle});
          transform: translateY(-50%);
        }
      `}
    border: ${(props) =>
      props.$selected
        ? '1px solid transparent'
        : `1px solid ${props.theme.palette.greys.lightLightGrey}`};
    ${(props) =>
      props.$selected &&
      css`
        border-left: 1px solid rgb(124, 132, 151);
        border-right: 1px solid rgb(192, 199, 217);
      `};
    padding-right: 25px;
    cursor: pointer;
    height: 64px;
    width: 289px;
    max-height: 64px;

    &:hover {
      background-color: ${(props) => props.theme.palette.greys.lightLightGrey};
    }

    & > :first-child {
      height: 40px;
      width: 40px;
    }
  }

  @media (${(props) => props.theme.breakpoints.smallDesktop}) {
    width: 442px;
  }
`;

const Name = styled.p<{ $selected: boolean }>`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) =>
    props.$selected ? props.theme.palette.primary.white : props.theme.palette.primary.black};
  width: 148px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 148px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    width: initial;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    max-width: initial;
  }
`;

const Tag = styled.p<{ $selected: boolean }>`
  ${FontStyles.bodySmallGilroy};
  margin-top: 2px;
  color: ${(props) =>
    props.$selected ? props.theme.palette.greys.middleGrey : props.theme.palette.greys.darkestGrey};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 148px;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    max-width: initial;
  }
`;

const List = styled.div`
  height: 40px;
  display: flex;
  //flex-direction: column;
  margin-left: 10px;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.laptop}) {
    //align-items: start;
  }
`;

const Button = styled.button<{ $selected: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 300ms ease-in-out;
  margin-left: 13px;
  color: ${(props) =>
    props.$selected ? props.theme.palette.primary.white : props.theme.palette.greys.darkGrey};

  @media (${(props) => props.theme.breakpoints.laptop}) {
    margin-left: auto;
  }

  &:hover {
    color: ${(props) => props.theme.palette.primary.black};
  }
`;

export default SendMoneyContact;
